import React from 'react';
import Grid from '@mui/material/Grid';
import { get } from 'src/api/api';
import { useQuery } from 'react-query';
import Loader from 'src/shared/defaultLoader';
import IncidentLogCard from './card/incidentLogCard';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';
import QuickreplyIcon from '@mui/icons-material/Quickreply';
import AssignmentIcon from '@mui/icons-material/Assignment';
import { useHistory } from 'react-router-dom';
import { useUIContext } from '../../contexts/ui/ui.context';
import useStoreQuickEntry from './quickEntry.store';
import AddPhotoButton from './addPhotoButton/addPhotoButton';
import WarningIcon from '@mui/icons-material/Warning';

export default function InterventionLog({ incidentId }) {
  const setLogEntry = useStoreQuickEntry((state) => state.setLogEntry);
  setLogEntry(null);
  const { t } = useTranslation();
  const history = useHistory();
  const module = history.location.pathname.split('/')[1];

  const uiContext = useUIContext();
  let setAppBarTitle;
  if (uiContext) {
    setAppBarTitle = uiContext.setAppBarTitle;
  }
  //const { setAppBarTitle } = useUIContext();

  React.useEffect(() => {
    if (module == 'user') {
      setAppBarTitle(t('INTERVENTION_LOG'));
    }
  }, []);

  const getQueryFn = async () => await get('incident-logs/' + incidentId);
  const { data, error, isLoading, isError } = useQuery(['incident-logs/' + incidentId], getQueryFn);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Button
            fullWidth
            variant="contained"
            startIcon={<WarningIcon />}
            size="large"
            color="neutral"
            onClick={() => history.push(`/${module}/incident/${incidentId}`)}
          >
            {t('INCIDENT')}
          </Button>
        </Grid>
        <Grid item xs={6}>
          <Button
            fullWidth
            variant="contained"
            startIcon={<AssignmentIcon />}
            size="large"
            color="neutral"
            onClick={() => history.push(`/${module}/intervention/${incidentId}/log/entry/0`)}
          >
            {t('MANUAL_COMMENT')}
          </Button>
        </Grid>
        <Grid item xs={6}>
          <Button
            fullWidth
            variant="contained"
            startIcon={<QuickreplyIcon />}
            size="large"
            color="neutral"
            onClick={() => history.push(`/${module}/intervention/${incidentId}/log/quickEntry`)}
          >
            {t('QUICK_ENTRY')}
          </Button>
        </Grid>
        <Grid item xs={12}>
          <AddPhotoButton incidentId={incidentId} />
        </Grid>
      </Grid>
      <Paper sx={{ boxSizing: 'border-box', display: 'flex', flexDirection: 'column', padding: 1, paddingBottom: 5, marginTop: 1 }}>
        {data.map((log, index) => {
          const isLastElement = index === data.length - 1;
          return <IncidentLogCard key={log.incident_log_id} log={log} isLastElement={isLastElement} />;
        })}
      </Paper>
    </>
  );
}
