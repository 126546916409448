import React from 'react';
import GenericList from 'src/shared/genericList';
import { useTranslation } from 'react-i18next';

export default function AlarmList() {
  const { t } = useTranslation();

  const columns = [
    { field: 'alarm_id', headerName: 'ID', hide: true },
    {
      field: 'alarm_status',
      headerName: t('INCIDENT_STATUS'),
      valueGetter: (value) => t(value),
      width: 150,
      editable: true,
      flex: 0.3,
    },
    {
      field: 'alarm_type',
      headerName: t('INCIDENT_TYPE'),
      width: 75,
      editable: true,
      flex: 1,
    },
    {
      field: 'incident_commander',
      headerName: t('INCIDENT_COMMANDER'),
      width: 75,
      editable: true,
      flex: 1,
    },
  ];

  return <GenericList objectName={'alarm'} columns={columns} />;
}
