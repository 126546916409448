import { colors } from '@mui/material';

export const Colors = {
  button_default: '#f6f6f6',
  button_default_icon: '#3c3c3c',
  even_line_background: '#f0f0f0',
  odd_line_background: '#fafafa',
  section: '#656263',
  dispatch_ack_button: '#dd2222',
  dispatch_ack_button_ICON: '#fff',
  default_contrast: '#fff',
  ALARM: {
    leaving: '#ffcbcd',
    lighter: '#D6E4FF',
    light: '#84A9FF',
    main: '#3366FF',
    dark: '#1939B7',
    darker: '#091A7A',
    contrastText: '#fff',
  },
  INCIDENT: {
    lighter: '#D6E4FF',
    light: '#ffcbcd',
    main: '#3366FF',
    dark: '#1939B7',
    darker: '#dd2222',
    contrastText: '#fff',
  },
  AVAILABLE: {
    lighter: '#e5f5e6',
    light: '#c2e5c2',
    main: '#20ac34',
    dark: '#139d2b',
    darker: '#007b13',
    contrastText: '#fff',
  },
  NOT_AVAILABLE: {
    lighter: '#ffeaed',
    light: '#ffcbcd',
    main: '#ff3313',
    dark: '#f42417',
    darker: '#d60007',
    contrastText: '#fff',
  },
  PRIVATE: {
    lighter: '#eee',
    light: '#ccc',
    main: '#aaa',
    dark: '#999',
    darker: '#777',
    contrastText: '#444',
  },
  NOT_AVAILABLE_LONG: {
    main: '#ccc',
    contrastText: '#aaa',
  },
  UNKNOWN: {
    dark: '#444',
    darker: '#222',
  },
  Summary: {
    day: '#FBE7C6',
    currentDay: '#f0af43',
    night: '#adcbe3',
    currentNight: '#3878ab',
    bellowMin: '#F51720',
    normal: '#222',
  },
  INCIDENT_TYPE: {
    FIRE: '#e80606',
    ACCIDENT: '#1c8600',
    RESCUE: '#ffea21',
    WATER: '#0437ff',
    HAZARD: '#ff8600',
    UNKNOWN: '#aaa',
  },
};

export const Themes = {
  USER: {
    palette: {
      type: 'light',
      primary: {
        main: '#bf0002',
        contrastText: '#ffffff',
      },
      secondary: {
        main: '#6b0000',
      },
      warning: {
        main: '#e53935',
      },
      custom: {
        test: '#fg7676',
      },
      neutral: {
        main: Colors.button_default,
        contrastText: '#00000099',
        dark: Colors.button_default,
      },
      badge: {
        main: '#9dc0f9',
      },
      vehicle_available: {
        main: Colors.AVAILABLE.main,
        contrastText: '#222',
      },
      vehicle_engaged: {
        main: '#720d94',
        contrastText: '#ffffff',
      },
      vehicle_on_way: {
        main: Colors.INCIDENT.main,
        contrastText: '#ffffff',
      },
      vehicle_on_site: {
        main: '#f0af43',
        contrastText: '#222',
      },
      vehicle_out_of_order: {
        main: '#ccc',
        contrastText: '#222',
      },
      vehicle_not_available: {
        main: '#222',
        contrastText: '#fff',
      },
      vehicle_training: {
        main: '#0d6b1e',
        contrastText: '#fff',
      },
    },
  },

  ADMIN: {
    palette: {
      badge: {
        main: '#9dc0f9',
      },
      neutral: {
        main: Colors.button_default,
        contrastText: '#00000099',
        dark: Colors.button_default,
      },
    },
  },
  DISPLAY: {},
};
export default Colors;
