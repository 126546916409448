import React from 'react';
import Fab from '@mui/material/Fab';
import EditLocationIcon from '@mui/icons-material/EditLocation';
import Drawer from '@mui/material/Drawer';
import Toolbar from '@mui/material/Toolbar';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { Box } from '@mui/system';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';

export default function MapLayerSelection({ layers, map }) {
  const { t } = useTranslation();
  const [state, setState] = React.useState(false);

  const toggleDrawer = (isOpen) => () => {
    setState(isOpen);
  };
  return (
    <>
      <Drawer anchor={'right'} open={state} onClose={toggleDrawer(false)}>
        <Toolbar />
        <Box sx={{ m: 2 }}>
          <FormGroup>
            {layers.map((layer, index) => {
              return (
                <FormControlLabel
                  key={index}
                  control={<Checkbox defaultChecked={layer.kmlLayer?.map !== null} />}
                  label={layer.map_layer_name}
                  onChange={() => {
                    if (layer.kmlLayer.map === null) {
                      layer.kmlLayer.setMap(map);
                    } else {
                      layer.kmlLayer.setMap(null);
                    }
                  }}
                />
              );
            })}
          </FormGroup>
          <Box sx={{ m: 2, height: '15px' }}>
            <Button variant="outlined" size="small" fullWidth sx={{ height: '15px' }} onClick={toggleDrawer(false)}>
              {t('CLOSE')}
            </Button>
          </Box>
        </Box>
      </Drawer>
      <Fab
        sx={{ display: { xs: 'none', md: state ? 'none' : 'flex' }, position: 'fixed', bottom: 24, right: 54, zIndex: 999999 }}
        variant="extended"
        onClick={toggleDrawer(!state)}
      >
        <EditLocationIcon sx={{ mr: 0 }} />
      </Fab>

      <Fab
        sx={{ display: { md: 'none', xs: state ? 'none' : 'flex' }, position: 'fixed', bottom: 80, right: 54, zIndex: 999999 }}
        variant="extended"
        onClick={toggleDrawer(!state)}
      >
        <EditLocationIcon sx={{ mr: 0 }} />
      </Fab>
    </>
  );
}
