import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import { DateUtiles } from 'src/services/dateUtiles';
import Paper from '@mui/material/Paper';
import WeeklyCalendarView from './weeklyCalendarView';
import { get } from 'src/api/api';
import { useQuery } from 'react-query';
import Loader from 'src/shared/defaultLoader';

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),

  color: theme.palette.text.secondary,
  marginTop: theme.spacing(2),
}));

export default function CalendarView({ data, baseDate }) {
  const monday = DateUtiles.getMonday(baseDate);
  const sunday = DateUtiles.getSunday(baseDate);

  const getQueryFn = async () => await get(`function/calendar/all`, { start: monday, end: sunday });
  const { data: dataCalendar, isLoading } = useQuery([`function/calendar/all?start=${monday}&end=${sunday}`], getQueryFn);
  if (isLoading) {
    return <Loader />;
  }
  // console.log(dataCalendar);
  return (
    <>
      {data.map((func) => (
        <WeeklyCalendarView key={func.function_id} functionDetails={func} data={dataCalendar?.filter((record) => record.function_id === func.function_id)} />
      ))}
    </>
  );
}
