import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useTranslation } from 'react-i18next';
import { DateUtiles } from 'src/services/dateUtiles';
import { get, getAllData } from 'src/api/api';
import { useQuery } from 'react-query';
import Loader from 'src/shared/defaultLoader';
import { Colors } from 'src/styles/variables';
import Box from '@mui/material/Box';
import MemberAgendaLine from './memberAgendaLine';

export default function AllPlanningAgenda({ baseDate, type = 'AVAILABILITY' }) {
  const { t } = useTranslation();

  const monday = DateUtiles.getMonday(baseDate);
  const sunday = DateUtiles.getSunday(baseDate);

  const url = 'availability/calendar/all';
  const cacheKey = 'AllWeeklyAvailability/';

  const getQueryFn = async ({ queryKey }) => await get(url, { start: monday, end: sunday });
  const { data, isLoading } = useQuery([cacheKey + monday], getQueryFn, {
    onSuccess: (data) => {
      // console.log(data);
    },
  });

  const getSpecializationQueryFn = async () => await getAllData('specialization');
  const { data: specializations, isLoading: specializationIsLoading } = useQuery('specialization', getSpecializationQueryFn);

  if (isLoading || specializationIsLoading) {
    return <Loader />;
  }

  const getRecordByDate = (date, source) => {
    let defaultLineMode = 'NOT_AVAILABLE';
    if (source.length > 0) {
      if (source[0].title === 'PRIVATE') {
        defaultLineMode = 'PRIVATE';
      }
    }

    let availabilities = [];
    if (data) {
      for (const availability of source) {
        const start = new Date(availability.start);
        const end = new Date(availability.end);
        if (start <= date && end >= date) {
          availabilities.push(availability);
        }
      }
    }
    if (availabilities.length === 0) {
      availabilities.push({
        id: 0,
        title: defaultLineMode,
        start: null,
        end: null,
        color: null,
        textColor: 'black',
      });
    }
    return availabilities;
  };
  for (const member of data) {
    let weekDays = [];
    for (let index = 0; index < 7; index++) {
      const day = DateUtiles.getMonday(baseDate);
      day.setDate(day.getDate() + index);
      let dayAbbr = '';
      switch (day.getDay()) {
        case 1:
          dayAbbr = 'MONDAY_ABBR';
          break;
        case 2:
          dayAbbr = 'TUESDAY_ABBR';
          break;
        case 3:
          dayAbbr = 'WEDNESDAY_ABBR';
          break;
        case 4:
          dayAbbr = 'THUSDAY_ABBR';
          break;
        case 5:
          dayAbbr = 'FRIDAY_ABBR';
          break;
        case 6:
          dayAbbr = 'SATURDAY_ABBR';
          break;
        case 0:
          dayAbbr = 'SUNDAY_ABBR';
          break;
        default:
          break;
      }
      const dayData = {
        id: index,
        date: day,
        name: t(dayAbbr) + ' ' + day.getDate(),
        availabilities: [],
      };

      const minutesInOneDay = 1440;
      const DayDelta = minutesInOneDay * index;

      const morning = DateUtiles.addMinutes(monday, 60 * 10 + DayDelta);
      const morningRecords = getRecordByDate(morning, member.availabilities);
      dayData.availabilities.push({ id: 1, date: morning, availabilities: morningRecords });

      const afternoon = DateUtiles.addMinutes(monday, 60 * 14 + DayDelta);
      const afternoonRecords = getRecordByDate(afternoon, member.availabilities);
      dayData.availabilities.push({ id: 2, date: afternoon, availabilities: afternoonRecords });

      const night = DateUtiles.addMinutes(monday, 60 * 23 + DayDelta);
      const nightRecords = getRecordByDate(night, member.availabilities);
      dayData.availabilities.push({ id: 3, date: night, availabilities: nightRecords });

      // for (let j = 0; j < 4; j++) {
      //   const minutesInOneDay = 1440
      //   const eDate = DateUtiles.addMinutes(monday, 360 * j + minutesInOneDay * index);
      //   const records = getRecordByDate(eDate, member.availabilities);
      //   const m = { id: j, date: eDate, availabilities: records };
      //   dayData.availabilities.push(m);
      // }
      weekDays.push(dayData);
    }
    member.standardizedAvailability = weekDays;
  }

  let today = new Date().getDay();
  if (DateUtiles.getWeekNumber(new Date()) !== DateUtiles.getWeekNumber(baseDate)) {
    today = -1;
  }
  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell sx={{ minWidth: 120, width: 120, maxWidth: 120, p: 1 }}></TableCell>
            <TableCell sx={{ p: 0, width: 1 / 7, color: today === 1 ? 'red' : null }} align="left">
              {t('MONDAY_ABBR')}
            </TableCell>

            <TableCell sx={{ p: 0, width: 1 / 7, color: today === 2 ? 'red' : null }} align="left">
              {t('TUESDAY_ABBR')}
            </TableCell>

            <TableCell sx={{ p: 0, width: 1 / 7, color: today === 3 ? 'red' : null }} align="left">
              {t('WEDNESDAY_ABBR')}
            </TableCell>

            <TableCell sx={{ p: 0, width: 1 / 7, color: today === 4 ? 'red' : null }} align="left">
              {t('THUSDAY_ABBR')}
            </TableCell>
            <TableCell sx={{ p: 0, width: 1 / 7, color: today === 5 ? 'red' : null }} align="left">
              {t('FRIDAY_ABBR')}
            </TableCell>
            <TableCell sx={{ p: 0, width: 1 / 7, color: today === 6 ? 'red' : null }} align="left">
              {t('SATURDAY_ABBR')}
            </TableCell>
            <TableCell sx={{ p: 0, width: 1 / 7, color: today === 0 ? 'red' : null }} align="left">
              {t('SUNDAY_ABBR')}
            </TableCell>
          </TableRow>
        </TableHead>
      </Table>
      <Table>
        <TableBody>
          {data.map((member) => (
            <TableRow key={member.member_id}>
              <TableCell sx={{ minWidth: 120, width: 120, maxWidth: 120, overflow: 'hidden', p: 1 }}>
                {member.lastname} {member.firstname.substr(0, 1) + '.'}
              </TableCell>
              {member.standardizedAvailability.map((day, dayIndex) => (
                <MemberAgendaLine key={dayIndex} highlight={today === (dayIndex + 1) % 7} day={day} dayIndex={dayIndex} type={type} />
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
