import React from 'react';
import { useQuery } from 'react-query';
import { getData } from 'src/api/api';
import Loader from 'src/shared/defaultLoader';
import TableauBloc from '../tableau-bloc/tableau-bloc';
import { Utils } from 'src/services/utils';

export default function TableauSpecialization({ title, specializationId, nameSuffix, namePrefix, datas, isPrimary, displayAvailability }) {
  const getQueryFn = async ({ queryKey }) => await getData('specialization', { queryKey });
  const { data: specData, isLoading } = useQuery(['specialization', { id: specializationId }], getQueryFn, { staleTime: 1000 });

  if (isLoading) {
    return <Loader />;
  }

  if (namePrefix) {
    title = namePrefix + ' ' + title;
  }

  if (nameSuffix) {
    title = title + ' ' + nameSuffix;
  }

  const specializations = [];
  for (const key in datas) {
    if (Object.hasOwnProperty.call(datas, key)) {
      const element = datas[key];
      for (const member of element.members) {
        if (member.specializations.find((e) => e === specData.specialization_abbreviation)) {
          specializations.push(member);
        }
      }
    }
  }

  if (specializations.length === 0) return null;

  return <TableauBloc title={title} displayAvailability={displayAvailability} blocData={Utils.sortByRankAndName(specializations)} />;
}
