import React, { useState, useEffect } from 'react';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import Typography from '@mui/material/Typography';
import { DateUtiles } from 'src/services/dateUtiles';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { useTranslation } from 'react-i18next';

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

export default function WeekSelector({ baseDate: pBaseDate, onWeekChange }) {
  const { t } = useTranslation();

  const [baseDate, setBaseDate] = useState(pBaseDate);
  const [dateTitleFrom, setDateTitleFrom] = useState('');
  const [dateTitleTo, setDateTitleTo] = useState('');
  const [weekNumberTitle, setWeekNumberTitle] = useState('');

  useEffect(() => {
    const monday = DateUtiles.getMonday(baseDate);
    const sunday = DateUtiles.getSunday(baseDate);
    // const dateTitle = `#${DateUtiles.getWeekNumber(monday)} ${DateUtiles.toStandardDate(monday)} - ${DateUtiles.toStandardDate(sunday)}`;
    const dateTitleFrom = `Du ${DateUtiles.toLongDate(monday)}`;
    const dateTitleTo = `Au ${DateUtiles.toLongDate(sunday)}`;
    const weekNumberTitle = `${t('WEEK_SELECTOR_WEEK_NUMBER')} ${DateUtiles.getWeekNumber(monday)}`;
    setDateTitleFrom(dateTitleFrom);
    setDateTitleTo(dateTitleTo);
    setWeekNumberTitle(weekNumberTitle);
  }, [baseDate]);

  const updateBaseDate = (delta) => {
    const newBaseDate = DateUtiles.addDays(baseDate, delta);
    setBaseDate(newBaseDate);
    onWeekChange(newBaseDate);
  };
  return (
    <Item>
      <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={1}>
        <IconButton aria-label="delete" size="large" onClick={() => updateBaseDate(-7)}>
          <NavigateBeforeIcon fontSize="inherit" />
        </IconButton>

        <Stack>
          <Typography variant="subtitle2" component="div">
            {weekNumberTitle}
          </Typography>
          <Typography variant="subtitle2" component="div">
            {dateTitleFrom}
          </Typography>
          <Typography variant="subtitle2" component="div">
            {dateTitleTo}
          </Typography>
        </Stack>

        <IconButton aria-label="delete" size="large" onClick={() => updateBaseDate(7)}>
          <NavigateNextIcon fontSize="inherit" />
        </IconButton>
      </Stack>
    </Item>
  );
}
