import JSZip from 'jszip';

const downloadImagesAsZip = async (imageUrls) => {
  const zip = new JSZip();

  // Fetch image data in parallel for efficiency
  const imagePromises = imageUrls.map(async (url) => {
    const filename = url.split('/').pop(); // Extract filename from URL
    const response = await fetch(url);
    const blob = await response.blob();
    return { filename, blob };
  });

  const imageData = await Promise.all(imagePromises);

  // Add images to the ZIP archive
  imageData.forEach(({ filename, blob }) => {
    zip.file(filename, blob);
  });

  // Generate downloadable ZIP
  const zipBlob = await zip.generateAsync({ type: 'blob' });
  const url = URL.createObjectURL(zipBlob);
  const link = document.createElement('a');
  link.href = url;
  link.download = 'images.zip';
  document.body.appendChild(link); // Required for Firefox
  link.click();
  URL.revokeObjectURL(url);
  document.body.removeChild(link);
};

export default downloadImagesAsZip;
