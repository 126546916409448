import React from 'react';
import GenericList from 'src/shared/genericList';
import { useTranslation } from 'react-i18next';

export default function MaterialList() {
  const { t } = useTranslation();
  const columns = [
    { field: 'material_id', headerName: 'ID', hide: true },
    {
      field: 'material_type',
      headerName: t('MATERIAL_TYPE'),
      width: 250,
      editable: true,
      valueGetter: function (value) {
        return t(value);
      },
    },
    {
      field: 'material_code',
      headerName: t('MATERIAL_CODE'),
      width: 250,
      editable: true,
    },
    {
      field: 'material_name',
      headerName: t('MATERIAL_NAME'),
      width: 250,
      editable: true,
      flex: 1,
    },
    {
      field: 'material_order',
      headerName: t('SORT_ORDER'),
      width: '150',
      editable: true,
    },
  ];

  return <GenericList objectName={'material'} columns={columns} rowId={'material_id'} />;
}
