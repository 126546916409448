import React from 'react';
import TextField from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import Grid from '@mui/material/Grid';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import DoNotDisturbOnIcon from '@mui/icons-material/DoNotDisturbOn';
import Stack from '@mui/material/Stack';
import Button from '../../components/button/button';
import Typography from '@mui/material/Typography';
import { useQueryClient } from 'react-query';
import { post } from 'src/api/api';
import { Colors } from 'src/styles/variables';
import { useTranslation } from 'react-i18next';
import frCH from 'date-fns/locale/fr-CH';
import { useHistory } from 'react-router-dom';

export default function Availability() {
  const queryClient = useQueryClient();
  const userParam = queryClient.getQueryData(['params/currentUser']);
  const { t } = useTranslation();
  const history = useHistory();

  // const getFunctionsQueryFn = async () => await getAllData('function');
  // const { data: allFunctions, isLoading: functionsIsLoading } = useQuery('function', getFunctionsQueryFn);

  const defaultFromDate = new Date();
  defaultFromDate.setSeconds(0);
  defaultFromDate.setMilliseconds(0);
  const defaultToDate = new Date(defaultFromDate);

  const dayDelta = Number(userParam.default_time_days) || 0;
  defaultToDate.setDate(defaultToDate.getDate() + dayDelta);

  const timeDelta = Number(userParam.default_time_hours) || 2;
  defaultToDate.setHours(defaultToDate.getHours() + timeDelta);

  const minutesDelta = Number(userParam.default_time_minutes) || 0;
  defaultToDate.setMinutes(defaultToDate.getMinutes() + minutesDelta);

  const [fromDate, setFromDate] = React.useState(defaultFromDate);
  const [toDate, setToDate] = React.useState(defaultToDate);

  const statusClickHandler = async (status) => {
    const data = {
      from: fromDate,
      to: toDate,
      status: status,
    };
    await post('availability', data);
    history.goBack();
  };

  // if (functionsIsLoading) {
  //   return <Loader />;
  // }

  return (
    <Grid container spacing={3}>
      <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={frCH}>
        <Grid item xs={12}>
          <Stack spacing={3}>
            <DateTimePicker
              label={t('FROM')}
              ampm={false}
              value={fromDate}
              onChange={(newValue) => {
                setFromDate(newValue);
              }}
              renderInput={(params) => <TextField variant="standard" {...params} />}
            />
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <Stack spacing={3}>
            <DateTimePicker
              label={t('TO')}
              ampm={false}
              value={toDate}
              onChange={(newValue) => {
                setToDate(newValue);
              }}
              renderInput={(params) => <TextField variant="standard" {...params} />}
            />
          </Stack>
        </Grid>
      </LocalizationProvider>
      <Grid item xs={12} sx={{ p: 0, color: 'text.secondary', textAlign: 'center' }}>
        <Typography variant="h6">{t('AVAILABILITY')}</Typography>
      </Grid>

      <Grid item xs={6}>
        <Button
          icon={EventAvailableIcon}
          textColor={Colors.AVAILABLE.darker}
          iconColor={Colors.AVAILABLE.darker}
          bgColor={Colors.AVAILABLE.light}
          onClick={() => statusClickHandler('AVAILABLE')}
        >
          {t('AVAILABLE')}
        </Button>
      </Grid>

      <Grid item xs={6}>
        <Button
          icon={DoNotDisturbOnIcon}
          textColor={Colors.NOT_AVAILABLE.darker}
          iconColor={Colors.NOT_AVAILABLE.darker}
          bgColor={Colors.NOT_AVAILABLE.light}
          onClick={() => statusClickHandler('NOT_AVAILABLE')}
        >
          {t('NOT_AVAILABLE')}
        </Button>
      </Grid>
    </Grid>
  );
}
