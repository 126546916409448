import React from 'react';
import GenericList from 'src/shared/genericList';
import { useTranslation } from 'react-i18next';

export default function DirectoryList() {
  const { t } = useTranslation();
  const columns = [
    { field: 'phone_directory_id', headerName: 'ID', hide: true },
    {
      field: 'phone_directory_name',
      headerName: t('NAME'),
      width: '*',
      flex: 1,
      editable: true,
    },
    {
      field: 'phone_directory_order',
      headerName: t('SORT_ORDER'),
      width: '*',
      flex: 1,
      editable: true,
    },
  ];

  return <GenericList objectName={'directory'} columns={columns} rowId={'phone_directory_id'} />;
}
