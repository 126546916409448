import React from 'react';
import { useDisplayContext } from '../../contexts/display/display.context';
import Box from '@mui/material/Box';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import { Colors } from 'src/styles/variables';
import { styled } from '@mui/system';
import { DurationUtils } from 'src/services/durations';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';
import Loader from 'src/shared/defaultLoader';
import { getAllData, get } from 'src/api/api';
import { useQuery } from 'react-query';

const TableauMembreV = function ({ member }) {
  const theme = useTheme();

  const { t } = useTranslation();

  // console.log(member);
  const { assignations } = useDisplayContext();
  const getSpecializationQueryFn = async () => await getAllData('specialization');
  const { data: specializations, isLoading: specializationIsLoading } = useQuery('specialization', getSpecializationQueryFn);

  if (specializationIsLoading) {
    return null;
  }

  if (!member) return null;
  // console.log('f', assignations);
  // console.log('m', member);

  let rankBgColor = '';
  let lineBgColor = '';
  let textColor = 'black';
  let NameColor = 'black';
  let iconOpacity = 1;
  let lastProcessedRank = null;
  for (const assignment of member.asignments) {
    const assignmentDetails = assignations.find((e) => e.function_abbreviation === assignment);
    // console.log(assignmentDetails);
    if (assignmentDetails) {
      if ((assignmentDetails && lastProcessedRank === null) || assignmentDetails.function_order < lastProcessedRank) {
        lastProcessedRank = assignmentDetails.function_order;
        if (assignmentDetails.rank_color_code) {
          rankBgColor = assignmentDetails.rank_color_code;
        }
        if (assignmentDetails.background_color_code) {
          lineBgColor = assignmentDetails.background_color_code;
        }
      }
    }
  }

  if (member.status_code === 'AVAILABLE') {
    NameColor = Colors.AVAILABLE.darker;
    iconOpacity = 1;
  }
  if (member.status_code === 'NOT_AVAILABLE' || !member.status_code) {
    iconOpacity = 0.2;
    const diff = Math.floor((new Date(member.end_date) - new Date(member.start_date)) / 86400000);
    if (diff <= 3) {
      NameColor = Colors.NOT_AVAILABLE.darker;
    } else {
      NameColor = Colors.NOT_AVAILABLE_LONG.contrastText;
      lineBgColor = Colors.NOT_AVAILABLE_LONG.main;
    }
  }
  if (member.alarm_id) {
    NameColor = Colors.ALARM.contrastText;
    lineBgColor = Colors.ALARM.darker;
  }

  const isIC = member.member_id === member.incident_commander_id;
  if (isIC) {
    const ciSpec = specializations.find((spec) => spec.role_abbreviation === 'CI');
    NameColor = theme.palette.getContrastText(ciSpec.color);
    lineBgColor = ciSpec.color;
  }
  const MemberLine = styled(TableRow)({
    backgroundColor: lineBgColor + ' !important',

    color: textColor,
    '&:nth-of-type(even)': {
      background: Colors.even_line_background,
    },
    '&:nth-of-type(odd)': {
      background: Colors.odd_line_background,
    },
  });

  const NamesBox = styled(TableCell)({
    fontWeight: 'bold',
    color: NameColor,
    fontSize: '0.8em',
    paddingLeft: 0.1,
    paddingRight: 0.1,
  });

  const SpecIcon = styled(Box)({
    flexGrow: 0,
    // width: '50px',
    paddingLeft: 1,
    paddingRight: 1,
  });
  const SpecIconImg = styled('img')({
    height: '25px',
  });
  if (member.receipt_date) {
    member.duration = DurationUtils.toReadableDuration(member.receipt_date);
  }
  // if (member.duration !== '') {
  //   console.log(member.duration);
  // }
  return (
    <MemberLine key={member.member_id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
      <TableCell sx={{ p: 0.4, color: NameColor, bgcolor: rankBgColor }}>{t('RANK_ABBR.' + member.rank_abbreviation)}</TableCell>
      <NamesBox component="th" scope="row">
        <a href={`tel://${member.phone}`}>{member.lastname}</a>
      </NamesBox>
      <NamesBox component="th" scope="row">
        <a href={`tel://${member.phone}`}>{member.firstname}</a>
      </NamesBox>
      <TableCell sx={{ p: 0.1, color: NameColor }} align="right">
        {DurationUtils.toReadableDuration(member.receipt_date)}
      </TableCell>
      <TableCell sx={{ p: 0.1 }} align="right">
        <Box
          sx={{
            display: 'inline-flex',
            alignItems: 'center',
          }}
        >
          <SpecIcon>
            {member.specializations.some((item) => item === 'PREPOSER_ARI') ? (
              <SpecIconImg sx={{ opacity: iconOpacity }} src={'/icons/cylindre_ARI_color.png'} alt="item" />
            ) : member.specializations.some((item) => item === 'ARI') ? (
              <SpecIconImg sx={{ opacity: iconOpacity }} src={'/icons/cylindre_ARI.png'} alt="item" />
            ) : (
              <SpecIconImg sx={{ opacity: 0 }} src={'/icons/cylindre_ARI.png'} alt="item" />
            )}
          </SpecIcon>
          <SpecIcon>
            {member.specializations.some((item) => item === 'EA') ? (
              <SpecIconImg sx={{ opacity: iconOpacity }} src={'/icons/EPS25.png'} alt="item" />
            ) : (
              <SpecIconImg sx={{ opacity: 0 }} src={'/icons/EPS25.png'} alt="item" />
            )}
          </SpecIcon>
          <SpecIcon>
            {member.specializations.some((item) => item === 'ANTICHUTE') ? (
              <SpecIconImg sx={{ opacity: iconOpacity }} src={'/icons/antichute.png'} alt="item" />
            ) : (
              <SpecIconImg sx={{ opacity: 0 }} src={'/icons/antichute.png'} alt="item" />
            )}
          </SpecIcon>
          <SpecIcon>
            {member.specializations.some((item) => item?.startsWith('CHAUFFEUR') ?? false) ? (
              <SpecIconImg sx={{ opacity: iconOpacity }} src={'/icons/volant.png'} alt="item" />
            ) : (
              <SpecIconImg sx={{ opacity: 0 }} src={'/icons/volant.png'} alt="item" />
            )}
          </SpecIcon>
        </Box>
      </TableCell>
    </MemberLine>
  );
};

const TableauMembre = React.memo(TableauMembreV);
//const TableauMembre = TableauMembreV;
export default TableauMembre;
