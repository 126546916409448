import React from 'react';
import GenericCRUD from 'src/shared/genericCRUD';
import { useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useQuery } from 'react-query';
import { getData } from 'src/api/api';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import { useTranslation } from 'react-i18next';
import FormControl from '@mui/material/FormControl';
import Loader from 'src/shared/defaultLoader';

export default function AlarmTypesDetails() {
  const { register, handleSubmit } = useForm();
  const { t } = useTranslation();
  let { id } = useParams();
  const getQueryFn = async ({ queryKey }) => await getData('alarm/type', { queryKey });

  const { data, error, isLoading, isError } = useQuery(['alarm/type', { id }], getQueryFn);

  if (isLoading) {
    return <Loader />;
  }
  if (isError) {
    return <span>Error : {error.message}</span>;
  }

  return (
    <GenericCRUD objectName={'alarm/type'} handleSubmit={handleSubmit} data={data}>
      <Grid item xs={12} sm={6}>
        <FormControl variant="standard" fullWidth={true}>
          <TextField
            {...register('alarm_code')}
            required
            autoComplete="off"
            id="alarm_code"
            name="alarm_code"
            label={t('ALARM_CODE')}
            fullWidth
            variant="standard"
            defaultValue={data.alarm_code}
          />
        </FormControl>
      </Grid>

      <Grid item xs={12} sm={6}>
        <FormControl variant="standard" fullWidth={true}>
          <TextField
            {...register('alarm_order')}
            required
            id="alarm_order"
            name="alarm_order"
            label={t('ALARM_ORDER')}
            fullWidth
            autoComplete="family-name"
            variant="standard"
            defaultValue={data.alarm_order}
          />
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={12}>
        <FormControl variant="standard" fullWidth={true}>
          <TextField
            {...register('alarm_description')}
            required
            id="alarm_description"
            name="alarm_description"
            label={t('ALARM_DESCRIPTION')}
            fullWidth
            autoComplete="family-name"
            variant="standard"
            defaultValue={data.alarm_description}
          />
        </FormControl>
      </Grid>
    </GenericCRUD>
  );
}
