import React from 'react';
import InterventionLog from './interventionLog';
import Loader from 'src/shared/defaultLoader';
import { get } from 'src/api/api';
import { useQuery } from 'react-query';

export default function CurrentInterventionLog() {
  const getQueryFn = async ({ queryKey }) => await get('incident/currentIncident');
  const { data, isLoading } = useQuery(['currentIncident'], getQueryFn);

  if (isLoading) {
    return <Loader />;
  }

  return <InterventionLog incidentId={data.incident_id} />;
}
