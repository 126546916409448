import React from 'react';
import Avatar from '@mui/material/Avatar';
import Colors from 'src/styles/variables';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import { useTranslation } from 'react-i18next';
import { EVENT_TYPE } from 'src/constants/constants';
import api from 'src/api/axios';
import { useQueryClient } from 'react-query';

export default function IncidentAvatar({ incident }) {
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(false);

  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setOpen(open);
  };

  const changeEventType = async (eventType) => {
    console.log(eventType);
    await api.put(`/incident/${incident.incident_id}/incidentType`, { incident_type: eventType });
    queryClient.invalidateQueries(['incidentDetail']);
    queryClient.refetchQueries(['incidentDetail']);
  };

  let avatarColor = Colors.INCIDENT_TYPE.UNKNOWN;
  let avatarSign = '?';
  switch (incident.incident_type) {
    case 'FIRE':
      avatarSign = 'F';
      avatarColor = Colors.INCIDENT_TYPE.FIRE;
      break;
    case 'ACCIDENT':
      avatarSign = 'A';
      avatarColor = Colors.INCIDENT_TYPE.ACCIDENT;
      break;
    case 'RESCUE':
      avatarSign = 'S';
      avatarColor = Colors.INCIDENT_TYPE.RESCUE;
      break;
    case 'WATER':
      avatarSign = 'E';
      avatarColor = Colors.INCIDENT_TYPE.WATER;
      break;
    case 'HAZARD':
      avatarSign = 'C';
      avatarColor = Colors.INCIDENT_TYPE.HAZARD;
      break;
  }

  return (
    <>
      <div onClick={toggleDrawer(true)}>
        <Avatar sx={{ bgcolor: avatarColor }}>{avatarSign}</Avatar>
      </div>

      <Drawer anchor={'right'} open={open} onClose={toggleDrawer(false)}>
        <Box sx={{ width: 300, p: 1 }} role="presentation" onClick={toggleDrawer(false)} onKeyDown={toggleDrawer(false)}>
          <Toolbar />
          <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
            {EVENT_TYPE?.map((event) => {
              return (
                <ListItem key={event.code}>
                  <ListItemAvatar onClick={() => changeEventType(event.code)} sx={{ cursor: 'pointer' }}>
                    <Avatar sx={{ bgcolor: event.color }}>{event.shortCode}</Avatar>
                  </ListItemAvatar>
                  <ListItemText primary={t(event.name)} />
                </ListItem>
              );
            })}
          </List>
        </Box>
      </Drawer>
    </>
  );
}
