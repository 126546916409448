import React from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Loader from 'src/shared/defaultLoader';
import { get } from 'src/api/api';
import Grid from '@mui/material/Grid';
import KeyboardReturnIcon from '@mui/icons-material/KeyboardReturn';
import useStoreQuickEntry from '../quickEntry.store';
import Button from '@mui/material/Button';
import QuickEntryButton from './quickEntryButton';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

function setParent(e) {
  e.children.forEach((element) => {
    element.parent = e;
    setParent(element);
  });
  return e;
}

function CategoryHeader({ value }) {
  return (
    <Grid item xs={12}>
      <Box
        sx={{
          color: 'text.secondary',
          alignContent: 'center',
          justifyContent: 'center',
          display: 'flex',
          textAlign: 'center',
          flexDirection: 'column',
          paddingBottom: '0px',
          paddingTop: '15px',
        }}
      >
        <Box>
          <Typography variant="subtitle2" gutterBottom>
            {value ?? '---'}
          </Typography>
        </Box>
      </Box>
    </Grid>
  );
}

export default function InterventionLogQuickEntry() {
  let { interventionId } = useParams();
  const [curentFolder, setCurrentFolder] = React.useState(null);
  const setLogEntry = useStoreQuickEntry((state) => state.setLogEntry);
  setLogEntry(null);

  const { t } = useTranslation();
  const getQueryFn = async () => await get('intervention-log-preset/tree/withDetails');
  const { data, error, isLoading, isError } = useQuery(['intervention-log-preset/tree'], getQueryFn, {
    cacheTime: 1,
    onSuccess: (data) => {
      const root = { name: t('ROOT'), children: data, parent: null };
      setParent(root);
      setCurrentFolder(root);
    },
  });

  if (isLoading) {
    return <Loader />;
  }
  if (isError) {
    return <span>Error : {error.message}</span>;
  }
  let lastCategory = '';
  let showCategoryHeader = false;

  let lastFolderCategory = '';
  let showFolderCategoryHeader = false;
  return (
    <Grid container spacing={1}>
      {curentFolder.parent === null ? null : (
        <Grid item xs={12}>
          <Button
            fullWidth
            variant="contained"
            // startIcon={<FolderOpenIcon />}
            endIcon={<KeyboardReturnIcon />}
            size="large"
            color="neutral"
            sx={{ backgroundColor: curentFolder.parent?.color_code ?? null }}
            onClick={() => {
              setCurrentFolder(curentFolder.parent);
            }}
          >
            {t(curentFolder.parent.name)}
          </Button>
        </Grid>
      )}
      {curentFolder.children.map((e) => {
        if (e.item_type === 'ACTION') {
          if (e.category_name !== lastCategory) {
            lastCategory = e.category_name;
            showCategoryHeader = true;
          } else {
            showCategoryHeader = false;
          }
          return (
            <React.Fragment key={e.incident_log_preset_item_id}>
              {showCategoryHeader ? <CategoryHeader value={e.category_name} /> : null}
              <Grid item xs={6}>
                <QuickEntryButton entry={e} interventionId={interventionId} setCurrentFolder={setCurrentFolder} />
              </Grid>
            </React.Fragment>
          );
        }
      })}
      <Grid item xs={12}></Grid>
      {curentFolder.children.map((e) => {
        if (e.item_type === 'FOLDER') {
          if (e.category_name !== lastFolderCategory) {
            lastFolderCategory = e.category_name;
            showFolderCategoryHeader = true;
          } else {
            showFolderCategoryHeader = false;
          }
          return (
            <React.Fragment key={e.incident_log_preset_item_id}>
              {showFolderCategoryHeader ? <CategoryHeader value={e.category_name} /> : null}
              <Grid item xs={6}>
                <QuickEntryButton entry={e} interventionId={interventionId} setCurrentFolder={setCurrentFolder} />
              </Grid>
            </React.Fragment>
          );
        }
      })}
    </Grid>
  );
}
