import React from 'react';
import GenericCRUD from 'src/shared/genericCRUD';
import { useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useQuery } from 'react-query';
import { getData } from 'src/api/api';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import { useTranslation } from 'react-i18next';
import FormControl from '@mui/material/FormControl';
import Loader from 'src/shared/defaultLoader';

export default function InterventionLogCategory() {
  const { register, handleSubmit } = useForm();
  const { t } = useTranslation();
  let { id } = useParams();
  const getQueryFn = async ({ queryKey }) => await getData('intervention-log-category', { queryKey });

  const { data, error, isLoading, isError } = useQuery(['intervention-log-category', { id }], getQueryFn);

  if (isLoading) {
    return <Loader />;
  }
  if (isError) {
    return <span>Error : {error.message}</span>;
  }

  return (
    <GenericCRUD objectName={'intervention-log-category'} handleSubmit={handleSubmit} data={data}>
      <Grid item xs={12} sm={6}>
        <FormControl variant="standard" fullWidth={true}>
          <TextField
            {...register('name')}
            required
            autoComplete="off"
            id="name"
            name="name"
            label={t('CATEGORY_NAME')}
            fullWidth
            variant="standard"
            defaultValue={data.name}
          />
        </FormControl>
      </Grid>

      <Grid item xs={12} sm={6}>
        <FormControl variant="standard" fullWidth={true}>
          <TextField
            {...register('category_order')}
            required
            id="category_order"
            name="category_order"
            label={t('CATEGORY_ORDER')}
            fullWidth
            autoComplete="family-name"
            variant="standard"
            defaultValue={data.category_order}
          />
        </FormControl>
      </Grid>
    </GenericCRUD>
  );
}
