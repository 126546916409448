import React, { useState } from 'react';
import Grid from '@mui/material/Grid';
import { useTranslation } from 'react-i18next';
import CloseIncidentButton from './closeIncidentButton';
import Loader from 'src/shared/defaultLoader';
import { get } from 'src/api/api';
import { useQuery } from 'react-query';
import Button from '../../components/button/button';
import CircleNotificationsIcon from '@mui/icons-material/CircleNotifications';
import Colors from 'src/styles/variables';
import { useHistory } from 'react-router-dom';
import { AuthenticationService } from 'src/services/auth';
import { useQueryClient } from 'react-query';
import IncidentNotification from './incidentNotification';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import AlarmButton from '../../components/buttons/alarmButton/alarmButton';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import FeedIcon from '@mui/icons-material/Feed';
import IAmICButton from './iAmICButton';
import DispatchButton from './dispatchButton';
import CurrentIC from './currentIC';
import { createData } from 'src/api/api';
import RoomIcon from '@mui/icons-material/Room';
import WarningIcon from '@mui/icons-material/Warning';

export default function Incident() {
  const history = useHistory();

  const queryClient = useQueryClient();
  const globalParam = queryClient.getQueryData(['params/global']);

  const { t } = useTranslation();
  const [currentStatus, setCurrentStatus] = useState();

  const getQueryFn = async ({ queryKey }) => await get('user/currentUserStatus');
  const { isLoading } = useQuery(['currentUserStatus'], getQueryFn, {
    refetchInterval: 10000,
    onSuccess: (data) => {
      setCurrentStatus(data);
    },
  });

  const isIC = AuthenticationService.hasRole('INCIDENT_COMMANDER');
  const isSquadLeaderOrHigher = AuthenticationService.checkUserMaxAccessLevel() > 80;
  const currentUser = AuthenticationService.getCurrentUser();

  if (isLoading) {
    return <Loader />;
  }
  return (
    <>
      {currentStatus?.isInAlarm ? (
        <>
          {/* <Grid item xs={12} sx={{ color: 'text.secondary', textAlign: 'center' }}>
            <Typography variant="h6">{t('INCIDENTS')}</Typography>
          </Grid> */}
          {isIC && currentStatus?.incidentDetails?.incident_commander_id === null ? (
            <Grid item xs={12} sx={{ textAlign: 'center' }}>
              <IAmICButton />
            </Grid>
          ) : null}
          {currentStatus?.incidentDetails?.incident_commander_id === currentUser.member_id ? (
            <Grid item xs={12} sx={{ textAlign: 'center' }}>
              <CurrentIC />
            </Grid>
          ) : null}

          {isIC && globalParam.dispatch_settings_name && currentStatus?.incidentDetails?.incident_id ? (
            <Grid item xs={6}>
              <DispatchButton incidentId={currentStatus?.incidentDetails?.incident_id} />
            </Grid>
          ) : null}
          {/* <Grid item xs={12}>
            <WorkforceEngagedSummary inActionOnly />
          </Grid> */}

          <Grid item xs={isIC && globalParam.dispatch_settings_name ? 6 : 12}>
            <Button
              icon={CircleNotificationsIcon}
              iconColor={Colors.ALARM.darker}
              textColor={Colors.ALARM.darker}
              bgColor={Colors.ALARM.light}
              onClick={() => history.push(`/user/receipts`)}
            >
              {t('RECEIPTS')}
            </Button>
          </Grid>

          {globalParam.vhc_enabled ? (
            <Grid item xs={isIC ? 6 : 12}>
              <Button
                icon={LocalShippingIcon}
                iconColor={Colors.ALARM.dark}
                textColor={Colors.ALARM.dark}
                bgColor={Colors.ALARM.lighter}
                onClick={() => history.push(`/user/apparatus`)}
              >
                {t('VEHICLES_AND_MATERIALS')}
              </Button>
            </Grid>
          ) : null}

          {isIC && globalParam.dispatch_settings_name ? (
            <Grid item xs={globalParam.vhc_enabled ? 6 : 12}>
              <Button
                icon={GroupAddIcon}
                iconColor={Colors.ALARM.dark}
                textColor={Colors.ALARM.dark}
                bgColor={Colors.ALARM.lighter}
                onClick={() => history.push(`/user/reinforcements`)}
              >
                {t('REINFORCEMENTS')}
              </Button>
            </Grid>
          ) : null}

          {isSquadLeaderOrHigher ? (
            <Grid item xs={12}>
              <Button
                icon={FeedIcon}
                iconColor={Colors.ALARM.dark}
                textColor={Colors.ALARM.dark}
                bgColor={Colors.ALARM.lighter}
                onClick={() => history.push(`/user/intervention/log`)}
              >
                {t('INTERVENTION_LOG')}
              </Button>
            </Grid>
          ) : (
            <Grid item xs={12}>
              <Button
                icon={WarningIcon}
                iconColor={Colors.ALARM.dark}
                textColor={Colors.ALARM.dark}
                bgColor={Colors.ALARM.lighter}
                onClick={() => history.push(`/user/incident/${currentStatus?.incidentDetails?.incident_id}`)}
              >
                {t('INCIDENT')}
              </Button>
            </Grid>
          )}

          {currentStatus?.incidentDetails?.incident_address ? (
            <Grid item xs={12}>
              <Button
                icon={RoomIcon}
                iconColor={Colors.ALARM.dark}
                textColor={Colors.ALARM.dark}
                bgColor={Colors.ALARM.lighter}
                onClick={() => window.open(`https://www.google.com/maps/dir/Current+Location/${currentStatus.incidentDetails.incident_address}`, '_blank')}
              >
                {t('GPS')}
              </Button>
            </Grid>
          ) : null}

          <IncidentNotification />

          <Grid item xs={isIC && currentStatus?.incidentDetails?.incident_commander_id === currentUser.member_id ? 6 : 12}>
            <AlarmButton />
          </Grid>

          {isIC && currentStatus?.incidentDetails?.incident_commander_id === currentUser.member_id ? (
            <Grid item xs={6}>
              <CloseIncidentButton />
            </Grid>
          ) : null}
        </>
      ) : null}
    </>
  );
}
