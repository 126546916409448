function compare(a, b) {
  if (a.rank_order < b.rank_order) {
    return -1;
  }
  if (a.rank_order > b.rank_order) {
    return 1;
  }
  if (a.lastname < b.lastname) {
    return -1;
  }
  if (a.lastname > b.lastname) {
    return 1;
  }
  if (a.firstname < b.firstname) {
    return -1;
  }
  if (a.firstname > b.firstname) {
    return 1;
  }
  return 0;
}

function sortByRankAndName(data) {
  return data.sort(compare);
}

export const Utils = {
  sortByRankAndName,
};
