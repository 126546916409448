import api from 'src/api/axios';
// import { get } from 'src/api/api';

import { BehaviorSubject } from 'rxjs';
import jwt_decode from 'jwt-decode';

const currentUserSubject = new BehaviorSubject(JSON.parse(localStorage.getItem('currentUser')));
// currentUserSubject.subscribe(async (user) => {
//   if (user) {
//     const response = await get('params/currentUser');
//     console.log(response);
//   }
// });

const registerNewUser = async (userData) => {
  const { user_name, password, email } = userData;
  const result = await api.post('/user/register', { user_name, password, email });
  // console.log(result);
};

const login = async (login, password) => {
  try {
    const result = await api.post('/auth/login', { user_name: login, password: password });
    const user = JSON.stringify(result.data);
    localStorage.setItem('currentUser', user);
    currentUserSubject.next(result.data);
    return true;
  } catch (error) {
    return false;
  }
};

const setPassword = async (token, password) => {
  try {
    const result = await api.post('/auth/set-password', { token: token, password: password });
    if (result) {
      return result.data;
    }
    return false;
  } catch (error) {
    return false;
  }
};

const updatePassword = async (password) => {
  try {
    const result = await api.post('/auth/update-password', { password: password });
    if (result) {
      return result.data;
    }
    return false;
  } catch (error) {
    return false;
  }
};

const resetPassword = async (email) => {
  try {
    const result = await api.post('/auth/reset-password', { email: email });
    if (result) {
      return true;
    }
    return false;
  } catch (error) {
    return false;
  }
};

function getToken() {
  return localStorage.getItem('currentUser');
}

function getDecodedToken() {
  const currentUser = currentUserSubject.value;
  const token = currentUser?.access_token;
  if (token) {
    const myDecodedToken = jwt_decode(token);
    return myDecodedToken;
  } else {
    return null;
  }
}

function checkUserMaxAccessLevel() {
  const userToken = getDecodedToken();
  const roles = userToken.roles;
  let maxLevel = 0;
  for (const role of roles) {
    if (role.level > maxLevel) {
      maxLevel = role.level;
    }
  }
  return maxLevel;
}

function hasRole(roleName) {
  const userToken = getDecodedToken();
  const roles = userToken.roles;
  if (roles) {
    for (const role of roles) {
      if (role.name === roleName) {
        return true;
      }
    }
  }

  return false;
}

function getCurrentUser() {
  const userToken = getDecodedToken();
  return userToken;
}

function logout() {
  // remove user from local storage to log user out
  localStorage.removeItem('currentUser');
  currentUserSubject.next(null);
}

export const AuthenticationService = {
  login,
  logout,
  setPassword,
  registerNewUser,
  getToken,
  resetPassword,
  getDecodedToken,
  checkUserMaxAccessLevel,
  hasRole,
  getCurrentUser,
  updatePassword,
  currentUser: currentUserSubject.asObservable(),
  get currentUserValue() {
    return currentUserSubject.value;
  },
};
