import React from 'react';
import { useQuery } from 'react-query';
import { getAllData, get } from 'src/api/api';
import Grid from '@mui/material/Grid';
import Button from '../../components/button/button';
import Colors from 'src/styles/variables';
import { useTranslation } from 'react-i18next';
import Loader from 'src/shared/defaultLoader';
import { useUIContext } from '../../contexts/ui/ui.context';
import { useHistory } from 'react-router-dom';
import ContactPhoneIcon from '@mui/icons-material/ContactPhone';
import { useParams, useRouteMatch } from 'react-router-dom';
function Line({ group }) {
  const history = useHistory();
  let { url } = useRouteMatch();
  return (
    <Grid item xs={12}>
      <Button
        icon={ContactPhoneIcon}
        iconColor={Colors.button_default_icon}
        bgColor={Colors.button_default}
        onClick={() => history.push(`${url}/phone_books/${group.phone_directory_id}`)}
      >
        {group.phone_directory_name}
      </Button>
    </Grid>
  );
}

export default function DirectoriesList() {
  let { id } = useParams();
  const { t } = useTranslation();

  const { setAppBarTitle } = useUIContext();
  React.useEffect(() => {
    setAppBarTitle(t('PHONE_BOOKS'));
  }, []);

  const query = () => getAllData(`directory/group/${id}`);
  const { data, isLoading } = useQuery(`directory/group/${id}`, query);

  const getQueryFn = async () => await get(`directory/group/${id}`);
  const { isLoading: groupIsLoading } = useQuery(['directory/group/', id], getQueryFn, {
    onSuccess: (data) => {
      setAppBarTitle(`${t('PHONE_BOOKS')}: ${data.phone_directory_group_name}`);
    },
  });

  if (isLoading || groupIsLoading) {
    return <Loader />;
  }

  return (
    <Grid container spacing={2}>
      {data.map((group) => {
        return <Line key={group.phone_directory_id} group={group} />;
      })}
    </Grid>
  );
}
