import React from 'react';
import Box from '@mui/material/Box';
import TableauMembre from '../tableau-membre/tableau-membre.component';
import { styled } from '@mui/system';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import { Colors } from 'src/styles/variables';
import { useTranslation } from 'react-i18next';

const TableauSectionV = styled(TableauSectionBase)(({ theme }) => {
  // console.log(theme);
  return {
    ...theme.typography.body2,
    color: theme.palette.text.secondary,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  };
});

function TableauSectionBase({ sectionName, nameSuffix, namePrefix, section, isPrimary, displayAvailability }) {
  const { t } = useTranslation();

  let sectionColor = Colors.section;

  const SectionName = styled('h1')({
    color: sectionColor,
    textAlign: 'center',
  });

  let sectionTitle = sectionName;
  if (namePrefix) {
    sectionTitle = namePrefix + ' ' + sectionTitle;
  }

  if (nameSuffix) {
    sectionTitle = sectionTitle + ' ' + nameSuffix;
  }

  if (!section) return null;

  const nbDetails = section.reduce(
    (acc, currentData) => {
      if (currentData.status_code === 'AVAILABLE') {
        acc.available++;
      } else {
        acc.notAvailable++;
      }
      return acc;
    },
    { available: 0, notAvailable: 0 }
  );

  let titleAvailability = '';

  switch (displayAvailability) {
    case 'AVAILABLE_ONLY':
      titleAvailability = '' + nbDetails.available;
      break;
    case 'UNAVAILABLE_ONLY':
      titleAvailability = '' + nbDetails.notAvailable;
      break;
    default:
      titleAvailability = t('TITLE_TOTAL_AVAILABILITY', { avaialble: nbDetails.available, total: nbDetails.available + nbDetails.notAvailable });
      break;
  }

  return (
    <Box>
      <SectionName>
        {sectionTitle} - {titleAvailability}
      </SectionName>
      <TableContainer component={Paper}>
        <Table size="small" aria-label="a dense table">
          <TableBody>
            {section.map((membre) => {
              if (
                displayAvailability === 'ALL' ||
                (displayAvailability === 'AVAILABLE_ONLY' && membre.status_code === 'AVAILABLE') ||
                (displayAvailability === 'UNAVAILABLE_ONLY' && (membre.status_code === null || membre.status_code === 'NOT_AVAILABLE'))
              )
                return <TableauMembre member={membre} key={membre.member_id} />;
              else return null;
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}

const TableauSection = React.memo(TableauSectionV);
//const TableauMembre = TableauMembreV;
export default TableauSection;
