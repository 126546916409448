import React from 'react';
import GenericCRUD from 'src/shared/genericCRUD';
import { useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useQuery } from 'react-query';
import { getData } from 'src/api/api';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import { useTranslation } from 'react-i18next';
import FormControl from '@mui/material/FormControl';
import Loader from 'src/shared/defaultLoader';
import MapFileImport from './mapFileImport';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import MenuItem from '@mui/material/MenuItem';
import { MAP_FILE_TYPE } from 'src/constants/constants';
import ReactHookFormSelect from 'src/shared/reactHookFormSelect';

export default function MapFileDetail() {
  const { register, handleSubmit, control } = useForm();
  const { t } = useTranslation();
  let { id } = useParams();
  const getQueryFn = async ({ queryKey }) => await getData('map-files', { queryKey });

  const [fileName, setFileName] = React.useState('');

  const { data, error, isLoading, isError } = useQuery(['map-files', { id }], getQueryFn, {
    onSuccess: (data) => {
      if (data.map_file_url && data.map_file_url !== '') {
        const fileData = JSON.parse(data.map_file_url);
        setFileName(fileData.fileName);
      }
    },
  });

  if (isLoading) {
    return <Loader />;
  }
  if (isError) {
    return <span>Error : {error.message}</span>;
  }

  return (
    <GenericCRUD objectName={'map-files'} handleSubmit={handleSubmit} data={data} stayOnPage rowId={'map_file_id'}>
      <Grid item xs={12} sm={6}>
        <FormControl variant="standard" fullWidth={true}>
          <TextField
            {...register('map_file_name')}
            required
            autoComplete="off"
            id="map_file_name"
            name="map_file_name"
            label={t('NAME')}
            fullWidth
            variant="standard"
            defaultValue={data.map_file_name}
          />
        </FormControl>
      </Grid>

      <Grid item xs={12} sm={6}>
        <ReactHookFormSelect
          required
          register={register}
          id="map_file_type"
          name="map_file_type"
          label={t('FILE_TYPE')}
          control={control}
          defaultValue={data.map_file_type}
          variant="standard"
        >
          {MAP_FILE_TYPE.map((type) => {
            return (
              <MenuItem key={type.code} value={type.code}>
                {t(type.name)}
              </MenuItem>
            );
          })}
        </ReactHookFormSelect>
      </Grid>

      {id != 0 ? (
        <Grid item xs={12}>
          <Card>
            <CardHeader title={t('FILE')} />
            <CardContent>
              <Grid container spacing={3}>
                <Grid item xs={12} md={12}>
                  <FormControl variant="standard" fullWidth={true}>
                    <TextField
                      id="map_file_url"
                      name="map_file_url"
                      label={t('MAP_FILE_IMPORT')}
                      fullWidth
                      variant="standard"
                      value={fileName}
                      InputProps={{
                        readOnly: true,
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={12}>
                  <Box sx={{ display: 'flex', flexDirection: 'row-reverse' }}>
                    <Stack direction="row" spacing={1}>
                      <MapFileImport fileId={id} />
                    </Stack>
                  </Box>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      ) : null}
    </GenericCRUD>
  );
}
