import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

export default function Standing(props) {
  return (
    <SvgIcon {...props} viewBox="0 0 64 170">
      {/* <svg version="1.1" id="Layer_1" width="18px" height="18px" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 64 170"> */}
      <g>
        <g>
          <circle cx="32" cy="14" r="14" />
        </g>
        <g>
          <path
            d="M49,32h-3H18h-3C6.75,32,0,38.75,0,47v1v2v42c0,3.038,2.463,5.5,5.5,5.5S11,95.038,11,92V50h3v112c0,4.4,3.6,8,8,8
			s8-3.6,8-8V98h4v64c0,4.4,3.6,8,8,8s8-3.6,8-8V50h3v42c0,3.038,2.463,5.5,5.5,5.5S64,95.038,64,92V50v-2v-1
			C64,38.75,57.25,32,49,32z"
          />
        </g>
      </g>
      {/* </svg> */}
    </SvgIcon>
  );
}
