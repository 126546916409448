import React from 'react';
import { useTranslation } from 'react-i18next';
import Backdrop from '@mui/material/Backdrop';
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import AddAlertIcon from '@mui/icons-material/AddAlert';
import PersonIcon from '@mui/icons-material/Person';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import DoDisturbOffIcon from '@mui/icons-material/DoDisturbOff';
import useStore from './reinforcement.store';
import { useHistory } from 'react-router-dom';

export default function ReinforcementCall() {
  const { t } = useTranslation();
  const history = useHistory();

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const cancelSelection = useStore((state) => state.cancelSelection);

  return (
    <React.Fragment>
      <Backdrop open={open} />
      <SpeedDial
        ariaLabel="SpeedDial tooltip example"
        sx={{ position: 'fixed', bottom: 64, right: 16 }}
        icon={<AddAlertIcon />}
        onClose={handleClose}
        onOpen={handleOpen}
        open={open}
      >
        <SpeedDialAction
          icon={<PersonAddIcon />}
          tooltipTitle={t('REINFORCEMENT_ALARM_ALL')}
          tooltipOpen
          onClick={() => {
            history.push(`/user/reinforcements/message/all`);
            handleClose();
          }}
        />
        <SpeedDialAction
          icon={<PersonIcon />}
          tooltipTitle={t('REINFORCEMENT_ALARM_AVAILABLE_ONLY')}
          tooltipOpen
          onClick={() => {
            history.push(`/user/reinforcements/message/available`);
            handleClose();
          }}
        />
        <SpeedDialAction
          icon={<DoDisturbOffIcon />}
          tooltipTitle={t('CANCEL')}
          tooltipOpen
          onClick={() => {
            cancelSelection();
            handleClose();
          }}
        />
      </SpeedDial>
    </React.Fragment>
  );
}

{
}
