import React from 'react';
import Topbar from './components/topbar/topbar';
import Sidebar from './components/sidebar/sidebar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import { UIContextProvider } from './contexts/ui/ui.context';
import { Switch, Route, useRouteMatch, Redirect } from 'react-router-dom';
import Home from './pages/home/home';
import UserList from './pages/userList/userList';
import User from './pages/user/user';
import SectionsList from './pages/sectionsList/sectionsList';
import Section from './pages/section/section';
import RanksList from './pages/ranksList/ranksList';
import Rank from './pages/rank/rank';
import SpecializationList from './pages/specializationList/specializationList';
import Specialization from './pages/specialization/specialization';
import FunctionList from './pages/functionList/functionList';
import Function from './pages/function/function';
import UserAvailabilities from './pages/userAvailabilities/userAvailabilities';
import UserAvailabilitiesList from './pages/userAvailabilitiesList/userAvailabilitiesCalendar';
import UserFunction from './pages/userFunction/userFunction';
import UserFunctionList from './pages/userFunctionList/userFunctionList';
import DisplayShiftsList from './pages/displayShiftsList/displayShiftsList';
import DisplayShift from './pages/displayShift/displayShift';
import DisplayBoardList from './pages/displayBoardList/displayBoardList';
import DisplayBoard from './pages/displayBoard/displayBoard';
import DisplayBoardBlocList from './pages/displayBoardBlocList/displayBoardBlocList';
import DisplayBoardBloc from './pages/displayBoardBloc/displayBoardBloc';
import Access from './pages/access/access';
import LinksList from './pages/linksList/linksList';
import Link from './pages/link/link';
import Planning from './pages/planning/planning';
import GroupTypeList from './pages/groupTypeList/groupTypeList';
import GroupType from './pages/groupType/groupType';
import GroupList from './pages/groupList/groupList';
import Group from './pages/group/group';
import Parameters from './pages/parameters/parameters';
import AlarmTypesList from './pages/alarmTypes/alarmTypesList';
import AlarmTypesDetails from './pages/alarmTypes/alarmTypesDetails';
import DirectoryList from './pages/phoneBooks/directoryList';
import DirectoryGroupsList from './pages/phoneBooks/directoryGroupsList';
import DirectoryGroupsDetail from './pages/phoneBooks/directoryGroupsDetail';
import DirectoryDetail from './pages/phoneBooks/directoryDetail';
import DirectoryContactList from './pages/phoneBooks/directoryContactList';
import DirectoryContactDetail from './pages/phoneBooks/directoryContactDetail';
import MapLayerList from './pages/maps/mapLayerList';
import MapLayerDetail from './pages/maps/mapLayerDetail';
import MapFilesList from './pages/maps/mapFilesList';
import MapsList from './pages/maps/mapsList';
import MapDetail from './pages/maps/mapDetail';
import MapFileDetail from './pages/maps/mapFileDetail';
import theme from './admin.theme';
import { ThemeProvider } from '@mui/material/styles';
import DisplayShiftDetailsSection from './pages/displayShiftDetails/displayShiftDetailsSection';
import DisplayShiftDetailsGroup from './pages/displayShiftDetails/displayShiftDetailsGroup';
import ChangeLog from 'src/shared/changeLogAdmin';
import VehiclesList from './pages/vehiclesList/vehiclesList';
import Vehicle from './pages/vehicle/vehicle';
import MaterialList from './pages/materialList/materialList';
import Material from './pages/material/material';
import InterventionLogPresetsList from './pages/interventionLogPresets/interventionLogPresetsList';
import InterventionLogPresetEdit from './pages/interventionLogPresets/interventionLogPresetEdit';
import InterventionLogCategoriesList from './pages/interventionLogCategories/interventionLogCategoriesList';
import InterventionLogCategory from './pages/interventionLogCategories/interventionLogCategory';
import InterventionsList from './pages/interventionsList/interventionsList';
import IncidentDetail from '../user/pages/incidents/detail/incidentDetail';
import IncidentCardEdit from '../user/pages/incidents/card/incidentCardEdit';
import CurrentInterventionLog from '../user/pages/interventionLog/currentInterventionLog';
import OneInterventionLog from '../user/pages/interventionLog/oneInterventionLog';
import InterventionLogEntry from '../user/pages/interventionLog/entry/interventionLogEntry';
import InterventionLogQuickEntry from '../user/pages/interventionLog/quickEntry/interventionLogQuickEntry';
import InterventionLogReport from '../user/pages/interventionLog/report/interventionLogReport';

export default function Admin() {
  let { path } = useRouteMatch();

  return (
    <ThemeProvider theme={theme}>
      <UIContextProvider>
        <Box sx={{ display: 'flex' }}>
          <Topbar />

          <Sidebar />

          <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
            <Toolbar />
            <Switch>
              <Route path={`${path}/users`}>
                <UserList />
              </Route>
              <Route path={`${path}/user/:id/availabilities`}>
                <UserAvailabilitiesList />
              </Route>
              <Route path={`${path}/user/:id/availability`}>
                <UserAvailabilities />
              </Route>
              <Route path={`${path}/user/:id/function`}>
                <UserFunction />
              </Route>
              <Route path={`${path}/user/:id/functions`}>
                <UserFunctionList />
              </Route>
              <Route path={`${path}/user/:id/access`}>
                <Access />
              </Route>
              <Route path={`${path}/user/:id`}>
                <User />
              </Route>
              <Route path={`${path}/sections`}>
                <SectionsList />
              </Route>
              <Route path={`${path}/section/:id`}>
                <Section />
              </Route>
              <Route path={`${path}/group-types`}>
                <GroupTypeList />
              </Route>
              <Route path={`${path}/group-type/:id`}>
                <GroupType />
              </Route>
              <Route path={`${path}/groups`}>
                <GroupList />
              </Route>
              <Route path={`${path}/group/:id`}>
                <Group />
              </Route>
              <Route path={`${path}/display_shifts`}>
                <DisplayShiftsList />
              </Route>
              <Route path={`${path}/display_shift/:id/details`}>
                <DisplayShiftDetailsSection />
              </Route>
              <Route path={`${path}/display_shift/:id/details_group`}>
                <DisplayShiftDetailsGroup />
              </Route>
              <Route path={`${path}/display_shift/:id`}>
                <DisplayShift />
              </Route>
              <Route path={`${path}/ranks`}>
                <RanksList />
              </Route>
              <Route path={`${path}/rank/:id`}>
                <Rank />
              </Route>
              <Route path={`${path}/specializations`}>
                <SpecializationList />
              </Route>
              <Route path={`${path}/specialization/:id`}>
                <Specialization />
              </Route>
              <Route path={`${path}/functions`}>
                <FunctionList />
              </Route>
              <Route path={`${path}/function/:id`}>
                <Function />
              </Route>
              <Route path={`${path}/display_board/:board_id/bloc/:id`}>
                <DisplayBoardBloc />
              </Route>
              <Route path={`${path}/display_board/:id/blocs`}>
                <DisplayBoardBlocList />
              </Route>
              <Route path={`${path}/display_boards/`}>
                <DisplayBoardList />
              </Route>
              <Route path={`${path}/display_board/:id`}>
                <DisplayBoard />
              </Route>
              <Route path={`${path}/links`}>
                <LinksList />
              </Route>
              <Route path={`${path}/link/:id`}>
                <Link />
              </Route>
              <Route path={`${path}/planning`}>
                <Planning />
              </Route>
              <Route path={`${path}/parameters`}>
                <Parameters />
              </Route>
              <Route path={`${path}/alarm/types/:id`}>
                <AlarmTypesDetails />
              </Route>
              <Route path={`${path}/alarm_types`}>
                <AlarmTypesList />
              </Route>
              <Route path={`${path}/directory/group/:id`}>
                <DirectoryGroupsDetail />
              </Route>
              <Route path={`${path}/directory_groups`}>
                <DirectoryGroupsList />
              </Route>
              <Route path={`${path}/directory/contact/:id`}>
                <DirectoryContactDetail />
              </Route>
              <Route path={`${path}/contacts`}>
                <DirectoryContactList />
              </Route>
              <Route path={`${path}/directory/:id`}>
                <DirectoryDetail />
              </Route>
              <Route path={`${path}/directory`}>
                <DirectoryList />
              </Route>
              <Route path={`${path}/map-layers/:id`}>
                <MapLayerDetail />
              </Route>
              <Route path={`${path}/map_layers`}>
                <MapLayerList />
              </Route>
              <Route path={`${path}/map-files/:id`}>
                <MapFileDetail />
              </Route>
              <Route path={`${path}/map_files`}>
                <MapFilesList />
              </Route>
              <Route path={`${path}/maps/:id`}>
                <MapDetail />
              </Route>
              <Route path={`${path}/maps`}>
                <MapsList />
              </Route>
              <Route path={`${path}/changelog`}>
                <ChangeLog />
              </Route>
              <Route path={`${path}/vehicles`}>
                <VehiclesList />
              </Route>
              <Route path={`${path}/vehicle/:id`}>
                <Vehicle />
              </Route>
              <Route path={`${path}/materials`}>
                <MaterialList />
              </Route>
              <Route path={`${path}/material/:id`}>
                <Material />
              </Route>
              <Route path={`${path}/intervention-log-presets/:id`}>
                <InterventionLogPresetsList />
              </Route>
              <Route path={`${path}/intervention-log-presets`}>
                <InterventionLogPresetsList />
              </Route>
              <Route path={`${path}/intervention-log-preset/:id`}>
                <InterventionLogPresetEdit />
              </Route>
              <Route path={`${path}/intervention-log-categories`}>
                <InterventionLogCategoriesList />
              </Route>
              <Route path={`${path}/intervention-log-category/:id`}>
                <InterventionLogCategory />
              </Route>
              <Route path={`${path}/interventions-list`}>
                <InterventionsList />
              </Route>
              <Route path={`${path}/intervention/:id/log/report`}>
                <InterventionLogReport />
              </Route>
              <Route path={`${path}/incident/:id/edit`}>
                <IncidentCardEdit />
              </Route>
              <Route path={`${path}/incident/:id`}>
                <IncidentDetail />
              </Route>
              <Route path={`${path}/intervention/:interventionId/log/entry/:id`}>
                <InterventionLogEntry />
              </Route>
              <Route path={`${path}/intervention/:interventionId/log/quickEntry`}>
                <InterventionLogQuickEntry />
              </Route>
              <Route path={`${path}/intervention/:id/log`}>
                <OneInterventionLog />
              </Route>

              <Route exact path={path}>
                <Redirect push to={`${path}/users`} />;{/* <Home /> */}
              </Route>
            </Switch>
          </Box>
        </Box>
      </UIContextProvider>
    </ThemeProvider>
  );
}
