import React from 'react';
import { Page, Text, Image, Document, StyleSheet, Font, View } from '@react-pdf/renderer';
import { DateUtiles } from 'src/services/dateUtiles';
import { useTranslation } from 'react-i18next';
import { DurationUtils } from 'src/services/durations';
import logo from 'src/images/Alarmo_simple_full.png';

// Create styles
Font.register({
  family: 'Roboto',
  src: 'https://fonts.gstatic.com/s/roboto/v16/zN7GBFwfMP4uA6AR0HCoLQ.ttf',
});
Font.register({
  family: 'Roboto-900',
  src: 'https://fonts.gstatic.com/s/roboto/v15/H1vB34nOKWXqzKotq25pcg.ttf',
});

const styles = StyleSheet.create({
  body: {
    paddingTop: 25,
    paddingBottom: 25,
    paddingHorizontal: 35,
  },
  headerImage: {
    marginVertical: 15,
    marginHorizontal: 20,
    position: 'absolute',
    top: 0,
    right: 0,
    height: 40,
    padding: 10,
  },
  title: {
    fontSize: 24,
    textAlign: 'center',
    fontFamily: 'Roboto',
    marginTop: 50,
    marginBottom: 40,
  },
  author: {
    fontSize: 12,
    textAlign: 'center',
    marginBottom: 40,
    fontFamily: 'Roboto',
  },
  titleDetail: {
    fontSize: 12,
    textAlign: 'center',
    fontFamily: 'Roboto',
  },
  commentAuthor: {
    marginLeft: 5,
    fontSize: 10,
    fontFamily: 'Roboto',
  },
  subtitle: {
    fontSize: 13,
    fontFamily: 'Roboto',
  },
  subtitleBold: {
    fontSize: 13,
    fontFamily: 'Roboto-900',
  },
  text: {
    marginLeft: 5,
    fontSize: 12,
    textAlign: 'justify',
    fontFamily: 'Roboto',
    marginBottom: 20,
  },
  section: {
    fontWeight: 'bold',
    marginLeft: 0,
    fontSize: 14,
    textAlign: 'justify',
    fontFamily: 'Roboto-900',
    marginTop: 20,
    marginBottom: 5,
  },

  header: {
    fontSize: 12,
    marginBottom: 20,
    textAlign: 'center',
    color: 'grey',
    fontFamily: 'Roboto',
  },
  pageNumber: {
    position: 'absolute',
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: 'center',
    color: 'grey',
    fontFamily: 'Roboto',
  },
  footer: {
    position: 'absolute',
    fontSize: 12,
    bottom: 15,
    left: 0,
    right: 0,
    textAlign: 'center',
    color: 'grey',
    fontFamily: 'Roboto',
  },
  endOfStaff: {
    marginBottom: 20,
  },
  incident: {
    paddingBottom: 5,
  },
});

// Create Document Component
export default function MyDocument({ logData, incidentData, staffData, globalParam, b2Url, vhcData, matData }) {
  const { t } = useTranslation();

  let dateAndDurationSubHeader = DateUtiles.toDateTime(incidentData.creation_date);
  if (incidentData.end_date) {
    dateAndDurationSubHeader += ` - ${DateUtiles.toDateTime(incidentData.end_date)}`;
    dateAndDurationSubHeader += ` (${DurationUtils.toReadableDurationBetweenTwoDate(incidentData.creation_date, incidentData?.end_date ?? '')})`; //
  } else {
    dateAndDurationSubHeader += ` (${DurationUtils.toReadableDurationBetweenTwoDate(incidentData.creation_date, Date.now())})`; //
  }

  return (
    <Document>
      <Page style={styles.body}>
        <Image style={styles.headerImage} src={logo} fixed />

        <Text style={styles.title}>{t('INTERVENTION_LOG_REPORT_TITLE')}</Text>

        <Text style={styles.section}>{t('INCIDENT')}</Text>
        <Text style={styles.subtitle}>{incidentData.incident_description}</Text>
        <Text style={styles.subtitle}>
          <Text style={styles.subtitleBold}>{`${t('EVENT_DATE')}:`}</Text> {`${dateAndDurationSubHeader}`}
        </Text>
        <Text style={styles.subtitle}>
          <Text style={styles.subtitleBold}>{`${t('ALARM_ADDRESS')}:`}</Text> {`${incidentData.incident_address}`}
        </Text>
        <Text style={styles.subtitle}>
          <Text style={styles.subtitleBold}>{`${t('INCIDENT_COMMANDER')}:`}</Text>{' '}
          {`${incidentData.rank_abbreviation} ${incidentData.lastname} ${incidentData.firstname}`}
        </Text>

        <Text style={styles.section}>{t('INTERVENTION_LOG')}</Text>
        {logData.toReversed().map((log, index) => {
          return (
            <View key={log.incident_log_id} wrap={false}>
              <Text style={styles.subtitle}>
                {DateUtiles.toTime(log.event_date)} - {DateUtiles.toDate(log.event_date)}
              </Text>
              <Text style={styles.commentAuthor}>{`${log.rank_abbreviation} ${log.lastname} ${log.firstname}`}</Text>
              {(() => {
                switch (log.entry_type) {
                  case 'PHOTO':
                    let fileData = JSON.parse(log.comment);
                    return (
                      <View style={{ width: 420, textAlign: 'right', width: '75%', marginVertical: 20, marginHorizontal: 50 }}>
                        <Image
                          style={{ marginHorizontal: '10%', width: 'auto', height: 'auto' }}
                          src={`${globalParam.files_server || b2Url}${fileData.smFileName}`}
                        />
                      </View>
                    );
                  case 'COMMENT':
                    return <Text style={styles.text}>{log.comment}</Text>;
                  case 'AUTO':
                    return <Text style={styles.text}>{t(log.comment)}</Text>;
                }
              })()}
            </View>
          );
        })}
        <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages} `} fixed />
        <Text style={styles.footer} render={() => `alarmo.ch`} fixed />
      </Page>

      <Page style={styles.body}>
        <Text style={styles.section}>{`${t('STAFF')} (${staffData.length})`}</Text>
        {staffData.map((staff, index) => {
          return (
            <View key={staff[0].alarm_receipt_id} wrap={false}>
              <Text style={styles.subtitle}>{`${staff[0].rank_abbreviation} ${staff[0].lastname} ${staff[0].firstname}`}</Text>
              {staff?.map((line) => {
                return (
                  <Text style={styles.commentAuthor}>
                    {`${DateUtiles.toDateTime(line.receipt_date)} - ${line.end_date ? DateUtiles.toDateTime(line.end_date) : t('IN_PROGRESS')} `}
                    {` (${DurationUtils.toReadableDurationBetweenTwoDate(line.receipt_date, line?.end_date ?? '')})`}
                  </Text>
                );
              })}
              <Text style={styles.endOfStaff}></Text>
            </View>
          );
        })}
        <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages} `} fixed />
        <Text style={styles.footer} render={() => `alarmo.ch`} fixed />
      </Page>

      <Page style={styles.body}>
        <Text style={styles.section}>{`${t('VEHICLES')} (${vhcData.length})`}</Text>
        {vhcData.map((vehicle, index) => {
          return (
            <View key={vehicle[0].vehicle_code} wrap={false}>
              <Text style={styles.subtitle}>{`${vehicle[0].vehicle_code} - ${vehicle[0].vehicle_name}`}</Text>
              {vehicle?.map((line) => {
                return (
                  <Text style={styles.commentAuthor}>
                    {`${t(line.vehicle_status)} - ${DateUtiles.toDateTime(line.start_date)} `}
                    {` - ${line.end_date ? DateUtiles.toDateTime(line.end_date) : t('IN_PROGRESS')}  (${DurationUtils.toReadableDurationBetweenTwoDate(
                      line.start_date,
                      line?.end_date ?? ''
                    )})`}
                  </Text>
                );
              })}
              <Text style={styles.endOfStaff}></Text>
            </View>
          );
        })}

        <Text style={styles.section}>{`${t('Materials')} (${matData.length})`}</Text>
        {matData.map((material, index) => {
          return (
            <View key={material[0].material_code} wrap={false}>
              <Text style={styles.subtitle}>{`${material[0].material_code} - ${material[0].material_name}`}</Text>
              {material?.map((line) => {
                return (
                  <Text style={styles.commentAuthor}>
                    {`${t(line.material_status)} - ${DateUtiles.toDateTime(line.start_date)} `}
                    {`${line.end_date ? DateUtiles.toDateTime(line.end_date) : t('IN_PROGRESS')}  (${DurationUtils.toReadableDurationBetweenTwoDate(
                      line.start_date,
                      line?.end_date ?? ''
                    )})${line.vehicle_code ? `- (${line.vehicle_code} - ${line.vehicle_name})` : ''}`}
                  </Text>
                );
              })}
              <Text style={styles.endOfStaff}></Text>
            </View>
          );
        })}

        <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages} `} fixed />
        <Text style={styles.footer} render={() => `alarmo.ch`} fixed />
      </Page>
    </Document>
  );
}
