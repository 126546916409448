import React, { useState, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Loader from 'src/shared/defaultLoader';
import { get } from 'src/api/api';
import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';
import { Utils } from 'src/services/utils';

export default function MemberSelector({ defaultMemberId, role_id, specialization_id, onChange, label = '', inputRef, disabled }) {
  const [memberList, setMemberList] = useState();
  const { t } = useTranslation();

  const [value, setValue] = React.useState(null);
  const [inputValue, setInputValue] = React.useState('');

  const getQueryFn = async ({ queryKey }) => await get('user/search', queryKey[1]);
  const { data, isLoading } = useQuery(
    ['user/search', { role_id: role_id, specialization_id: specialization_id, member_type: 'FIRST_RESPONDER' }],
    getQueryFn,
    {
      onSuccess: (data) => {
        if (data[0].member_id !== null) {
          data.unshift({ member_id: null, lastname: '', firstname: '', rank_abbreviation: '', rank_order: 0 });
        }

        setMemberList(data);
        if (defaultMemberId) {
          setValue(data.find((e) => e.member_id === defaultMemberId));
        }
      },
    }
  );

  useEffect(() => {
    if (defaultMemberId && memberList) {
      setValue(memberList.find((e) => e.member_id === defaultMemberId));
    } else {
      setValue(null);
    }
  }, [defaultMemberId, memberList]);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <div>
      <form autoComplete="off">
        <Autocomplete
          disabled={disabled}
          noOptionsText={t('NO_OPTION')}
          fullWidth
          value={value}
          onChange={(event, newValue) => {
            setValue(newValue);
            onChange(newValue);
          }}
          inputValue={inputValue}
          onInputChange={(event, newInputValue) => {
            setInputValue(newInputValue);
          }}
          options={data}
          getOptionLabel={(option) =>
            option.member_id === null ? '⠀' : `${t('RANK_ABBR.' + option.rank_abbreviation)} ${option.lastname} ${option.firstname}`
          }
          renderInput={(params) => <TextField variant="standard" fullWidth autoComplete="off" {...params} label={label} inputRef={inputRef} />}
        />
      </form>
    </div>
  );
}
