import React, { useEffect } from 'react';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import { useUIContext } from '../../contexts/ui/ui.context';
import Button from '../../components/button/button';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import { useParams, useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import DoNotDisturbOnIcon from '@mui/icons-material/DoNotDisturbOn';
import { Colors } from 'src/styles/variables';
import { post } from 'src/api/api';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';

export default function QuickStatusChange() {
  const queryClient = useQueryClient();
  const userParam = queryClient.getQueryData(['params/currentUser']);

  const { t } = useTranslation();

  const history = useHistory();

  let { status } = useParams();

  const { setAppBarTitle } = useUIContext();
  useEffect(() => {
    setAppBarTitle(t('STATUS_CHANGE'));
  }, []);

  const { register, handleSubmit } = useForm();
  const onSubmit = handleSubmit(async (data) => {
    // console.log(data);
    data.status = status;
    await post('availability/quick', data);
    history.goBack();
  });
  const AvailableButton = () => (
    <Button
      icon={EventAvailableIcon}
      textColor={Colors.AVAILABLE.darker}
      iconColor={Colors.AVAILABLE.darker}
      bgColor={Colors.AVAILABLE.light}
      onClick={() => onSubmit()}
    >
      {t('AVAILABLE')}
    </Button>
  );

  const AbsentButton = () => (
    <Button
      icon={DoNotDisturbOnIcon}
      textColor={Colors.NOT_AVAILABLE.darker}
      iconColor={Colors.NOT_AVAILABLE.darker}
      bgColor={Colors.NOT_AVAILABLE.light}
      onClick={() => onSubmit()}
    >
      {t('NOT_AVAILABLE')}
    </Button>
  );

  return (
    <Box>
      <form onSubmit={onSubmit}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <FormControl variant="standard" fullWidth={true}>
              <TextField
                {...register('days')}
                required
                autoComplete="off"
                id="days"
                name="days"
                label={t('DAYS')}
                fullWidth
                variant="standard"
                inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                defaultValue={userParam.default_time_days || 0}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl variant="standard" fullWidth={true}>
              <TextField
                {...register('hours')}
                required
                autoComplete="off"
                id="hours"
                name="hours"
                label={t('HOURS')}
                fullWidth
                variant="standard"
                defaultValue={userParam.default_time_hours || 2}
                inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                onFocus={(event) => {
                  event.target.select();
                }}
                autoFocus={true}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl variant="standard" fullWidth={true}>
              <TextField
                {...register('minutes')}
                required
                autoComplete="off"
                id="minutes"
                name="minutes"
                label={t('MINUTES')}
                fullWidth
                variant="standard"
                inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                defaultValue={userParam.default_time_minutes || 0}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            {status === 'AVAILABLE' ? <AvailableButton /> : null}
            {status === 'NOT_AVAILABLE' ? <AbsentButton /> : null}
          </Grid>
        </Grid>
      </form>
    </Box>
  );
}
