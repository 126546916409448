import React from 'react';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import ButtonGroup from '@mui/material/ButtonGroup';
import Box from '@mui/material/Box';
import VehicleOnSiteIcon from 'src/icons/v_on_site';
import VehicleOnWayIcon from 'src/icons/v_on_way';
import VehicleEngagedIcon from 'src/icons/v_engaged';
import VehicleAvailableIcon from 'src/icons/v_available';
import VehicleNotAvailableIcon from 'src/icons/v_not_available';
import VehicleOutOfOrderIcon from 'src/icons/v_out_of_order';
import VTpIcon from 'src/icons/vhc/vtp.icon';
import VtractIcon from 'src/icons/vhc/vtrac.icon';
import VTechIcon from 'src/icons/vhc/vtech.Icon';
import VEAIcon from 'src/icons/vhc/vea.icon';
import VSRIcon from 'src/icons/vhc/vsr.icon';
import VLOGIcon from 'src/icons/vhc/vlog.icon';
import VCHIcon from 'src/icons/vhc/vch.icon';
import VDCHIcon from 'src/icons/vhc/vdch.icon';
import VCIIcon from 'src/icons/vhc/vci.icon';
import VcpontIcon from 'src/icons/vhc/vcpont.icon';

export default function VehicleButton({ vehicle }) {
  let Icon = EventAvailableIcon;
  let ExtraIcon = null;
  let color = 'vehicle_available';
  switch (vehicle.vehicle_status) {
    case 'ENGAGED':
      color = 'vehicle_engaged';
      ExtraIcon = VehicleEngagedIcon;
      break;
    case 'ON_SITE':
      color = 'vehicle_on_site';
      ExtraIcon = VehicleOnSiteIcon;
      break;
    case 'ON_WAY':
      color = 'vehicle_on_way';
      ExtraIcon = VehicleOnWayIcon;
      break;
    case 'OUT_OF_ORDER':
      color = 'vehicle_out_of_order';
      ExtraIcon = VehicleOutOfOrderIcon;
      break;

    case 'NOT_AVAILABLE':
      color = 'vehicle_not_available';
      ExtraIcon = VehicleNotAvailableIcon;
      break;

    case 'TRAINING':
      color = 'vehicle_training';
      ExtraIcon = VehicleAvailableIcon;
      break;

    default:
      color = 'neutral';
      ExtraIcon = VehicleAvailableIcon;
      break;
  }
  switch (vehicle.vehicle_type) {
    case 'VTR':
      Icon = VtractIcon;
      break;
    case 'VTP':
      Icon = VLOGIcon;
      break;
    case 'VCC':
      Icon = VLOGIcon;
      break;
    case 'TP FR':
      Icon = VTpIcon;
      break;
    case 'TPL':
      Icon = VTpIcon;
      break;
    case 'VEA':
      Icon = VEAIcon;
      break;
    case 'SSO':
      Icon = VLOGIcon;
      break;
    case 'VDC':
      Icon = VCHIcon;
      break;
    case 'VDH':
      Icon = VDCHIcon;
      break;
    case 'VGM':
      Icon = VLOGIcon;
      break;
    case 'VPI':
      Icon = VSRIcon;
      break;
    case 'VST':
      Icon = VTechIcon;
      break;
    case 'VTM':
      Icon = VTechIcon;
      break;
    case 'VCI':
      Icon = VCIIcon;
      break;
    case 'VCPONT':
      Icon = VcpontIcon;
      break;
    default:
      Icon = VtractIcon;
      break;
  }

  return (
    <Grid container spacing={1}>
      {ExtraIcon ? (
        <Grid item xs={2}>
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
            <ExtraIcon />
          </Box>
        </Grid>
      ) : null}

      <Grid item xs={ExtraIcon ? 10 : 12}>
        <ButtonGroup size={'small'} sx={{ height: '100%' }} color={color} fullWidth variant="contained" aria-label="split button">
          <Button
            sx={{ height: '100%', p: 0.5 }}
            variant="contained"
            startIcon={<Icon style={{ height: '39px', width: '100px', color: 'blue' }} />}
            size="small"
            color={color}
          >
            {vehicle.vehicle_code} / {vehicle.vehicle_name}
            {vehicle?.firstname ? (
              <>
                <br />
                {vehicle.firstname} {vehicle.lastname}
              </>
            ) : null}
          </Button>
        </ButtonGroup>
      </Grid>
    </Grid>
  );
}
