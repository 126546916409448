import React from 'react';
import GenericCRUD from 'src/shared/genericCRUD';
import { useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useQuery } from 'react-query';
import { getData } from 'src/api/api';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import { useTranslation } from 'react-i18next';
import FormControl from '@mui/material/FormControl';
import Loader from 'src/shared/defaultLoader';
import ReactHookFormSelect from 'src/shared/reactHookFormSelect';
import MenuItem from '@mui/material/MenuItem';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Switch from '@mui/material/Switch';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';

import { Controller } from 'react-hook-form';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import frLocale from 'date-fns/locale/fr-CH';
import { PERIODICITY_DAYS } from 'src/constants/constants';
import PopulatedSelect from 'src/shared/populatedSelect';
import { FUNCTION_TYPE } from 'src/constants/constants';

export default function Function() {
  const [startTime, setStartTime] = React.useState('');
  const [startHour, setStartHour] = React.useState('');
  const [startMinute, setStartMinute] = React.useState('');
  const [endTime, setEndTime] = React.useState('');
  const [endHour, setEndHour] = React.useState('');
  const [endMinute, setEndMinute] = React.useState('');
  const [periodicityEnable, setPeriodicityEnable] = React.useState(false);
  const [requierementNumberDisabled, setRequierementNumberDisabled] = React.useState(false);

  const { register, watch, handleSubmit, control, setValue } = useForm();
  const { t } = useTranslation();
  let { id } = useParams();
  const getQueryFn = async ({ queryKey }) => await getData('function', { queryKey });

  const { data, error, isLoading, isError } = useQuery(['function', { id }], getQueryFn, {
    onSuccess: (data) => {
      if (data.periodicity_start_hour !== '') {
        let start_time = new Date();
        start_time.setHours(data.periodicity_start_hour);
        start_time.setMinutes(data.periodicity_start_minute);
        setStartTime(start_time);
      }
      if (data.periodicity_end_hour !== '') {
        let end_time = new Date();
        end_time.setHours(data.periodicity_end_hour);
        end_time.setMinutes(data.periodicity_end_minute);
        setEndTime(end_time);
      }

      setPeriodicityEnable(data.periodicity_type !== null);
      setRequierementNumberDisabled(data.function_type !== 'MEMBER');
      setValue('periodicity_start_hour', data.periodicity_start_hour, { shouldValidate: true, shouldDirty: true });
      setValue('periodicity_start_minute', data.periodicity_start_minute, { shouldValidate: true, shouldDirty: true });
      setValue('periodicity_end_hour', data.periodicity_end_hour, { shouldValidate: true, shouldDirty: true });
      setValue('periodicity_end_minute', data.periodicity_end_minute, { shouldValidate: true, shouldDirty: true });
    },
  });

  React.useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      if (name === 'periodicity_enabled') {
        setPeriodicityEnable(value.periodicity_enabled);
      }
      if (name === 'function_type') {
        setValue('periodicity_requierement_number', '1', { shouldValidate: true, shouldDirty: true });
        setRequierementNumberDisabled(value.function_type !== 'MEMBER');
      }
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  if (isLoading) {
    return <Loader />;
  }
  if (isError) {
    return <span>Error : {error.message}</span>;
  }

  return (
    <GenericCRUD objectName={'function'} handleSubmit={handleSubmit} data={data}>
      <Grid item xs={12} sm={6}>
        <FormControl variant="standard" fullWidth={true}>
          <TextField
            {...register('function_name')}
            required
            autoComplete="off"
            id="function_name"
            name="function_name"
            label={t('FUNCTION')}
            fullWidth
            variant="standard"
            defaultValue={data.function_name}
          />
        </FormControl>
      </Grid>

      <Grid item xs={12} sm={3}>
        <FormControl variant="standard" fullWidth={true}>
          <TextField
            {...register('function_abbreviation')}
            id="function_abbreviation"
            name="function_abbreviation"
            label={t('ABBREVIATION')}
            fullWidth
            autoComplete="family-name"
            variant="standard"
            defaultValue={data.function_abbreviation}
          />
        </FormControl>
      </Grid>

      <Grid item xs={12} sm={3}>
        <FormControl variant="standard" fullWidth={true}>
          <TextField
            {...register('function_order')}
            required
            id="function_order"
            name="function_order"
            label={t('FUNCRION_ORDER')}
            fullWidth
            autoComplete="family-name"
            variant="standard"
            defaultValue={data.function_order}
          />
        </FormControl>
      </Grid>

      <Grid item xs={12} sm={6}>
        <ReactHookFormSelect
          register={register}
          id="function_type"
          name="function_type"
          label={t('FUNCTION_TYPE')}
          control={control}
          defaultValue={data.function_type}
          variant="standard"
          onChange={(e) => console.log(e)}
        >
          {FUNCTION_TYPE.map((type) => {
            return (
              <MenuItem key={type.code} value={type.code}>
                {t(type.name)}
              </MenuItem>
            );
          })}
        </ReactHookFormSelect>
      </Grid>

      <Grid item xs={12} md={6}>
        <FormControl variant="standard" fullWidth={true}>
          <TextField
            {...register('rank_color_code')}
            id="rank_color_code"
            name="rank_color_code"
            label={t('RANK_COLOR')}
            fullWidth
            autoComplete="family-name"
            variant="standard"
            defaultValue={data.rank_color_code}
          />
        </FormControl>
      </Grid>
      {/* <Grid item xs={12} md={4}>
        <FormControl variant="standard" fullWidth={true}>
          <TextField
            {...register('background_color_code')}
            id="background_color_code"
            name="background_color_code"
            label={t('BACKGROUND_COLOR')}
            fullWidth
            autoComplete="family-name"
            variant="standard"
            defaultValue={data.background_color_code}
          />
        </FormControl>
      </Grid>
      <Grid item xs={12} md={4}>
        <FormControl variant="standard" fullWidth={true}>
          <TextField
            {...register('iconName')}
            id="iconName"
            name="iconName"
            label={t('ICON')}
            fullWidth
            autoComplete="family-name"
            variant="standard"
            defaultValue={data.iconName}
          />
        </FormControl>
      </Grid> */}
      <Grid item xs={12}>
        <Card>
          <CardHeader
            title={t('PERIODICITY')}
            // action={<Switch {...register('periodicity_enabled')} defaultChecked={data.periodicity_type !== null} name="periodicity_enabled" />}
          />
          <CardContent>
            <Grid container spacing={3}>
              <Grid item xs={12} md={3}>
                <ReactHookFormSelect
                  register={register}
                  required={periodicityEnable}
                  id="periodicity_start_day"
                  name="periodicity_start_day"
                  label={t('PERIODICITY_START_DAY')}
                  control={control}
                  defaultValue={data.periodicity_start_day}
                  variant="standard"
                >
                  {PERIODICITY_DAYS.map((type) => {
                    return (
                      <MenuItem key={type.code} value={type.code}>
                        {t(type.name)}
                      </MenuItem>
                    );
                  })}
                </ReactHookFormSelect>
              </Grid>
              <Grid item xs={12} md={3}>
                <FormControl variant="standard" fullWidth={true}>
                  <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={frLocale}>
                    <Controller
                      control={control}
                      id="start_time"
                      name="start_time"
                      defaultValue={data.start_time}
                      render={({ field }) => (
                        <TimePicker
                          {...field}
                          label={t('START_TIME')}
                          value={startTime}
                          onChange={(newValue) => {
                            // newValue.setSeconds(0);
                            // newValue.setMilliseconds(0);
                            // setValue('start_time', fromDate, { shouldValidate: true, shouldDirty: true });
                            field.onChange(newValue);
                            const startDate = new Date(newValue);
                            let periodicity_start_hour = startDate.getHours();
                            let periodicity_start_minute = startDate.getMinutes();
                            setValue('periodicity_start_hour', periodicity_start_hour, { shouldValidate: true, shouldDirty: true });
                            setValue('periodicity_start_minute', periodicity_start_minute, { shouldValidate: true, shouldDirty: true });
                            setStartTime(newValue);
                          }}
                          renderInput={(params) => <TextField required={periodicityEnable} fullWidth variant="standard" {...params} />}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </FormControl>
              </Grid>

              <Grid item xs={12} md={3}>
                <ReactHookFormSelect
                  register={register}
                  required={periodicityEnable}
                  id="periodicity_end_day"
                  name="periodicity_end_day"
                  label={t('PERIODICITY_END_DAY')}
                  control={control}
                  defaultValue={data.periodicity_end_day}
                  variant="standard"
                >
                  {PERIODICITY_DAYS.map((type) => {
                    return (
                      <MenuItem key={type.code} value={type.code}>
                        {t(type.name)}
                      </MenuItem>
                    );
                  })}
                </ReactHookFormSelect>
              </Grid>
              <Grid item xs={12} md={3}>
                <FormControl variant="standard" fullWidth={true}>
                  <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={frLocale}>
                    <Controller
                      control={control}
                      id="end_time"
                      name="end_time"
                      defaultValue={data.end_time}
                      render={({ field }) => (
                        <TimePicker
                          {...field}
                          label={t('END_TIME')}
                          value={endTime}
                          onChange={(newValue) => {
                            // newValue.setSeconds(0);
                            // newValue.setMilliseconds(0);

                            field.onChange(newValue);
                            const endDate = new Date(newValue);
                            let periodicity_end_hour = endDate.getHours();
                            let periodicity_end_minute = endDate.getMinutes();
                            setValue('periodicity_end_hour', periodicity_end_hour, { shouldValidate: true, shouldDirty: true });
                            setValue('periodicity_end_minute', periodicity_end_minute, { shouldValidate: true, shouldDirty: true });
                            setEndTime(newValue);
                          }}
                          renderInput={(params) => <TextField required={periodicityEnable} fullWidth variant="standard" {...params} />}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </FormControl>
              </Grid>
              {requierementNumberDisabled ? null : (
                <Grid item xs={12} md={6}>
                  <FormControl variant="standard" fullWidth={true}>
                    <TextField
                      {...register('periodicity_requierement_number')}
                      id="periodicity_requierement_number"
                      name="periodicity_requierement_number"
                      label={t('PERIODICITY_REQUIEREMENT_NUMBER')}
                      fullWidth
                      autoComplete="family-name"
                      disabled={requierementNumberDisabled}
                      variant="standard"
                      defaultValue={data.periodicity_requierement_number}
                      type="number"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </FormControl>
                </Grid>
              )}

              {requierementNumberDisabled ? null : (
                <Grid item xs={12} md={6}>
                  <PopulatedSelect
                    control={control}
                    value={data.specialization_id}
                    endoint={'specialization'}
                    name={'specialization_id'}
                    id={'specialization_id'}
                    label={t('SPECIALIZATION')}
                    withBlankOption
                    displayAttribute={'specialization_name'}
                  />
                </Grid>
              )}
              {/* <Grid item xs={12} md={4}>
                <PopulatedSelect
                  control={control}
                  value={data.role_id}
                  endoint={'role'}
                  name={'role_id'}
                  id={'role_id'}
                  label={t('ROLE')}
                  withBlankOption
                  displayAttribute={'role_name'}
                />
              </Grid> */}
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </GenericCRUD>
  );
}
