import React from 'react';
import Grid from '@mui/material/Grid';
import YearSelector from 'src/shared/yearSelector';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useTranslation } from 'react-i18next';
import { DateUtiles } from 'src/services/dateUtiles';
import { get } from 'src/api/api';
import { useQuery } from 'react-query';
import Loader from 'src/shared/defaultLoader';
import { Colors } from 'src/styles/variables';
import IconButton from '@mui/material/IconButton';
import ModeEditOutlineIcon from '@mui/icons-material/ModeEditOutline';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { AuthenticationService } from 'src/services/auth';
import { useUIContext } from '../../contexts/ui/ui.context';

export default function MyDuty() {
  const { t } = useTranslation();
  const history = useHistory();
  let { url } = useRouteMatch();
  const isIC = AuthenticationService.hasRole('INCIDENT_COMMANDER');

  const [baseDate, setBaseDate] = React.useState(new Date());

  const { setAppBarTitle } = useUIContext();
  React.useEffect(() => {
    setAppBarTitle(t('MY_DUTY') + ': ' + baseDate.getFullYear());
  }, [baseDate]);

  const getQueryFn = async ({ queryKey }) =>
    await get('function/calendar', { start: baseDate.getFullYear() + '-01-01', end: baseDate.getFullYear() + '-12-31' });
  const { data, isLoading } = useQuery(['myduty', baseDate], getQueryFn, {
    onSuccess: (data) => {
      // console.log(data);
    },
  });

  // React.useEffect(() => {
  //   console.log(baseDate);
  // }, [baseDate]);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <YearSelector baseDate={baseDate} onYearChange={(newDate) => setBaseDate(newDate)} />
      </Grid>
      <Grid item xs={12}>
        <TableContainer component={Paper}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell sx={{ minWidth: 5, width: 5, p: 0 }}></TableCell>
                <TableCell>{t('FROM')}</TableCell>
                <TableCell>{t('TO')}</TableCell>
                <TableCell>{t('STATUS')}</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((availability) => {
                return (
                  <TableRow key={availability.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                    <TableCell sx={{ minWidth: 5, width: 5, p: 0, backgroundColor: availability.color }}></TableCell>
                    <TableCell>{`${t(DateUtiles.getDayName(new Date(availability.start)))} ${DateUtiles.toShortDateTime(availability.start)}`}</TableCell>
                    <TableCell>{`${t(DateUtiles.getDayName(new Date(availability.end)))} ${DateUtiles.toShortDateTime(availability.end)}`}</TableCell>
                    <TableCell component="th" scope="row">
                      {t(availability.title)}
                    </TableCell>
                    {isIC ? (
                      <TableCell component="th" scope="row">
                        <IconButton color="primary" onClick={(event) => history.push(`${url}/assignation/${availability.id}`)}>
                          <ModeEditOutlineIcon />
                        </IconButton>
                      </TableCell>
                    ) : null}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );
}
