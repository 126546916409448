import React, { useState, useEffect } from 'react';
import { get } from 'src/api/api';
import { useQuery, useQueryClient } from 'react-query';
import Loader from 'src/shared/defaultLoader';
import { useTranslation } from 'react-i18next';
import useStore from './planning.store';
import MemberLine from './memberLine';
import GroupLine from './groupLine';

export default function WeeklyPlanning({ functionDetails, year, weekNumber, pushIsDisabled }) {
  // console.log(functionDetails.function_type);

  const [planningData, setPlanningData] = useState();

  const plannings = useStore((state) => state.plannings);
  const updatePlanning = useStore((state) => state.updatePlanning);

  const getQueryFn = async () => await get(`function/${functionDetails.function_id}/planning/${year}/${weekNumber}`);
  const { data, isLoading, refetch } = useQuery([`function/${functionDetails.function_id}/planning/${year}/${weekNumber}`], getQueryFn, {
    refetchOnWindowFocus: false,
    onSuccess: (data) => {
      if (plannings) {
        if (!plannings[`${functionDetails.function_name}_${year}_${weekNumber}`]) {
          setPlanningData(data);
          updatePlanning(`${functionDetails.function_name}_${year}_${weekNumber}`, data);
        } else {
          setPlanningData(plannings[`${functionDetails.function_name}_${year}_${weekNumber}`]);
        }
      } else {
        setPlanningData(data);
        updatePlanning(`${functionDetails.function_name}_${year}_${weekNumber}`, data);
      }
    },
  });

  useEffect(() => {
    if (!plannings) {
      refetch();
    }
  }, [plannings, refetch]);

  if (isLoading) {
    return <Loader />;
  }
  switch (functionDetails.function_type) {
    case 'GROUP':
      return <GroupLine functionDetails={functionDetails} planningData={planningData} pushIsDisabled={pushIsDisabled} />;

    case 'GROUP_LEADER':
      return <GroupLine functionDetails={functionDetails} planningData={planningData} pushIsDisabled={pushIsDisabled} />;
    default:
      return <MemberLine functionDetails={functionDetails} planningData={planningData} pushIsDisabled={pushIsDisabled} />;
  }
}
