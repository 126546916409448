import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useTranslation } from 'react-i18next';
import { DateUtiles } from 'src/services/dateUtiles';
import { get } from 'src/api/api';
import { useQuery } from 'react-query';
import Loader from 'src/shared/defaultLoader';
import { Colors } from 'src/styles/variables';
import IconButton from '@mui/material/IconButton';
import ModeEditOutlineIcon from '@mui/icons-material/ModeEditOutline';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { AuthenticationService } from 'src/services/auth';

export default function AvailabilityList({ baseDate, type, memberId = null }) {
  const { t } = useTranslation();
  const history = useHistory();
  let { url } = useRouteMatch();

  const isIC = AuthenticationService.hasRole('INCIDENT_COMMANDER');

  const monday = DateUtiles.getMonday(baseDate);
  const sunday = DateUtiles.getSunday(baseDate);
  //availability/calendar/${id}

  let apiUrl = '';
  let cacheKey = '';
  switch (type) {
    case 'AVAILABILITY':
      apiUrl = 'availability/calendar';
      cacheKey = 'currentUserWeeklyAvailability/';
      break;
    case 'FUNCTION':
      apiUrl = 'function/calendar';
      cacheKey = 'currentUserWeeklyFunction/';
      break;
    default:
      break;
  }
  if (memberId) {
    apiUrl += '/' + memberId;
    cacheKey += memberId;
  }

  const getQueryFn = async ({ queryKey }) => await get(apiUrl, { start: monday, end: sunday });
  const { data, isLoading } = useQuery([cacheKey + monday], getQueryFn, {
    onSuccess: (data) => {
      // console.log(data);
    },
  });

  if (isLoading) {
    return <Loader />;
  }

  return (
    <TableContainer component={Paper}>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell sx={{ minWidth: 5, width: 5, p: 0, p: '4px' }}></TableCell>
            <TableCell sx={{ p: '4px' }}>{t('FROM')}</TableCell>
            <TableCell sx={{ p: '4px' }}>{t('TO')}</TableCell>
            <TableCell sx={{ p: '4px' }}>{t('STATUS')}</TableCell>
            {type === 'FUNCTION' ? <TableCell sx={{ p: '4px' }}></TableCell> : null}
          </TableRow>
        </TableHead>
        <TableBody>
          {data?.map((availability) => {
            let bgColor = '';

            switch (type) {
              case 'AVAILABILITY':
                bgColor = Colors[availability.title].dark;
                break;
              case 'FUNCTION':
                bgColor = availability.color;
                break;
              default:
                break;
            }
            return (
              <TableRow key={availability.id} sx={{ '&:last-child td, &:last-child th': { border: 0 }, p: '4px' }}>
                <TableCell sx={{ minWidth: 5, width: 5, p: 0, backgroundColor: bgColor }}></TableCell>
                <TableCell sx={{ p: '4px' }}>
                  {availability.title === 'PRIVATE'
                    ? ''
                    : `${t(DateUtiles.getDayName(new Date(availability.start)))} ${DateUtiles.toShortDateTime(availability.start)}`}
                </TableCell>
                <TableCell sx={{ p: '4px' }}>
                  {availability.title === 'PRIVATE'
                    ? ''
                    : `${t(DateUtiles.getDayName(new Date(availability.end)))} ${DateUtiles.toShortDateTime(availability.end)}`}
                </TableCell>
                <TableCell sx={{ p: '4px' }} component="th" scope="row">
                  {t(availability.title)}
                </TableCell>
                {type === 'FUNCTION' && isIC ? (
                  <TableCell sx={{ p: '4px' }} component="th" scope="row">
                    <IconButton color="primary" onClick={(event) => history.push(`${url}/assignation/${availability.id}`)}>
                      <ModeEditOutlineIcon />
                    </IconButton>
                  </TableCell>
                ) : null}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
