import React from 'react';
import GenericList from 'src/shared/genericList';
import { useTranslation } from 'react-i18next';
import { DateUtiles } from 'src/services/dateUtiles';
import { EVENT_TYPE } from 'src/constants/constants';

export default function InterventionsList() {
  const { t } = useTranslation();

  function getStartDate(creation_date) {
    return `${DateUtiles.toStandardDate(creation_date)} - ${DateUtiles.toShortTime(creation_date)}`;
  }
  function getEndDate(end_date) {
    if (!end_date) {
      return '';
    }
    return `${DateUtiles.toStandardDate(end_date)} - ${DateUtiles.toShortTime(end_date)}`;
  }

  function getType(incident_type) {
    if (incident_type !== '') {
      const event = EVENT_TYPE.find((o) => {
        return o.code === incident_type;
      });
      if (event) {
        return t(event.name);
      }
    }
    return '';
  }

  function getIC(value, row) {
    return `${row.rank_abbreviation || ''} ${row.lastname || ''} ${row.firstname || ''}`;
  }
  const columns = [
    { field: 'incident_id', headerName: 'ID', hide: true },

    {
      field: 'creation_date',
      headerName: t('START_DATE'),
      width: 175,
      flex: 0,
      valueGetter: getStartDate,
    },
    {
      field: 'end_date',
      headerName: t('END_DATE'),
      width: 175,
      flex: 0,
      valueGetter: getEndDate,
    },
    {
      field: 'incident_type',
      headerName: t('INCIDENT_TYPE'),
      width: 200,
      flex: 0,
      valueGetter: getType,
    },
    {
      field: 'lastname',
      headerName: t('INCIDENT_COMMANDER'),
      width: 200,
      flex: 0,
      valueGetter: getIC,
    },
    {
      field: 'incident_address',
      headerName: t('INCIDENT_ADDRESS'),
      width: 75,
      flex: 75,
    },
    {
      field: 'incident_description',
      headerName: t('ALARM_MESSAGE'),
      width: 75,
      flex: 100,
    },
  ];

  return <GenericList objectName={'incident'} columns={columns} rowId={'incident_id'} />;
}
