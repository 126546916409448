import React from 'react';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import useStore from './planning.store';
import GroupSelector from 'src/shared/groupSelector';
import PlanningPushModal from './planningPushModal';
import { useTranslation } from 'react-i18next';
import useStoreGlobalFeedback from 'src/modules/shared/globalFeedback/globalFeedback.store.js';
import { post } from 'src/api/api';

export default function GroupLine({ functionDetails, planningData, pushIsDisabled }) {
  const setPlanningToChanged = useStore((state) => state.setPlanningToChanged);
  const setFeedback = useStoreGlobalFeedback((state) => state.setFeedback);
  const { t } = useTranslation();
  const pushPlanning = async (type, function_planning_id) => {
    try {
      await post('function/planning/push/line', { function_planning_id: function_planning_id });
      setFeedback({ msg: t('PLANNING_PUSHED_SUCESS'), severity: 'success' });
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <Typography variant="h6" component="div">
        {functionDetails.function_name}
      </Typography>
      <Grid container spacing={3}>
        {}
        {planningData?.map((planning, index) => {
          if (planning.group_id) {
            return (
              <React.Fragment key={index}>
                <Grid item xs={9}>
                  <GroupSelector
                    defaultGroupId={planning.group_id}
                    onChange={(v) => {
                      setPlanningToChanged();
                      planningData[index].group_id = v?.group_id ?? null;
                    }}
                  />
                </Grid>
                <Grid item xs={3}>
                  <PlanningPushModal
                    onConfirm={async (type) => {
                      await pushPlanning(type, planning.function_planning_id);
                    }}
                    isDisabled={pushIsDisabled}
                    type="LINE"
                  />
                </Grid>
              </React.Fragment>
            );
          } else {
            return (
              <React.Fragment key={index}>
                <Grid item xs={9}>
                  <GroupSelector
                    onChange={(v) => {
                      setPlanningToChanged();
                      planningData[index].group_id = v?.group_id ?? null;
                    }}
                  />
                </Grid>
                <Grid item xs={3}>
                  <PlanningPushModal
                    onConfirm={async (type) => {
                      await pushPlanning(type, planning.function_planning_id);
                    }}
                    isDisabled={pushIsDisabled}
                    type="LINE"
                  />
                </Grid>
              </React.Fragment>
            );
          }
        })}
      </Grid>
    </>
  );
}
