import * as React from 'react';
// import { styled } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import LanguageIcon from '@mui/icons-material/Language';
// import InputBase from '@mui/material/InputBase';
// import Badge from '@mui/material/Badge';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
// import AccountCircle from '@mui/icons-material/AccountCircle';
// import MailIcon from '@mui/icons-material/Mail';
// import NotificationsIcon from '@mui/icons-material/Notifications';
import MoreIcon from '@mui/icons-material/MoreVert';
import { useUIContext } from '../../contexts/ui/ui.context';
import i18n from 'src/i18n/i18n';
import { AuthenticationService } from 'src/services/auth';
import { useHistory, useLocation } from 'react-router-dom';
import LogoutIcon from '@mui/icons-material/Logout';
import { useTranslation } from 'react-i18next';
import Badge from '@mui/material/Badge';
import { useQueryClient } from 'react-query';
import { DateUtiles } from 'src/services/dateUtiles';
import HelpIcon from '@mui/icons-material/Help';
import InfoIcon from '@mui/icons-material/Info';
// const StyledInputBase = styled(InputBase)(({ theme }) => ({
//   color: 'inherit',
//   '& .MuiInputBase-input': {
//     padding: theme.spacing(1, 1, 1, 0),
//     // vertical padding + font size from searchIcon
//     paddingLeft: `calc(1em + ${theme.spacing(4)})`,
//     transition: theme.transitions.create('width'),
//     width: '100%',
//     [theme.breakpoints.up('md')]: {
//       width: '20ch',
//     },
//   },
// }));
import SettingsIcon from '@mui/icons-material/Settings';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';

export default function Topbar() {
  const history = useHistory();

  const { pathname } = useLocation();

  const goBack = () => {
    history.goBack();
  };

  const queryClient = useQueryClient();
  const helpParam = queryClient.getQueryData(['params/info']);
  const lastHelpUpdate = DateUtiles.dateDiffInDays(new Date(helpParam?.helpData?.admin_last_update ?? new Date()), new Date());
  const lastViewedVersion = helpParam?.infoViewData?.changelog ?? '1.0';
  const localConfig = queryClient.getQueryData(['local/config']);

  // console.log((localConfig?.version ?? '1.0') > lastViewedVersion);

  const { toggleMenu } = useUIContext();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [isOnMainScreen, setIsOnMainScreen] = React.useState(false);
  const [anchorLanguageMenuEl, setAnchorLanguageEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
  const isLanguageMenuOpen = Boolean(anchorLanguageMenuEl);
  const baseUrls = new Set(['/admin']);

  React.useEffect(() => {
    setIsOnMainScreen(baseUrls.has(pathname));
  }, [pathname]);

  const { t } = useTranslation();
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleLanguageMenuClose = () => {
    setAnchorLanguageEl(null);
    handleMobileMenuClose();
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const handleLanguageMenuOpen = (event) => {
    setAnchorLanguageEl(event.currentTarget);
  };

  const handleParam = () => {
    history.push('/admin/parameters');
  };

  const handleLogout = () => {
    AuthenticationService.logout();
    history.push('/');
  };

  const handleHelp = () => {
    window.open(helpParam.helpData.help_admin_url, '_blank');
  };
  const handleChangeLog = () => {
    history.push('/admin/changelog');
  };

  const menuId = 'primary-search-account-menu';
  const languageMenuId = 'primary-search-account-menu';
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={handleMenuClose}>Profile</MenuItem>
      <MenuItem onClick={handleMenuClose}>My account</MenuItem>
    </Menu>
  );

  const mobileMenuId = 'primary-search-account-menu-mobile';
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem onClick={handleLanguageMenuOpen}>
        <IconButton size="large" aria-label="account of current user" aria-controls="primary-search-account-menu" aria-haspopup="true" color="inherit">
          <LanguageIcon />
        </IconButton>
        <p>{t('LANGUAGE')}</p>
      </MenuItem>
      {/* <MenuItem>
        <IconButton size="large" aria-label="show 4 new mails" color="inherit">
          <Badge badgeContent={4} color="error">
            <MailIcon />
          </Badge>
        </IconButton>
        <p>Messages</p>
      </MenuItem>
      <MenuItem>
        <IconButton size="large" aria-label="show 17 new notifications" color="inherit">
          <Badge badgeContent={17} color="error">
            <NotificationsIcon />
          </Badge>
        </IconButton>
        <p>Notifications</p>
      </MenuItem> */}
      {/* <MenuItem onClick={handleProfileMenuOpen}>
        <IconButton size="large" aria-label="account of current user" aria-controls="primary-search-account-menu" aria-haspopup="true" color="inherit">
          <AccountCircle />
        </IconButton>
        <p>Profile</p>
      </MenuItem> */}
      <MenuItem onClick={handleChangeLog}>
        <IconButton size="large" color="inherit">
          <Badge variant="dot" color="badge" badgeContent={(localConfig?.version ?? '1.0') > lastViewedVersion ? 1 : 0}>
            <InfoIcon />
          </Badge>
        </IconButton>
        <p>{t('CHANGELOG')}</p>
      </MenuItem>

      <MenuItem onClick={handleParam}>
        <IconButton size="large" color="inherit">
          <SettingsIcon />
        </IconButton>
        <p>{t('PARAMETERS')}</p>
      </MenuItem>

      <MenuItem onClick={handleLogout}>
        <IconButton size="large" color="inherit">
          <LogoutIcon />
        </IconButton>
        <p>{t('LOGOUT')}</p>
      </MenuItem>
    </Menu>
  );

  const renderLanguageMenu = (
    <Menu
      anchorEl={anchorLanguageMenuEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      id={languageMenuId}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={isLanguageMenuOpen}
      onClose={handleLanguageMenuClose}
    >
      <MenuItem
        onClick={() => {
          changeLanguage('fr');
          handleLanguageMenuClose();
        }}
      >
        Français
      </MenuItem>
      <MenuItem
        onClick={() => {
          changeLanguage('de');
          handleLanguageMenuClose();
        }}
      >
        Deutsch
      </MenuItem>
      <MenuItem
        onClick={() => {
          changeLanguage('en');
          handleLanguageMenuClose();
        }}
      >
        English
      </MenuItem>
    </Menu>
  );

  return (
    <>
      <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
        <Toolbar>
          {isOnMainScreen ? null : (
            <IconButton color="inherit" aria-label="open drawer" edge="start" onClick={() => goBack()}>
              <ArrowBackIosNewIcon />
            </IconButton>
          )}
          <IconButton color="inherit" aria-label="open drawer" edge="start" onClick={() => toggleMenu()} sx={{ mr: 2, display: { sm: 'none' } }}>
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap component="div" sx={{ display: { sm: 'block' } }}>
            Alarmo
          </Typography>

          <Box sx={{ flexGrow: 1 }} />
          <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
            <IconButton size="large" color="inherit" onClick={handleHelp}>
              <Badge variant="dot" color="badge" badgeContent={lastHelpUpdate < 10 ? 1 : 0}>
                <HelpIcon />
              </Badge>
            </IconButton>
            <IconButton size="large" color="inherit" onClick={handleChangeLog}>
              <Badge variant="dot" color="badge" badgeContent={(localConfig?.version ?? '1.0') > lastViewedVersion ? 1 : 0}>
                <InfoIcon />
              </Badge>
            </IconButton>
            <IconButton size="large" aria-label="change language" color="inherit" onClick={handleLanguageMenuOpen}>
              <LanguageIcon />
            </IconButton>
            {/* <IconButton size="large" aria-label="show 4 new mails" color="inherit">
              <Badge badgeContent={4} color="error">
                <MailIcon />
              </Badge>
            </IconButton>
            <IconButton size="large" aria-label="show 17 new notifications" color="inherit">
              <Badge badgeContent={17} color="error">
                <NotificationsIcon />
              </Badge>
            </IconButton> */}
            {/* <IconButton
              size="large"
              edge="end"
              aria-label="account of current user"
              aria-controls={menuId}
              aria-haspopup="true"
              onClick={handleProfileMenuOpen}
              color="inherit"
            >
              <AccountCircle />
            </IconButton> */}
            <IconButton size="large" color="inherit" onClick={handleParam}>
              <SettingsIcon />
            </IconButton>
            <IconButton size="large" color="inherit" onClick={handleLogout}>
              <LogoutIcon />
            </IconButton>
          </Box>
          <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
            <IconButton size="large" color="inherit" onClick={handleHelp}>
              <Badge variant="dot" color="badge" badgeContent={lastHelpUpdate < 10 ? 1 : 0}>
                <HelpIcon />
              </Badge>
            </IconButton>
            <IconButton size="large" aria-label="show more" aria-controls={mobileMenuId} aria-haspopup="true" onClick={handleMobileMenuOpen} color="inherit">
              <MoreIcon />
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar>
      {renderMobileMenu}
      {renderMenu}
      {renderLanguageMenu}
    </>
  );
}
