import React from 'react';
import TextField from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import Grid from '@mui/material/Grid';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import DoNotDisturbOnIcon from '@mui/icons-material/DoNotDisturbOn';
import Stack from '@mui/material/Stack';
import Button from '../../components/button/button';
import Typography from '@mui/material/Typography';
import { useQuery } from 'react-query';
import { post, getAllData, getData } from 'src/api/api';
import Loader from 'src/shared/defaultLoader';
import { Colors } from 'src/styles/variables';
import { useTranslation } from 'react-i18next';
import frLocale from 'date-fns/locale/fr-CH';
import { useParams, useHistory } from 'react-router-dom';
import { useUIContext } from '../../contexts/ui/ui.context';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import { DateUtiles } from 'src/services/dateUtiles';
import MemberSelector from 'src/shared/memberSelector';
import { AuthenticationService } from 'src/services/auth';

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),

  color: theme.palette.text.secondary,
  marginTop: theme.spacing(2),
}));

export default function Assignation() {
  const { t } = useTranslation();
  const history = useHistory();

  let { id, assignation_id } = useParams();
  if (!id) {
    const currentUser = AuthenticationService.getCurrentUser();
    id = currentUser.member_id;
  }

  const inputRef = React.useRef();

  const [fromDate, setFromDate] = React.useState(new Date());
  const [toDate, setToDate] = React.useState(new Date());
  const [substituteMemberId, setSubstituteMemberId] = React.useState(0);

  const getAssignationFn = async ({ queryKey }) => await getData('assignment', { queryKey });
  const { data: assignationData, isLoading: assignationIsLoading } = useQuery(['assignment', { id: assignation_id }], getAssignationFn, {
    onSuccess: (data) => {
      setToDate(new Date(data.end_date));
      setFromDate(new Date(data.start_date));
    },
  });

  const { setAppBarTitle } = useUIContext();
  React.useEffect(() => {
    let rank = '';
    if (assignationData?.rank_abbreviation) {
      rank = t('RANK_ABBR.' + assignationData.rank_abbreviation);
    }
    setAppBarTitle(`${rank} ${assignationData?.firstname ?? ''} ${assignationData?.lastname ?? ''}`);
  }, [assignationData]);

  const createRemplacement = async (status) => {
    if (substituteMemberId > 0 || substituteMemberId === null) {
      const data = {
        from: fromDate,
        to: toDate,
        member_id: substituteMemberId,
        assignation_id: assignation_id,
      };
      await post('assignment/substitute', data);
      history.goBack();
    } else {
      inputRef?.current?.focus();
    }
  };

  if (assignationIsLoading) {
    return <Loader />;
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Item>
          <Typography variant="h6">{t('NEW_REPLACEMENT')}</Typography>
          <Typography variant="subtitle2" display="inline">
            {t('FUNCTION')}:&nbsp;
          </Typography>
          <Typography variant="body2" display="inline">
            {assignationData.function_name}
          </Typography>
          <br />
          <Typography variant="subtitle2" display="inline">
            {t('BASE_PERIODE')}:&nbsp;
          </Typography>
          <Typography variant="body2" display="inline">
            {`${t(DateUtiles.getDayName(new Date(assignationData.start_date)))} ${DateUtiles.toShortDateTime(assignationData.start_date)} - ${t(
              DateUtiles.getDayName(new Date(assignationData.end_date))
            )} ${DateUtiles.toShortDateTime(assignationData.end_date)}`}
          </Typography>
        </Item>
      </Grid>

      <Grid item xs={12}>
        <Stack spacing={3}>
          <MemberSelector
            role_id={assignationData.role_id}
            specialization_id={assignationData.specialization_id}
            label={t('SUBSTITUTE')}
            onChange={(v) => {
              setSubstituteMemberId(v?.member_id ?? null);
            }}
            inputRef={inputRef}
          />
        </Stack>
      </Grid>

      <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={frLocale}>
        <Grid item xs={12}>
          <Stack spacing={3}>
            <DateTimePicker
              label={t('FROM')}
              ampm={false}
              value={fromDate}
              onChange={(newValue) => {
                setFromDate(newValue);
              }}
              renderInput={(params) => <TextField variant="standard" {...params} />}
            />
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <Stack spacing={3}>
            <DateTimePicker
              label={t('TO')}
              ampm={false}
              value={toDate}
              onChange={(newValue) => {
                setToDate(newValue);
              }}
              renderInput={(params) => <TextField variant="standard" {...params} />}
            />
          </Stack>
        </Grid>
      </LocalizationProvider>

      <Grid item xs={12}>
        <Button
          bgColor={assignationData.rank_color_code}
          onClick={() => {
            // console.log('test');
            createRemplacement('AVAILABLE');
          }}
        >
          {assignationData.function_name}
        </Button>
      </Grid>
    </Grid>
  );
}
