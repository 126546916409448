import React from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { DataGrid } from '@mui/x-data-grid';
import { getData } from 'src/api/api';
import api from 'src/api/axios';
import { useTranslation } from 'react-i18next';
import Loader from 'src/shared/defaultLoader';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import AddIcon from '@mui/icons-material/Add';
import QuickSelector from '../../components/quickSelector/quickSelector';
import Paper from '@mui/material/Paper';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import IconButton from '@mui/material/IconButton';

export default function DisplayShiftDetailsGroup() {
  let { id } = useParams();

  const { t } = useTranslation();

  const getQueryFn = async ({ queryKey }) => await getData('display_shift', { queryKey });
  const { data, error, isLoading, isError, refetch } = useQuery(['display_shift_details', { id: id, withDetails: true }], getQueryFn);

  const onGroupAdd = async (group) => {
    // console.log(group);
    await api.post(`/display_shift/group/${id}`, group);
    refetch();
  };

  const onOrderUpdate = async (data) => {
    // console.log(data);
    await api.put(`/display_shift/details/${id}`, data);
  };

  function RowMenuCell(props) {
    const handleDeleteClick = async (event) => {
      event.stopPropagation();
      const row = props.row;
      // console.log(row);

      await api.delete(`/display_shift/details/${row.display_shift_detail_id}`);
      refetch();
    };

    return (
      // <div className={classes.root}>
      <div>
        <IconButton color="inherit" size="small" aria-label="delete" onClick={handleDeleteClick}>
          <DeleteIcon fontSize="small" />
        </IconButton>
      </div>
    );
  }

  const columns = [
    { field: 'display_shift_detail_id', headerName: 'ID', hide: true },
    {
      field: 'group_name',
      headerName: t('GROUP'),
      width: 200,
    },
    {
      field: 'shift_order',
      headerName: t('ROTATION_ORDER'),
      width: 75,
      flex: 1,
      editable: true,
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      width: 100,
      renderCell: RowMenuCell,
    },
  ];

  if (isLoading) {
    return <Loader />;
  }
  if (isError) {
    return <span>Error : {error.message}</span>;
  }
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <Paper sx={{ pl: 1, pr: 3, pb: 1, pt: 1, mb: 1 }}>
        <Box>
          <Stack spacing={1} direction="row">
            <QuickSelector
              objectName={'group'}
              columnName={'group_name'}
              columnId={'group_id'}
              title={t('GROUP')}
              buttonTitle={t('ADD_GROUP')}
              startIcon={<AddIcon />}
              onItemSelected={(group) => onGroupAdd(group)}
            />
          </Stack>
        </Box>
      </Paper>
      <DataGrid
        getRowId={(row) => row[`display_shift_detail_id`]}
        autoHeight
        rows={data}
        columns={columns}
        pageSize={100}
        rowsPerPageOptions={[100]}
        // onCellClick={(cell) => history.push(`user/${cell.id}`)}
        onCellEditCommit={(e) => onOrderUpdate(e)}
      />
    </Box>
  );
}
