import React from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useQuery } from 'react-query';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import { useTranslation } from 'react-i18next';
import FormControl from '@mui/material/FormControl';
import Loader from 'src/shared/defaultLoader';
import { useMutation, useQueryClient } from 'react-query';
import { updateData, createData, deleteData, getData, getAllData } from 'src/api/api';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import AlertDialog from 'src/shared/sharedModal';
import ReactHookFormSelect from 'src/shared/reactHookFormSelect';
import MenuItem from '@mui/material/MenuItem';
import { INCIDENT_STATUS } from 'src/constants/constants';

export default function Alarm() {
  const { register, handleSubmit, control, getValues, setValue } = useForm();
  const { t } = useTranslation();
  let { id } = useParams();
  const history = useHistory();

  const getQueryFn = async ({ queryKey }) => await getData('alarm', { queryKey });

  const { data, error, isLoading, isError } = useQuery(['alarm', { id }], getQueryFn);
  const { data: members, isLoading: membersIsLoading } = useQuery('users', async () => await getAllData('user'));

  // const getRanksQueryFn = async () => await getAllData('rank');

  const [open, setOpen] = React.useState(false);

  const createQueryFn = async (data) => await createData('alarm', data);
  const updateQueryFn = async ({ id, ...data }) => await updateData('alarm', { id, ...data });
  const deleteQueryFn = async ({ id, ...data }) => await deleteData('alarm', { id, ...data });

  const queryClient = useQueryClient();
  const { mutateAsync: mutateAsyncUpdate } = useMutation(updateQueryFn, {
    onSuccess: (data) => {
      queryClient.setQueryData(['alarm', { id: id }], data);
    },
  });
  const { mutateAsync: mutateAsyncCreate } = useMutation(createQueryFn, {
    onSuccess: (data) => {
      queryClient.setQueryData(['alarm', { id: id }], data);
      queryClient.setQueryData(['alarm', { id: '0' }], {});
    },
  });
  const { mutateAsync: mutateAsyncDelete } = useMutation(deleteQueryFn, {
    onSuccess: (data) => {
      queryClient.setQueryData(['alarm', { id: id }], {});
    },
  });

  async function deleteCurrentRecord() {
    await mutateAsyncDelete({ ...data, id });
    history.goBack();
  }

  const onSubmit = handleSubmit(async (data) => {
    // console.log(data);
    if (id !== '0') {
      await mutateAsyncUpdate({ ...data, id });
    } else {
      await mutateAsyncCreate(data);
    }
    history.goBack();
  });

  if (isLoading || membersIsLoading) {
    return <Loader />;
  }
  if (isError) {
    return <span>Error : {error.message}</span>;
  }

  return (
    <Paper sx={{ p: 3 }}>
      <Typography variant="h6" gutterBottom>
        {t('DETAILS')}
      </Typography>
      <form className="detailsForm" onSubmit={onSubmit}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <ReactHookFormSelect
              // register={register}
              id="alarm_status"
              name="alarm_status"
              label={t('INCIDENT_STATUS')}
              control={control}
              defaultValue={data.alarm_status}
              variant="standard"
            >
              {INCIDENT_STATUS.map((status) => {
                return (
                  <MenuItem key={status.code} value={status.code}>
                    {t(status.name)}
                  </MenuItem>
                );
              })}
            </ReactHookFormSelect>
          </Grid>

          <Grid item xs={12} sm={6}>
            <ReactHookFormSelect
              // register={register}
              id="incident_commander_id"
              name="incident_commander_id"
              label={t('INCIDENT_COMMANDER')}
              control={control}
              defaultValue={data.incident_commander_id}
              variant="standard"
            >
              {members.map((member) => {
                return (
                  <MenuItem key={member.id} value={parseInt(member.id)}>
                    {`${t('RANK_ABBR.' + member.rank_abbreviation)} ${member.lastname} ${member.firstname}`}
                  </MenuItem>
                );
              })}
            </ReactHookFormSelect>
          </Grid>

          <Grid item xs={12}>
            <Box sx={{ display: 'flex', flexDirection: 'row-reverse' }}>
              <Stack direction="row" spacing={1}>
                <Button type="submit" variant="contained">
                  {t('SAVE')}
                </Button>
                {id !== '0' ? (
                  <Button
                    variant="contained"
                    color="error"
                    onClick={() => {
                      setOpen(true);
                      // deleteCurrentRecord;
                    }}
                  >
                    {t('DELETE')}
                  </Button>
                ) : null}
                <AlertDialog
                  open={open}
                  setOpen={setOpen}
                  Message={t('Êtes-vous sûr de vouloir supprimer cet enregistrement?')}
                  onConfirm={() => deleteCurrentRecord()}
                  AcceptBtn={t('YES')}
                  CancelBtn={t('CANCEL')}
                />
              </Stack>
            </Box>
          </Grid>
        </Grid>
      </form>
    </Paper>
  );
}
