import React from 'react';
import GenericList from 'src/shared/genericList';
import { useTranslation } from 'react-i18next';

export default function DisplayBoardList() {
  const { t } = useTranslation();
  const columns = [
    { field: 'display_board_id', headerName: 'ID', hide: true },
    {
      field: 'board_name',
      headerName: t('BOARD_NAME'),
      width: 75,
      editable: true,
      flex: 1,
    },
    {
      field: 'board_order',
      headerName: t('SORT_ORDER'),
      width: 75,
      editable: true,
      flex: 1,
    },
  ];

  return <GenericList objectName={'display_board'} columns={columns} />;
}
