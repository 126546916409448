import React from 'react';
import Box from '@mui/material/Box';
import { styled } from '@mui/system';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import { Colors } from 'src/styles/variables';
import { useTranslation } from 'react-i18next';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';

const TableauBloc = styled(TableauAvailabilitySummary)(({ theme }) => {
  return {
    ...theme.typography.body2,
    color: theme.palette.text.secondary,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  };
});
export default TableauBloc;

const BlocName = styled('h1')({
  color: Colors.section,
  textAlign: 'center',
});

function TableauAvailabilitySummary({ title, datas }) {
  const { t } = useTranslation();

  const nbDetails = { available: 0, notAvailable: 0, notAvailablePlus3: 0 };
  for (const key in datas) {
    if (Object.hasOwnProperty.call(datas, key)) {
      const element = datas[key];
      for (const member of element.members) {
        if (member.status_code === 'AVAILABLE') {
          nbDetails.available++;
        } else {
          if (member.status_code === 'NOT_AVAILABLE' || !member.status_code) {
            const diff = Math.floor((new Date(member.end_date) - new Date(member.start_date)) / 86400000);
            if (diff <= 3) {
              nbDetails.notAvailable++;
            } else {
              nbDetails.notAvailablePlus3++;
            }
          }
        }
      }
    }
  }

  return (
    <Box>
      {title ? <BlocName>{title}</BlocName> : null}
      <TableContainer component={Paper}>
        <Table size="small" aria-label="a dense table">
          <TableBody>
            <TableRow>
              <TableCell sx={{ p: 1, fontSize: '2em', color: Colors.AVAILABLE.darker }}>
                {t('BOARD_AVAILABILITY', { available: nbDetails.available, total: nbDetails.available + nbDetails.notAvailable + nbDetails.notAvailablePlus3 })}
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell sx={{ p: 1, fontSize: '2em', color: Colors.NOT_AVAILABLE.darker }}>
                {t('BOARD_NOT_AVAILABLE', { notAvailable: nbDetails.notAvailable })}
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell sx={{ p: 1, fontSize: '2em', color: Colors.NOT_AVAILABLE_LONG.contrastText, bgColor: Colors.NOT_AVAILABLE_LONG.main }}>
                {t('BOARD_NOT_AVAILABLE_PLUS_3', { notAvailable: nbDetails.notAvailablePlus3 })}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}
