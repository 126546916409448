import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import CardActions from '@mui/material/CardActions';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import CardHeader from '@mui/material/CardHeader';
import Switch from '@mui/material/Switch';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import { styled } from '@mui/system';
import { Controller, useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { updateData } from 'src/api/api';
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import Tooltip from '@mui/material/Tooltip';

const CustomCardActions = styled(CardActions)({
  flexDirection: 'row-reverse',
});

export default function AccessForm({ type, access, id }) {
  const [isDisabled, setIsDisabled] = useState(access.is_disabled);
  const [feedBack, setFeedBack] = useState('');
  const [error, setError] = useState('');

  const { t } = useTranslation();
  const { register, handleSubmit, watch, control } = useForm({
    defaultValues: { is_enable: !access.is_disabled },
  });

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      setIsDisabled(!value.is_enable);
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  const updateQueryFn = async ({ id, ...data }) => await updateData('auth/user/access', { id, ...data });
  const { mutateAsync: mutateAsyncUpdate } = useMutation(updateQueryFn, {
    onSuccess: (data) => {
      // console.log(data);
      if (data.status === 'ok') {
        const translatedType = t('USER_TYPE_' + type);
        setFeedBack(t('ACCESS_SAVED', { access_type: translatedType }));
      } else {
        setError(data.msg);
      }
    },
  });

  const handleClose = () => {
    setFeedBack('');
  };

  const onSubmit = handleSubmit(async (data) => {
    setError('');
    data.type = type;
    data.member_id = id;
    data.is_disabled = !data.is_enable;
    await mutateAsyncUpdate({ ...data, id });
  });

  return (
    <Grid item xs={12}>
      <Card sx={{ minWidth: 275 }}>
        <form className="detailsForm" onSubmit={onSubmit}>
          <CardHeader title={t('USER_TYPE_' + type)} action={<Switch {...register('is_enable')} defaultChecked={!access.is_disabled} name="is_enable" />} />
          <CardContent>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <FormControl variant="standard" fullWidth={true}>
                  <Controller
                    name={'user_name'}
                    defaultValue={access.user_name}
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <TextField required onChange={onChange} value={value} label={t('USER_NAME')} disabled={isDisabled} fullWidth variant="standard" />
                    )}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl variant="standard" fullWidth={true}>
                  <Controller
                    name={'email'}
                    defaultValue={access.email}
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <TextField required onChange={onChange} value={value} label={t('EMAIL')} disabled={isDisabled} fullWidth variant="standard" />
                    )}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={12}>
                <FormControl variant="standard" fullWidth={true}>
                  <Controller
                    name={'password'}
                    defaultValue=""
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <Tooltip title={t('PASSWORD_LEAVE_EMPTY_TO_KEEP')}>
                        <TextField type="password" onChange={onChange} value={value} label={t('PASSWORD')} disabled={isDisabled} fullWidth variant="standard" />
                      </Tooltip>
                    )}
                  />
                </FormControl>
              </Grid>
              {error !== '' ? (
                <Grid item xs={12}>
                  <Alert severity="error">{t(error)}</Alert>
                </Grid>
              ) : null}
            </Grid>
          </CardContent>
          <CustomCardActions>
            <Button type="submit">{t('SAVE')}</Button>
          </CustomCardActions>
        </form>
      </Card>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={feedBack !== ''}
        // autoHideDuration={6000}
        onClose={handleClose}
        message={feedBack}
      />
    </Grid>
  );
}
