import create from 'zustand';

const useStore = create((set) => {
  const updatePlanning = (planningName, details) =>
    set((state) => {
      let newPlanning = { ...state.plannings };
      newPlanning[planningName] = details;
      return { plannings: newPlanning };
    });

  const emptyPlanning = () =>
    set((state) => {
      return { plannings: null };
    });

  const setPlanningToChanged = () =>
    set((state) => {
      return { planningChanged: true };
    });

  const setPlanningToSaved = () =>
    set((state) => {
      return { planningChanged: false };
    });

  return {
    plannings: {},
    planningChanged: false,
    setPlanningToChanged: setPlanningToChanged,
    setPlanningToSaved: setPlanningToSaved,
    updatePlanning: updatePlanning,
    emptyPlanning: emptyPlanning,
  };
});

export default useStore;
