import React from 'react';
import DownloadingIcon from '@mui/icons-material/Downloading';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import downloadImagesAsZip from 'src/services/imageDownloader';
import { B2_BASE_URL } from 'src/constants/constants';
import { useQueryClient } from 'react-query';
import Loader from 'src/shared/defaultLoader';
import { get } from 'src/api/api';
import { useQuery } from 'react-query';

export default function IncidentsPhotoDownloadButton({ incidentId }) {
  const [wip, setWip] = React.useState(false);

  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const history = useHistory();
  const globalParam = queryClient.getQueryData(['params/global']);

  const getQueryFn = async () => await get('incident-logs/' + incidentId);
  const { data, error, isLoading, isError } = useQuery(['incident-logs/' + incidentId], getQueryFn);

  if (isLoading) {
    return <Loader />;
  }

  const handleDownload = async () => {
    setWip(true);
    const imageUrls = [];
    data.forEach((element) => {
      if (element.entry_type == 'PHOTO') {
        let fileData = JSON.parse(element.comment);
        let imgUrl = `${globalParam.files_server || B2_BASE_URL}${fileData.fileName}`;
        imageUrls.push(imgUrl);
      }
    });
    await downloadImagesAsZip(imageUrls);
    setWip(false);
  };

  return (
    <>
      {wip === true ? (
        <Loader />
      ) : (
        <Button
          fullWidth
          variant="contained"
          startIcon={<DownloadingIcon />}
          size="large"
          color="neutral"
          onClick={() => {
            handleDownload();
          }}
        >
          {t('DOWNLOAD_IMAGES')}
        </Button>
      )}
    </>
  );
}
