import React from 'react';
import GenericCRUD from 'src/shared/genericCRUD';
import { useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useQuery } from 'react-query';
import { getData, getAllData } from 'src/api/api';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import { useTranslation } from 'react-i18next';
import FormControl from '@mui/material/FormControl';
import Loader from 'src/shared/defaultLoader';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import PopulatedSelect from 'src/shared/populatedSelect';
import FormLabel from '@mui/material/FormLabel';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Controller } from 'react-hook-form';
import Checkbox from '@mui/material/Checkbox';

export default function MapDetail() {
  const { register, handleSubmit, control, getValues, setValue } = useForm();

  const { t } = useTranslation();
  let { id } = useParams();
  const getQueryFn = async ({ queryKey }) => await getData('maps', { queryKey });
  const { data, error, isLoading, isError } = useQuery(['maps', { id }], getQueryFn);

  const getLayersQueryFn = async () => await getAllData('map-layers');
  const { data: layers, isLoading: layerIsLoading } = useQuery('map-layers', getLayersQueryFn);

  const handleLayerCheck = (checkedId) => {
    const { layers: ids } = getValues();
    const newIds = ids?.includes(checkedId) ? ids?.filter((id) => id !== checkedId) : [...(ids ?? []), checkedId];
    setValue('layers', newIds);
    return newIds;
  };

  if (isLoading || layerIsLoading) {
    return <Loader />;
  }
  if (isError) {
    return <span>Error : {error.message}</span>;
  }

  return (
    <GenericCRUD objectName={'maps'} handleSubmit={handleSubmit} data={data} stayOnPage rowId={'map_id'}>
      <Grid item xs={12} sm={6}>
        <FormControl variant="standard" fullWidth={true}>
          <TextField
            {...register('map_name')}
            required
            autoComplete="off"
            id="map_name"
            name="map_name"
            label={t('NAME')}
            fullWidth
            variant="standard"
            defaultValue={data.map_name}
          />
        </FormControl>
      </Grid>

      <Grid item xs={12} sm={6}>
        <FormControl variant="standard" fullWidth={true}>
          <TextField
            {...register('map_order')}
            id="map_order"
            name="map_order"
            label={t('ORDER')}
            fullWidth
            autoComplete="family-name"
            variant="standard"
            defaultValue={data.map_order}
          />
        </FormControl>
      </Grid>

      <Grid item xs={12} md={12}>
        <PopulatedSelect
          control={control}
          value={data.role_id}
          endoint={'role'}
          name={'role_id'}
          id={'role_id'}
          label={t('ROLE')}
          withBlankOption
          displayAttribute={'role_name'}
        />
      </Grid>

      <Grid item xs={12}>
        <Card>
          <CardHeader title={t('PARAMETER')} />
          <CardContent>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <FormControl variant="standard" fullWidth={true}>
                  <TextField
                    {...register('map_param.center_lat')}
                    required
                    autoComplete="off"
                    id="map_param.center_lat"
                    name="map_param.center_lat"
                    label={t('LATITUDE')}
                    fullWidth
                    variant="standard"
                    defaultValue={data.map_param?.center_lat ?? ''}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12} md={6}>
                <FormControl variant="standard" fullWidth={true}>
                  <TextField
                    {...register('map_param.center_long')}
                    required
                    autoComplete="off"
                    id="map_param.center_long"
                    name="map_param.center_long"
                    label={t('LONGITUDE')}
                    fullWidth
                    variant="standard"
                    defaultValue={data.map_param?.center_long ?? ''}
                  />
                </FormControl>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>

      <Grid item xs={12}>
        <Card>
          <CardHeader title={t('MAP_LAYERS')} />
          <CardContent>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <FormControl component="fieldset" variant="standard">
                  <FormLabel component="legend">{t('MAP_LAYERS')}</FormLabel>
                  <FormGroup>
                    <Controller
                      name="layers"
                      defaultValue={data.layers}
                      render={(props) =>
                        layers?.map((item, index) => (
                          <FormControlLabel
                            control={
                              <Checkbox
                                onChange={() => {
                                  handleLayerCheck(item.map_layer_id);
                                }}
                                defaultChecked={data.layers?.includes(item.map_layer_id)}
                              />
                            }
                            key={item.map_layer_id}
                            label={item.map_layer_name}
                          />
                        ))
                      }
                      control={control}
                    />
                  </FormGroup>
                </FormControl>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </GenericCRUD>
  );
}
