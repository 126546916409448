import React from 'react';
import GenericCRUD from 'src/shared/genericCRUD';
import { useParams, useHistory } from 'react-router-dom';

import { useForm } from 'react-hook-form';
import { useQuery } from 'react-query';
import { getData } from 'src/api/api';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import { useTranslation } from 'react-i18next';
import FormControl from '@mui/material/FormControl';
import Loader from 'src/shared/defaultLoader';
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import ListIcon from '@mui/icons-material/List';

export default function DisplayBoard() {
  const history = useHistory();
  let { id } = useParams();

  const { register, handleSubmit } = useForm();
  const { t } = useTranslation();
  const getQueryFn = async ({ queryKey }) => await getData('display_board', { queryKey });

  const { data, error, isLoading, isError } = useQuery(['display_board', { id }], getQueryFn);

  if (isLoading) {
    return <Loader />;
  }
  if (isError) {
    return <span>Error : {error.message}</span>;
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <Paper sx={{ pl: 3, pr: 3, pb: 1, pt: 1, mb: 1 }}>
        <Stack spacing={1} direction="row">
          <Button size="small" type="submit" variant="contained" startIcon={<ListIcon />} disabled={id === '0'} onClick={() => history.push(`${id}/blocs`)}>
            {t('DETAILS')}
          </Button>
        </Stack>
      </Paper>
      <GenericCRUD objectName={'display_board'} handleSubmit={handleSubmit} data={data}>
        <Grid item xs={12} sm={8}>
          <FormControl variant="standard" fullWidth={true}>
            <TextField
              {...register('board_name')}
              required
              autoComplete="off"
              id="board_name"
              name="board_name"
              label={t('NAME')}
              fullWidth
              variant="standard"
              defaultValue={data.board_name}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={4}>
          <FormControl variant="standard" fullWidth={true}>
            <TextField
              {...register('board_order')}
              required
              id="board_order"
              name="board_order"
              label={t('SORT_ORDER')}
              fullWidth
              autoComplete="family-name"
              variant="standard"
              defaultValue={data.board_order}
            />
          </FormControl>
        </Grid>
      </GenericCRUD>
    </Box>
  );
}
