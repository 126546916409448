import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { AuthenticationService } from 'src/services/auth';
// import * as jwt from 'jsonwebtoken';
// import { publicKey } from 'src/publickey';
import jwt_decode from 'jwt-decode';

function ProtectedRoute({ component: Component, module, ...restOfProps }) {
  const currentUser = AuthenticationService.currentUserValue;
  // console.log(currentUser);
  const token = currentUser?.access_token;
  let userType = '';
  if (token) {
    try {
      const decoded = jwt_decode(token);
      userType = decoded?.type;
    } catch (error) {
      localStorage.removeItem('currentUser');
    }
  }

  return <Route {...restOfProps} render={(props) => (currentUser && module === userType ? <Component {...props} /> : <Redirect to="/" />)} />;
}

export default ProtectedRoute;
