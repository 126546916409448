import React from 'react';
import GenericCRUD from 'src/shared/genericCRUD';
import { useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useQuery } from 'react-query';
import { getData, getAllData } from 'src/api/api';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import { useTranslation } from 'react-i18next';
import FormControl from '@mui/material/FormControl';
import Loader from 'src/shared/defaultLoader';
import MenuItem from '@mui/material/MenuItem';
import ReactHookFormSelect from 'src/shared/reactHookFormSelect';
import { GROUP_TYPE } from 'src/constants/constants';
import MemberSelector from 'src/shared/memberSelector';

export default function Group() {
  const { register, handleSubmit, control, setValue } = useForm();
  const { t } = useTranslation();
  let { id } = useParams();
  const getQueryFn = async ({ queryKey }) => await getData('group', { queryKey });
  const { data, error, isLoading, isError } = useQuery(['group', { id }], getQueryFn, {
    onSuccess: (data) => {
      setValue('group_leader_id', data?.group_leader_id ?? null);
    },
  });

  const inputRef = React.useRef();

  const getGroupTypeQueryFn = async () => await getAllData('group-type');
  const { data: groupTypes, isLoading: groupTypeIsLoading } = useQuery('group-type', getGroupTypeQueryFn);

  if (isLoading || groupTypeIsLoading) {
    return <Loader />;
  }
  if (isError) {
    return <span>Error : {error.message}</span>;
  }

  return (
    <GenericCRUD objectName={'group'} handleSubmit={handleSubmit} data={data}>
      <Grid item xs={12} sm={4}>
        <FormControl variant="standard" fullWidth={true}>
          <TextField
            {...register('group_name')}
            required
            autoComplete="off"
            id="group_name"
            name="group_name"
            label={t('GROUP_NAME')}
            fullWidth
            variant="standard"
            defaultValue={data.group_name}
          />
        </FormControl>
      </Grid>

      <Grid item xs={12} sm={4}>
        <FormControl variant="standard" fullWidth={true}>
          <TextField
            {...register('group_code')}
            required
            autoComplete="off"
            id="group_code"
            name="group_code"
            label={t('GROUP_CODE')}
            fullWidth
            variant="standard"
            defaultValue={data.group_code}
          />
        </FormControl>
      </Grid>

      <Grid item xs={12} sm={4}>
        <FormControl variant="standard" fullWidth={true}>
          <TextField
            {...register('group_order')}
            required
            id="group_order"
            name="group_order"
            label={t('GROUP_ORDER')}
            fullWidth
            autoComplete="family-name"
            variant="standard"
            defaultValue={data.group_order}
          />
        </FormControl>
      </Grid>

      <Grid item xs={12} sm={4}>
        <FormControl variant="standard" fullWidth={true}>
          <TextField
            {...register('source_mapping')}
            id="source_mapping"
            name="source_mapping"
            label={t('SOURCE_MAPPING')}
            fullWidth
            autoComplete="family-name"
            variant="standard"
            defaultValue={data.source_mapping}
          />
        </FormControl>
      </Grid>

      <Grid item xs={12} sm={4}>
        <ReactHookFormSelect
          register={register}
          id="group_type_id"
          name="group_type_id"
          label={t('GROUP_TYPE_NAME')}
          control={control}
          defaultValue={data.group_type_id}
          variant="standard"
        >
          <MenuItem key={0} value={null}>
            &nbsp;
          </MenuItem>
          {groupTypes.map((groupType) => {
            return (
              <MenuItem key={groupType.group_type_id} value={groupType.group_type_id}>
                {groupType.group_type_name}
              </MenuItem>
            );
          })}
        </ReactHookFormSelect>
      </Grid>

      <Grid item xs={12} sm={4}>
        <ReactHookFormSelect
          required
          register={register}
          id="is_alarm_group"
          name="is_alarm_group"
          label={t('GROUP_TYPE')}
          control={control}
          defaultValue={data.is_alarm_group}
          variant="standard"
        >
          {GROUP_TYPE.map((type) => {
            return (
              <MenuItem key={type.code} value={type.code}>
                {t(type.name)}
              </MenuItem>
            );
          })}
        </ReactHookFormSelect>
      </Grid>
      <Grid item xs={12}>
        <MemberSelector
          defaultMemberId={data.group_leader_id}
          label={t('SQUAD_LEADER')}
          onChange={(v) => {
            setValue('group_leader_id', v?.member_id ?? null);
          }}
          inputRef={inputRef}
        />
      </Grid>
    </GenericCRUD>
  );
}
