import React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import IconButton from '@mui/material/IconButton';
import { useHistory } from 'react-router-dom';
import { get } from 'src/api/api';
import Loader from 'src/shared/defaultLoader';
import { useQuery } from 'react-query';
import List from '@mui/material/List';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { useTranslation } from 'react-i18next';
import IncidentVehicleLine from './incidentVehicleLine';

export default function IncidentVehicle({ incident }) {
  const { t } = useTranslation();
  const [allOpen, setAllOpen] = React.useState(false);

  const history = useHistory();

  const getQueryFn = async ({ queryKey }) => await get(`incident/${incident.incident_id}/Vehicle`);
  const { data, error, isLoading, isError } = useQuery(['incidentVehicle', { id: incident.incident_id }], getQueryFn);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <Card key={incident.incident_id} sx={{ mb: 0 }}>
      <CardHeader
        sx={{ pb: 1 }}
        action={
          <IconButton aria-label="settings" onClick={() => setAllOpen(!allOpen)}>
            {allOpen ? <ExpandLess /> : <ExpandMore />}
          </IconButton>
        }
        title={`${t('VEHICLES')} (${data.length})`}
      />
      <CardContent sx={{ p: 0, mb: 0 }}>
        <List sx={{ width: '100%', bgcolor: 'background.paper' }} component="nav">
          {data?.map((vehicle) => {
            return <IncidentVehicleLine key={vehicle[0].vehicle_id} allOpen={allOpen} vehicle={vehicle} />;
          })}
        </List>
      </CardContent>
    </Card>
  );
}
