import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Loader from 'src/shared/defaultLoader';
import { get } from 'src/api/api';
import { useQuery } from 'react-query';
import Button from '../../components/button/button';
import Colors from 'src/styles/variables';
import ContactPhoneIcon from '@mui/icons-material/ContactPhone';
import { useQueryClient } from 'react-query';
import { createData } from 'src/api/api';

export default function DispatchButton({ incidentId }) {
  const queryClient = useQueryClient();
  const globalParam = queryClient.getQueryData(['params/global']);
  const { t } = useTranslation();
  const [currentStatus, setCurrentStatus] = useState();

  const getQueryFn = async ({ queryKey }) => await get(`incident-logs/dispatch-ack/${incidentId}`);
  const { data, isLoading } = useQuery([`incident-logs/dispatch-ack/${incidentId}`], getQueryFn, {
    refetchInterval: (data) => {
      if (data == '') {
        return 3000;
      } else {
        return false;
      }
    },
  });

  if (isLoading) {
    return <Loader />;
  }
  return (
    <Button
      icon={ContactPhoneIcon}
      iconColor={data == '' ? Colors.dispatch_ack_button_ICON : Colors.dispatch_ack_button}
      textColor={data == '' ? Colors.dispatch_ack_button_ICON : Colors.dispatch_ack_button}
      bgColor={data == '' ? Colors.dispatch_ack_button : Colors.dispatch_ack_button_ICON}
      onClick={async () => {
        await createData('incident-logs', {
          entry_type: 'AUTO',
          subject: '',
          comment: 'ACK_DISPATCH',
          incident_id: incidentId,
          event_date: Date(),
        });
        window.location = 'tel:' + globalParam.dispatch_settings_phone;
      }}
    >
      {t('DISPATCH_ACK_BTN', { dispatch_name: globalParam.dispatch_settings_name }) + (data == '' ? '' : ` (${data.lastname})`)}
    </Button>
  );
}
