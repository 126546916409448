import React, { useEffect } from 'react';
import { useQuery } from 'react-query';
import { get } from 'src/api/api';

import Grid from '@mui/material/Grid';

import CurrentUser from '../../components/currentUser/currentUser';
import Loader from 'src/shared/defaultLoader';
import { useUserContext } from '../../contexts/user/user.context';
import Typography from '@mui/material/Typography';
import { useUIContext } from '../../contexts/ui/ui.context';
import AlarmButton from '../../components/buttons/alarmButton/alarmButton';
import AvailableButton from '../../components/buttons/availableButton/availableButton';
import NotAvailableButton from '../../components/buttons/notAvailableButton/notAvailableButton';
import { useTranslation } from 'react-i18next';
import Incident from '../../components/incident/incident';
import { AuthenticationService } from 'src/services/auth';
import WorkforceSummary from 'src/shared/workforceSummary';

export default function Home() {
  const { t } = useTranslation();
  const isIC = AuthenticationService.hasRole('INCIDENT_COMMANDER');

  const { currentUser, setUser } = useUserContext();
  const getQueryFn = async ({ queryKey }) => await get('user/currentUser');
  const { isLoading } = useQuery(['currentUser'], getQueryFn, {
    onSuccess: (data) => {
      setUser(data);
    },
  });

  const [currentStatus, setCurrentStatus] = React.useState();
  const getQueryUserStatusFn = async ({ queryKey }) => await get('user/currentUserStatus');
  const { isLoading: isLoadingStatus } = useQuery(['currentUserStatus'], getQueryUserStatusFn, {
    onSuccess: (data) => {
      setCurrentStatus(data);
    },
  });

  const { setAppBarTitle } = useUIContext();
  useEffect(() => {
    setAppBarTitle('Alarmo');
  }, []);

  if (isLoading || isLoadingStatus) {
    return <Loader />;
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <CurrentUser />
      </Grid>
      <Grid item xs={12}>
        <WorkforceSummary />
      </Grid>

      <Incident />
      {currentUser.user_id != -1 ? (
        !currentStatus?.isInAlarm ? (
          <React.Fragment>
            <Grid item xs={12}>
              <AlarmButton />
            </Grid>

            <Grid item xs={6}>
              <AvailableButton />
            </Grid>

            <Grid item xs={6}>
              <NotAvailableButton />
            </Grid>
          </React.Fragment>
        ) : null
      ) : null}
    </Grid>
  );
}
