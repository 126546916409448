import React from 'react';
import { TreeView } from '@mui/x-tree-view';
import { get } from 'src/api/api';
import Loader from 'src/shared/defaultLoader';
import { useQuery } from 'react-query';
import CustomTreeItem from './customTreeItem';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

function flatten(originalArray) {
  const newArray = [];
  originalArray.forEach((item) => {
    newArray.push(item);
    if (item.children.length > 0) {
      const childArray = flatten(item.children);
      newArray.push(...childArray);
    }
  });
  return newArray;
}

export default function InterventionLogPresetParentSelector({ defaultValue, control, name, id }) {
  const { t } = useTranslation();

  const [fieldValue, setFieldValue] = React.useState(defaultValue?.toString() ?? '');

  const getQueryFn = async () => await get('intervention-log-preset/tree');
  const { data, error, isLoading, isError } = useQuery(['intervention-log-preset/tree'], getQueryFn, {
    onSuccess: (data) => {
      // console.log(data);
    },
  });

  const handleSelect = (event, nodeIds) => {
    setFieldValue(nodeIds);
  };

  if (isLoading) {
    return <Loader />;
  }
  if (isError) {
    return <span>Error : {error.message}</span>;
  }
  const flattenedData = flatten(data);
  const ids = flattenedData.map((e) => e.incident_log_preset_item_id.toString());
  ids.push('0');

  return (
    <Controller
      name={name}
      id={id}
      control={control}
      defaultValue={fieldValue}
      render={({ field }) => {
        return (
          <TreeView
            aria-label="customized"
            defaultExpanded={ids}
            selected={fieldValue}
            onNodeSelect={(event, nodeIds) => {
              field.onChange(nodeIds);
              handleSelect(event, nodeIds);
            }}
          >
            <CustomTreeItem
              e={{ incident_log_preset_item_id: '0', name: t('ROOT'), children: data, item_type: 'FOLDER' }}
              actionDisable={true}
            ></CustomTreeItem>
          </TreeView>
        );
      }}
    />
  );
}
