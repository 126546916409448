import React from 'react';
import { useQuery } from 'react-query';
import './userList.scss';
import { useHistory } from 'react-router-dom';
import { DataGrid } from '@mui/x-data-grid';
import { getAllUsers } from 'src/api/api';
import { useTranslation } from 'react-i18next';
import Loader from 'src/shared/defaultLoader';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import MemberImportUpload from '../../components/memberImportUpload/memberImportUpload';
import AddIcon from '@mui/icons-material/Add';
import AlarmGroupUpload from '../../components/alarmGroupUpload/alarmGroupUpload';
import { useQueryClient } from 'react-query';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import NipUpload from '../../components/nipUpload/nipUpload';

export default function UserList() {
  const { t } = useTranslation();
  const history = useHistory();
  const { data, error, isLoading, isError, refetch } = useQuery('users', getAllUsers, {
    onSuccess: (data) => {
      setFilteredData(data);
    },
  });
  const queryClient = useQueryClient();

  const [filteredData, setFilteredData] = React.useState(data);
  const [quickSearchValue, setQuickSearchValue] = React.useState('');
  const [activeUserOnly, setActiveUserOnly] = React.useState(true);
  const handleActiveUserOnlyChange = (event) => {
    setActiveUserOnly(event.target.checked);
  };

  React.useEffect(() => {
    let newData = data;
    if (newData) {
      if (quickSearchValue !== '') {
        newData = newData.filter(
          (e) => e.firstname.toUpperCase().includes(quickSearchValue.toUpperCase()) || e.lastname.toUpperCase().includes(quickSearchValue.toUpperCase())
        );
      }
      if (activeUserOnly) {
        newData = newData.filter((e) => !e?.is_disabled ?? false);
      }
      setFilteredData(newData);
    }
  }, [quickSearchValue, activeUserOnly, data]);

  const columns = [
    { field: 'id', headerName: 'ID' },
    {
      field: 'rank_abbreviation',
      headerName: t('RANK'),
      width: 75,
      valueGetter: function (value) {
        return t('RANK_ABBR.' + value);
      },
    },
    {
      field: 'firstname',
      headerName: t('FIRST_NAME'),
      width: 150,
      flex: 1,
    },
    {
      field: 'lastname',
      headerName: t('LAST_NAME'),
      width: 150,
      flex: 1,
    },
  ];

  if (isLoading) {
    return <Loader />;
  }
  if (isError) {
    return <span>Error : {error.message}</span>;
  }
  return (
    <>
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <Paper sx={{ p: 1 }}>
          <Grid sx={{ mb: '25px' }} container spacing={3}>
            <Grid item xs={12} md={8}>
              <FormControl variant="standard" fullWidth={true}>
                <TextField
                  autoComplete="off"
                  id="default_time_days"
                  name="default_time_days"
                  label={t('QUICK_SEARCH')}
                  fullWidth
                  variant="standard"
                  value={quickSearchValue}
                  onChange={(event) => {
                    setQuickSearchValue(event.target.value);
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} md={4}>
              <FormControl component="fieldset" variant="standard">
                <FormLabel component="legend">{t('ONLY_ACTIVE_USER')}</FormLabel>
                <FormGroup>
                  <Switch checked={activeUserOnly} onChange={handleActiveUserOnlyChange} />
                </FormGroup>
              </FormControl>
            </Grid>
          </Grid>
        </Paper>
        <Box sx={{ mb: 1, mt: 1 }}>
          <Stack spacing={1} direction="row">
            <Button size="small" type="submit" variant="contained" startIcon={<AddIcon />} onClick={() => history.push(`user/0`)}>
              {t('NEW')}
            </Button>

            <MemberImportUpload
              onImportDone={() => {
                queryClient.removeQueries('user');
              }}
            />
            <AlarmGroupUpload
              onImportDone={() => {
                queryClient.removeQueries('user');
              }}
            />
            <NipUpload
              onImportDone={() => {
                queryClient.removeQueries('user');
              }}
            />
          </Stack>
        </Box>

        <DataGrid
          autoHeight
          rows={filteredData}
          columns={columns}
          initialState={{
            columns: {
              columnVisibilityModel: {
                id: false,
              },
            },
          }}
          pageSize={100}
          rowsPerPageOptions={[100]}
          onCellClick={(cell) => history.push(`user/${cell.id}`)}
          getRowClassName={(params) => {
            if (params.row.is_disabled) {
              return 'disabled-line';
            }
          }}
        />
      </Box>
    </>
  );
}
