import React from 'react';
import Grid from '@mui/material/Grid';
import { useQuery } from 'react-query';
import { getAllData } from 'src/api/api';
import { AuthenticationService } from 'src/services/auth';
import Loader from 'src/shared/defaultLoader';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import MaterialButton from './materialButton';
import MaterialLinkButton from './materialLinkButton';

export default function VehiclesMaterial({ vehicle }) {
  const { t } = useTranslation();
  const isIC = AuthenticationService.hasRole('INCIDENT_COMMANDER');

  const groupQuery = () => getAllData('material/withState');
  const { data, isLoading: materialsIsLoading } = useQuery('material/withState', groupQuery);

  if (materialsIsLoading) {
    return <Loader />;
  }

  const total_engaged = data.reduce((previousValue, material) => {
    if (material.vehicle_id === vehicle.vehicle_id) {
      previousValue++;
    }
    return previousValue;
  }, 0);

  return (
    <React.Fragment>
      {total_engaged ? (
        <>
          <Box
            sx={{
              color: 'text.secondary',
              alignContent: 'center',
              justifyContent: 'center',
              display: 'flex',
              textAlign: 'center',
              flexDirection: 'column',
              paddingBottom: '0px',
              paddingTop: '0px',
            }}
          >
            <Box>
              <Typography variant="subtitle2" gutterBottom>
                {t('MATERIALS_LINKED')}
              </Typography>
            </Box>
          </Box>

          <Grid container spacing={2}>
            {data.map((material) => {
              if (material.vehicle_id === vehicle.vehicle_id) {
                return (
                  <Grid key={material.material_id} item xs={12}>
                    <MaterialLinkButton material={material} />
                  </Grid>
                );
              }
            })}
          </Grid>
        </>
      ) : null}

      <Box
        sx={{
          color: 'text.secondary',
          alignContent: 'center',
          justifyContent: 'center',
          display: 'flex',
          textAlign: 'center',
          flexDirection: 'column',
          paddingBottom: '0px',
          paddingTop: '15px',
        }}
      >
        <Box>
          <Typography variant="subtitle2" gutterBottom>
            {t('MATERIALS_AVAILABLE')}
          </Typography>
        </Box>
      </Box>
      <Grid container spacing={2}>
        {data.map((material) => {
          if (!material.vehicle_id) {
            return (
              <React.Fragment key={material.material_id}>
                <Grid item xs={12}>
                  <MaterialLinkButton material={material} vehicle={vehicle} />
                </Grid>
              </React.Fragment>
            );
          }
        })}
      </Grid>
    </React.Fragment>
  );
}
