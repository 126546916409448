import React from 'react';

import { Grid } from '@mui/material';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import { Colors } from 'src/styles/variables';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/system';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import Checkbox from '@mui/material/Checkbox';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import useStore from './reinforcement.store';

const ReinforcementsPersonnesV = function () {
  const selectedGroups = useStore((state) => state.selectedGroups);
  const selectedPerons = useStore((state) => state.selectedPerons);
  const setSelectedPerons = useStore((state) => state.setSelectedPerons);
  const memberData = useStore((state) => state.membersData);

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <TableContainer component={Paper}>
          <Table size={'medium'}>
            <TableBody>
              {memberData.map((member) => {
                if (!member.alarm_id) {
                  let hasSelectedGroup = false;
                  for (const groupId of member.groups_ids) {
                    if (selectedGroups.has(groupId)) {
                      if (selectedGroups.get(groupId)) {
                        hasSelectedGroup = true;
                      }
                    }
                  }

                  return (
                    <Line
                      key={member.member_id}
                      member={member}
                      value={selectedPerons.has(member.member_id) ? selectedPerons.get(member.member_id) : hasSelectedGroup}
                      onLineStatusChange={(isSelected) => {
                        selectedPerons.set(member.member_id, isSelected);
                        setSelectedPerons(selectedPerons);
                      }}
                    />
                  );
                } else {
                  return null;
                }
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );
};

const LineV = function ({ member, onLineStatusChange, value }) {
  const { t } = useTranslation();
  const [selected, setSelected] = React.useState(value);

  let rankBgColor = '';
  let lineBgColor = '';
  let textColor = 'black';
  let NameColor = 'black';
  let iconOpacity = 1;

  if (member.status_code === 'AVAILABLE') {
    NameColor = Colors.AVAILABLE.darker;
    iconOpacity = 1;
  }
  if (member.status_code === 'NOT_AVAILABLE' || !member.status_code) {
    iconOpacity = 0.2;
    const diff = Math.floor((new Date(member.end_date) - new Date(member.start_date)) / 86400000);
    if (diff <= 3) {
      NameColor = Colors.NOT_AVAILABLE.darker;
    } else {
      NameColor = Colors.NOT_AVAILABLE_LONG.contrastText;
      lineBgColor = Colors.NOT_AVAILABLE_LONG.main;
    }
  }
  if (member.alarm_id) {
    NameColor = Colors.ALARM.contrastText;
    lineBgColor = Colors.ALARM.darker;
  }

  const NamesBox = styled(TableCell)({
    fontWeight: 'bold',
    color: NameColor,
    fontSize: '0.8em',
    paddingLeft: 0.1,
    paddingRight: 0.1,
  });
  const SpecIcon = styled(Box)({
    flexGrow: 0,
    // width: '50px',
    paddingLeft: 1,
    paddingRight: 1,
  });
  const SpecIconImg = styled('img')({
    height: '25px',
  });

  return (
    <TableRow
      key={member.member_id}
      hover
      selected={selected}
      onClick={(event) => {
        const newStatus = !selected;
        setSelected(newStatus);
        onLineStatusChange(newStatus);
      }}
    >
      <TableCell padding="checkbox">
        <Checkbox color="primary" checked={selected} />
      </TableCell>
      <TableCell sx={{ p: 0.4, color: NameColor, bgcolor: rankBgColor }}> {t('RANK_ABBR.' + member.rank_abbreviation)}</TableCell>
      <NamesBox component="th" scope="row">
        {member.lastname}
      </NamesBox>
      <NamesBox component="th" scope="row">
        {member.firstname}
      </NamesBox>
      <TableCell sx={{ p: 0.1 }} align="right">
        <Box
          sx={{
            display: 'inline-flex',
            alignItems: 'center',
          }}
        >
          <SpecIcon>
            {member.specializations.some((item) => item === 'PREPOSER_ARI') ? (
              <SpecIconImg sx={{ opacity: iconOpacity }} src={'/icons/cylindre_ARI_color.png'} alt="item" />
            ) : member.specializations.some((item) => item === 'ARI') ? (
              <SpecIconImg sx={{ opacity: iconOpacity }} src={'/icons/cylindre_ARI.png'} alt="item" />
            ) : (
              <SpecIconImg sx={{ opacity: 0 }} src={'/icons/cylindre_ARI.png'} alt="item" />
            )}
          </SpecIcon>
          <SpecIcon>
            {member.specializations.some((item) => item === 'EA') ? (
              <SpecIconImg sx={{ opacity: iconOpacity }} src={'/icons/EPS25.png'} alt="item" />
            ) : (
              <SpecIconImg sx={{ opacity: 0 }} src={'/icons/EPS25.png'} alt="item" />
            )}
          </SpecIcon>
          <SpecIcon>
            {member.specializations.some((item) => item === 'ANTICHUTE') ? (
              <SpecIconImg sx={{ opacity: iconOpacity }} src={'/icons/antichute.png'} alt="item" />
            ) : (
              <SpecIconImg sx={{ opacity: 0 }} src={'/icons/antichute.png'} alt="item" />
            )}
          </SpecIcon>
          <SpecIcon>
            {member.specializations.some((item) => item?.startsWith('CHAUFFEUR') ?? false) ? (
              <SpecIconImg sx={{ opacity: iconOpacity }} src={'/icons/volant.png'} alt="item" />
            ) : (
              <SpecIconImg sx={{ opacity: 0 }} src={'/icons/volant.png'} alt="item" />
            )}
          </SpecIcon>
        </Box>
      </TableCell>
    </TableRow>
  );
};
const Line = React.memo(LineV);

const ReinforcementsPersonnes = React.memo(ReinforcementsPersonnesV);
export default ReinforcementsPersonnes;
