import React from 'react';
import Button from '@mui/material/Button';
import { post } from 'src/api/api';
import { useQuery, useQueryClient } from 'react-query';
import Grid from '@mui/material/Grid';
import { useTranslation } from 'react-i18next';

export default function MaterialLinkButton({ material, vehicle }) {
  const { t } = useTranslation();

  const queryClient = useQueryClient();

  const clickHandler = async () => {
    console.log(material, vehicle);
    let newStatus = '';
    if (material.vehicle_id) {
      newStatus = 'UNLINK';
    } else {
      newStatus = 'LINK';
    }
    await post(`material/${material.material_id}/currentIntervention/${vehicle.vehicle_id}`, { newStatus });
    queryClient.invalidateQueries('material/withState');
  };

  let color = 'vehicle_available';
  switch (material.material_status) {
    case 'ENGAGED':
      color = 'vehicle_engaged';
      break;
    case 'ON_SITE':
      color = 'vehicle_on_site';
      break;
    case 'ON_WAY':
      color = 'vehicle_on_way';
      break;
    case 'OUT_OF_ORDER':
      color = 'vehicle_out_of_order';
      break;
    default:
      color = 'neutral';
      break;
  }

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Button fullWidth sx={{ height: '100%', p: 0.5 }} variant="contained" size="large" color={color} onClick={() => clickHandler()}>
          {material.material_code} / {material.material_name}
        </Button>
      </Grid>
    </Grid>
  );
}
