import React from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Loader from 'src/shared/defaultLoader';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import AddIcon from '@mui/icons-material/Add';
import { SimpleTreeView } from '@mui/x-tree-view/SimpleTreeView';
import { get } from 'src/api/api';
import CustomTreeItem from './customTreeItem';

function flatten(originalArray) {
  const newArray = [];
  originalArray.forEach((item) => {
    newArray.push(item);
    if (item.children.length > 0) {
      const childArray = flatten(item.children);
      newArray.push(...childArray);
    }
  });
  return newArray;
}

export default function InterventionLogPresetsList() {
  const objectName = 'intervention-log-preset';
  const rowId = 'incident_log_preset_item_id';

  let { id } = useParams();

  const handleSelect = (event, nodeIds) => {
    if (nodeIds) {
      history.push(`/admin/intervention-log-preset/${nodeIds}`);
    }
  };

  const { t } = useTranslation();
  const history = useHistory();
  const getQueryFn = async () => await get('intervention-log-preset/tree');
  const { data, error, isLoading, isError } = useQuery(['intervention-log-preset/tree'], getQueryFn, {
    onSuccess: (data) => {
      // console.log(data);
    },
  });

  if (isLoading) {
    return <Loader />;
  }
  if (isError) {
    return <span>Error : {error.message}</span>;
  }

  const flattenedData = flatten(data);
  const ids = flattenedData.map((e) => e.incident_log_preset_item_id.toString());
  ids.push('');

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <Box sx={{ mb: 1 }}>
        <Button
          size="small"
          type="submit"
          variant="contained"
          startIcon={<AddIcon />}
          onClick={() => {
            let url = `/admin/${objectName}/0`;
            history.push(url);
          }}
        >
          {t('NEW')}
        </Button>
      </Box>

      <SimpleTreeView
        aria-label="customized"
        defaultExpandedItems={ids}
        //selected={fieldValue}

        onItemSelectionToggle={(event, itemId, isSelected) => {
          handleSelect(event, itemId);
        }}
      >
        <CustomTreeItem e={{ incident_log_preset_item_id: '', name: t('ROOT'), children: data }} actionDisable={false}></CustomTreeItem>
      </SimpleTreeView>
    </Box>
  );
}
