import React from 'react';
import GenericCRUD from 'src/shared/genericCRUD';
import { useParams } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { useQuery } from 'react-query';
import { getData, getAllData } from 'src/api/api';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import { useTranslation } from 'react-i18next';
import FormControl from '@mui/material/FormControl';
import Loader from 'src/shared/defaultLoader';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import { CIVILITIES } from 'src/constants/constants';
import ReactHookFormSelect from 'src/shared/reactHookFormSelect';
import MenuItem from '@mui/material/MenuItem';
import PopulatedSelect from 'src/shared/populatedSelect';

export default function DirectoryContactDetail() {
  const { register, handleSubmit, control, getValues, setValue } = useForm();
  const { t } = useTranslation();
  let { id } = useParams();

  const [functionStates, setFunctionStates] = React.useState({});
  const getQueryFn = async ({ queryKey }) => await getData('directory/contact', { queryKey });
  const { data, error, isLoading, isError } = useQuery(['directory/contact', { id }], getQueryFn, {
    onSuccess: (data) => {
      setValue('details', data.details);
    },
  });

  const getDirectoryQueryFn = async () => await getAllData('directory');
  const { data: directories, isLoading: directoriesIsLoading } = useQuery('directory', getDirectoryQueryFn);

  const getGroupQueryFn = async () => await getAllData('directory/group');
  const { data: groups, isLoading: groupsIsLoading } = useQuery('directory/group', getGroupQueryFn);

  const handleDirectoryCheck = (groupId, directoryId) => {
    const details = getValues('details');
    const groupDetails = details[groupId];
    const contacts = groupDetails?.contacts;

    const newContacts = contacts.some((e) => e.phone_directory_id === directoryId)
      ? contacts.filter((c) => c.phone_directory_id !== directoryId)
      : [...(contacts ?? []), { phone_directory_id: directoryId }];

    details[groupId].contacts = newContacts;
    setValue('details', details);
  };

  const getFunctionName = (groupId, directoryId) => {
    if (id === '0' || data.details === undefined) {
      return '';
    }
    const contactDetails = data?.details[groupId]?.contacts.find((e) => e.phone_directory_id === directoryId);
    if (contactDetails) {
      return contactDetails?.function_name ?? '';
    } else {
      return '';
    }
  };

  const handleFunctionChange = (groupId, directoryId, functionValue) => {
    const details = getValues('details');
    const contactDetails = details[groupId]?.contacts.find((e) => e.phone_directory_id === directoryId);
    contactDetails.function_name = functionValue;
    setValue('details', details);
  };

  if (isLoading || directoriesIsLoading || groupsIsLoading) {
    return <Loader />;
  }
  if (isError) {
    return <span>Error : {error.message}</span>;
  }

  return (
    <GenericCRUD objectName={'directory/contact'} handleSubmit={handleSubmit} data={data} stayOnPage rowId={'phone_directory_entry_id'}>
      <Grid item xs={12} sm={4}>
        <ReactHookFormSelect
          register={register}
          id="civilities"
          name="civilities"
          label={t('CIVILITIE')}
          control={control}
          defaultValue={data.civilities}
          variant="standard"
        >
          <MenuItem value="">&nbsp;</MenuItem>
          {CIVILITIES.map((type) => {
            return (
              <MenuItem key={type.code} value={type.code}>
                {t(type.name)}
              </MenuItem>
            );
          })}
        </ReactHookFormSelect>
      </Grid>
      <Grid item xs={12} sm={4}>
        <FormControl variant="standard" fullWidth={true}>
          <TextField
            {...register('phone_directory_entry_name')}
            required
            autoComplete="off"
            id="phone_directory_entry_name"
            name="phone_directory_entry_name"
            label={t('FIRST_NAME')}
            fullWidth
            variant="standard"
            defaultValue={data.phone_directory_entry_name}
          />
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={4}>
        <FormControl variant="standard" fullWidth={true}>
          <TextField
            {...register('last_name')}
            autoComplete="off"
            id="last_name"
            name="last_name"
            label={t('NAME')}
            fullWidth
            variant="standard"
            defaultValue={data.last_name}
          />
        </FormControl>
      </Grid>

      <Grid item xs={12} sm={4}>
        <FormControl variant="standard" fullWidth={true}>
          <TextField
            {...register('phone_directory_entry_number')}
            required
            id="phone_directory_entry_number"
            name="phone_directory_entry_number"
            label={t('PHONE')}
            fullWidth
            autoComplete="off"
            variant="standard"
            defaultValue={data.phone_directory_entry_number}
          />
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={4}>
        <FormControl variant="standard" fullWidth={true}>
          <TextField
            {...register('phone2')}
            id="phone2"
            name="phone2"
            label={t('PHONE2')}
            fullWidth
            autoComplete="off"
            variant="standard"
            defaultValue={data.phone2}
          />
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={4}>
        <FormControl variant="standard" fullWidth={true}>
          <TextField
            {...register('email')}
            id="email"
            name="email"
            label={t('EMAIL')}
            fullWidth
            autoComplete="off"
            variant="standard"
            defaultValue={data.email}
          />
        </FormControl>
      </Grid>

      <Grid item xs={12} md={12}>
        <PopulatedSelect
          control={control}
          value={data.role_id}
          endoint={'role'}
          name={'role_id'}
          id={'role_id'}
          label={t('ROLE')}
          withBlankOption
          displayAttribute={'role_name'}
        />
      </Grid>

      {id !== '0'
        ? groups.map((group) => {
            return (
              <Grid key={group.phone_directory_group_id} item xs={12}>
                <Card>
                  <CardHeader title={group.phone_directory_group_name} />
                  <CardContent>
                    <Grid container spacing={3}>
                      {directories?.map((item, index) => {
                        return (
                          <React.Fragment key={item.phone_directory_id}>
                            <Grid item xs={6} md={6}>
                              <FormControl component="fieldset" variant="standard">
                                <FormGroup>
                                  <Controller
                                    name="groups"
                                    defaultValue={data.groups}
                                    render={(props) => (
                                      <Grid container spacing={1}>
                                        <Grid item xs={6}>
                                          <FormControlLabel
                                            control={
                                              <Checkbox
                                                onChange={() => {
                                                  handleDirectoryCheck(group.phone_directory_group_id, item.phone_directory_id);
                                                }}
                                                defaultChecked={
                                                  data.details
                                                    ? data.details[group.phone_directory_group_id]?.contacts.some(
                                                        (e) => e.phone_directory_id === item.phone_directory_id
                                                      )
                                                    : false
                                                }
                                              />
                                            }
                                            label={item.phone_directory_name}
                                          />
                                        </Grid>
                                      </Grid>
                                    )}
                                    control={control}
                                  />
                                </FormGroup>
                              </FormControl>
                            </Grid>
                            <Grid item xs={6}>
                              <FormControl variant="standard" fullWidth={true}>
                                <TextField
                                  autoComplete="off"
                                  label={t('DIRECTORY_FUNCTION')}
                                  fullWidth
                                  variant="standard"
                                  onChange={(x) => {
                                    handleFunctionChange(group.phone_directory_group_id, item.phone_directory_id, x.target.value);
                                  }}
                                  defaultValue={getFunctionName(group.phone_directory_group_id, item.phone_directory_id)}
                                />
                              </FormControl>
                            </Grid>
                          </React.Fragment>
                        );
                      })}
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            );
          })
        : null}
    </GenericCRUD>
  );
}
