import React from 'react';
import Button from '@mui/material/Button';
import { post } from 'src/api/api';
import { useQuery, useQueryClient } from 'react-query';

import AddCircleIcon from '@mui/icons-material/AddCircle';

export default function MaterialMobiliseButton({ material }) {
  const queryClient = useQueryClient();
  const vehicleClickHandler = async () => {
    await post(`material/${material.material_id}/currentIntervention`, { newStatus: 'ENGAGED' });
    queryClient.invalidateQueries('material/withState');
  };

  let Icon = AddCircleIcon;
  let color = 'vehicle_available';
  switch (material.material_status) {
    case 'ENGAGED':
      // Icon = AddAlertIcon;
      color = 'vehicle_engaged';
      break;

    default:
      Icon = AddCircleIcon;
      color = 'neutral';
      break;
  }
  return (
    <Button
      fullWidth
      sx={{ height: '100%', minWidth: '32px' }}
      variant="contained"
      // startIcon={<Icon />}
      size="large"
      color={'primary'}
      onClick={() => {
        vehicleClickHandler();
      }}
    >
      <AddCircleIcon />
    </Button>
  );
}
