import React from 'react';
import TableCell from '@mui/material/TableCell';
import { Colors } from 'src/styles/variables';
import Box from '@mui/material/Box';

export default function RegroupedPlanningLine({ day, min_requierement, highlight }) {
  return (
    <>
      <TableCell align="right" sx={{ minWidth: 5, height: '1em', p: 0, borderColor: '#aaa' }}>
        <Box
          sx={{
            bgcolor: highlight ? Colors.Summary.currentDay : Colors.Summary.day,
            textAlign: 'center',
            height: '50%',
            width: '100%',
            borderRight: '1px solid #ccc',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            color: day.morrning < min_requierement ? Colors.Summary.bellowMin : Colors.Summary.normal,
          }}
        >
          {day.morrning}
        </Box>
        <Box
          sx={{
            bgcolor: highlight ? Colors.Summary.currentDay : Colors.Summary.day,
            textAlign: 'center',
            height: '50%',
            width: '100%',
            borderRight: '1px solid #ccc',
            borderTop: '1px solid #ccc',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            color: day.afternoon < min_requierement ? Colors.Summary.bellowMin : Colors.Summary.normal,
          }}
        >
          {day.afternoon}
        </Box>
      </TableCell>
      <TableCell align="right" sx={{ minWidth: 5, height: '1em', p: 0, borderColor: '#aaa' }}>
        <Box
          sx={{
            bgcolor: highlight ? Colors.Summary.currentNight : Colors.Summary.night,
            textAlign: 'center',
            height: '100%',
            width: '100%',
            borderRight: '1px solid #888',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            color: day.night < min_requierement ? Colors.Summary.bellowMin : Colors.Summary.normal,
          }}
        >
          {day.night}
        </Box>
      </TableCell>
    </>
  );
}
