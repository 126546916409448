import React from 'react';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import { useParams } from 'react-router-dom';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import frLocale from '@fullcalendar/core/locales/fr';
import { baseUrl } from 'src/api/axios';

export default function UserAvailabilitiesList() {
  let { id } = useParams();

  return (
    <Box>
      <Paper sx={{ p: 2 }}>
        <FullCalendar
          locale={frLocale}
          plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
          headerToolbar={{
            left: 'prev,next today',
            center: 'title',
            right: 'dayGridMonth,timeGridWeek',
          }}
          displayEventTime={true}
          displayEventEnd={true}
          initialView={'timeGridWeek'}
          editable={false}
          selectable={false}
          selectMirror={true}
          dayMaxEvents={true}
          weekends={true}
          contentHeight={800}
          // initialEvents={events.currentEvents}
          // events={newEvents}
          events={`${baseUrl}availability/calendar/${id}`}
          // events={(info, successCallback, failureCallback) => {
          //   successCallback()
          //   return console.log(info);
          // }}
          // select={handleDateSelect}
          // eventContent={renderEventContent} // custom render function
          // eventClick={handleEventClick}
          // eventsSet={handleEvents}
        />
      </Paper>
    </Box>
  );
}
