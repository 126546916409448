import React from 'react';
import GenericList from 'src/shared/genericList';
import { useTranslation } from 'react-i18next';

export default function VehiclesList() {
  const { t } = useTranslation();
  const columns = [
    { field: 'vehicle_id', headerName: 'ID', hide: true },
    {
      field: 'vehicle_type',
      headerName: t('VEHICLE_TYPE'),
      width: 250,
      editable: true,
    },
    {
      field: 'vehicle_code',
      headerName: t('VEHICLE_CODE'),
      width: 250,
      editable: true,
    },
    {
      field: 'vehicle_name',
      headerName: t('VEHICLE_NAME'),
      width: 250,
      editable: true,
      flex: 1,
    },
    {
      field: 'vehicle_order',
      headerName: t('SORT_ORDER'),
      width: '150',
      editable: true,
    },
  ];

  return <GenericList objectName={'vehicle'} columns={columns} rowId={'vehicle_id'} />;
}
