import { createContext, useContext, useState, useMemo } from 'react';
import { AuthenticationService } from 'src/services/auth';

const UserContext = createContext(null);

export const useUserContext = () => useContext(UserContext);

export const UserContextProvider = ({ children }) => {
  const userState = {
    currentUser: {
      userId: 0,
      memberId: 0,
      memberName: '',
      memberRank: '',
      memberSection: '',
    },
  };

  const [state, setstate] = useState(userState);

  const value = useMemo(() => {
    return {
      ...state,
      setUser: (newCurrentUser) => {
        if (newCurrentUser.is_disabled) {
          AuthenticationService.logout();
        }
        setstate({ ...state, currentUser: newCurrentUser });
      },
    };
  }, [state]);

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};
// export default UIContext;
