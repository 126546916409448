import React from 'react';
import { Wrapper, google } from '@googlemaps/react-wrapper';
import { useQuery, useQueryClient } from 'react-query';
import Box from '@mui/material/Box';

//import GoogleMapReact from 'google-map-react';

function TableauMapV() {
  const queryClient = useQueryClient();
  const globalParam = queryClient.getQueryData(['params/global']);

  const center = { lat: 2, lng: 78 };
  const zoom = 16;

  // if (isLoading) {
  //   return <Loader />;
  // }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', flex: 1, minHeight: '10vh', height: '100%' }}>
      <Wrapper apiKey={globalParam.google_map_api}>
        <MyMapComponent center={center} zoom={zoom} google={google} />
      </Wrapper>
    </Box>
  );
}

function MyMapComponent({ center, zoom, google }) {
  const ref = React.useRef();
  const [myMap, setMyMap] = React.useState(null);
  const queryClient = useQueryClient();
  const globalParam = queryClient.getQueryData(['params/global']);

  React.useEffect(() => {
    const map = new window.google.maps.Map(ref.current, {
      center,
      zoom,
      mapTypeId: window.google.maps.MapTypeId.HYBRID,
      scaleControl: true,
      styles: [
        {
          featureType: 'poi',
          elementType: 'labels',
          stylers: [{ visibility: 'off' }],
        },
      ],
    });

    // map.setOptions(opt);

    let geolocationAvailable = false;
    if ('geolocation' in navigator) {
      geolocationAvailable = true;
    }
    if (geolocationAvailable) {
      navigator.geolocation.getCurrentPosition(function (position) {
        const pos = {
          lat: 46.626753,
          lng: 6.827802,
        };
        // const pos = {
        //   lat: position.coords.latitude,
        //   lng: position.coords.longitude,
        // };

        new window.google.maps.Marker({
          position: pos,
          map: map,
        });
        // new window.google.maps.Marker({
        //   position: {
        //     lat: 46.658744677655264,
        //     lng: 6.793464089227376,
        //   },
        //   map: map,
        // });

        map.setCenter(pos);
      });
    }
    setMyMap(map);
  }, []);

  return (
    <>
      <div ref={ref} id="map" />
    </>
  );
}

const TableauMap = React.memo(TableauMapV);
export default TableauMap;
