import fetchApi from './axios';
import store from 'src/modules/shared/globalFeedback/globalFeedback.vanilla.store';
export const getAllUsers = async () => {
  return await getAllData('user');
};

export const getAllRanks = async () => {
  return await getAllData('rank');
};

export const getAllSections = async () => {
  return await getAllData('section');
};

export const getSection = async ({ queryKey }) => {
  return await getData('section', { queryKey });
};
export const getRank = async ({ queryKey }) => {
  return await getData('rank', queryKey);
};

// export const createSection = async (data) => {
//   const result = await fetchApi.post(`/section`, data);

//   if (result.status !== 201 && result.status !== 200 && result.status !== 204) {
//     throw new Error(`Can't create section`);
//   }

//   return result.data;
// };

// export const updateSection = async ({ id, ...data }) => {
//   const result = await fetchApi.put(`/section/${id}`, data);

//   if (result.status !== 200) {
//     throw new Error(`Can't update section`);
//   }

//   return result.data;
// };

// export const deleteSection = async ({ id, ...data }) => {
//   const result = await fetchApi.delete(`/section/${id}`, data);

//   if (result.status !== 200 && result.status !== 202 && result.status !== 204) {
//     throw new Error(`Can't create section`);
//   }

//   return result.data;
// };

export const getUser = async ({ queryKey }) => {
  /* eslint-disable no-unused-vars */
  const [_key, { id }] = queryKey;
  const result = await fetchApi.get(`/user/${id}`);

  if (result.status !== 200) {
    throw new Error(`Can't retriev user`);
  }

  return result.data;
};

export const updateUser = async ({ id, ...data }) => {
  const result = await fetchApi.put(`/user/${id}`, data);

  if (result.status !== 200) {
    throw new Error(`Can't update user`);
  }

  return result.data;
};

export const getData = async (ObjectName, { queryKey }) => {
  /* eslint-disable no-unused-vars */
  const [_key, { id, withDetails }] = queryKey;
  if (id === '0') {
    return {};
  }
  let url = `/${ObjectName}/${id}`;

  if (withDetails) {
    url += '/details';
  }
  const result = await fetchApi.get(url);

  if (result.status !== 200) {
    throw new Error(`Can't retriev ${ObjectName}`);
  }

  return result.data;
};

export const getAllData = async (objectName, id = null) => {
  let url = `/${objectName}/list`;
  if (id) {
    url += '/' + id;
  }
  const result = await fetchApi.get(url);

  if (result.status !== 200) {
    throw new Error(`Can't retriev ${objectName} list`);
  }

  return result.data;
};

export const createData = async (objectName, data) => {
  return await post(objectName, data);
};

export const updateData = async (objectName, { id, ...data }) => {
  let url;
  if (id) {
    url = `/${objectName}/${id}`;
  } else {
    url = `/${objectName}`;
  }
  const result = await fetchApi.put(url, data);

  if (result.status !== 200) {
    throw new Error(`Can't update ${objectName}`);
  }

  return result.data;
};

export const deleteData = async (objectName, { id, ...data } = { id: null }) => {
  let result;
  try {
    if (id) {
      result = await fetchApi.delete(`/${objectName}/${id}`, data);
    } else {
      result = await fetchApi.delete(`/${objectName}`, data);
    }

    if (result.status !== 200 && result.status !== 202 && result.status !== 204) {
      store.getState().setFeedback({ msg: `Can't delete ${objectName}`, severity: 'error' });
      throw new Error(`Can't delete ${objectName}`);
    }
  } catch (error) {
    store.getState().setFeedback({ msg: `Can't delete ${objectName}`, severity: 'error' });
    throw new Error(`Can't delete ${objectName}`);
  }

  return result.data;
};

export const get = async (objectName, params) => {
  try {
    const result = await fetchApi.get(`/${objectName}`, { params: params });

    if (result.status !== 200) {
      store.getState().setFeedback({ msg: `Can't retriev ${objectName}`, severity: 'error' });
      throw new Error(`Can't retriev ${objectName}`);
    }
    return result.data;
  } catch (error) {
    store.getState().setFeedback({ msg: `Can't retriev ${objectName}`, severity: 'error' });
    throw new Error(`Can't retriev ${objectName}`);
  }
};

export const post = async (objectName, data) => {
  try {
    const result = await fetchApi.post(`/${objectName}`, data);

    if (result.status !== 201 && result.status !== 200 && result.status !== 204) {
      store.getState().setFeedback({ msg: `Can't create ${objectName}`, severity: 'error' });
      throw new Error(`Can't create ${objectName}`);
    }

    return result.data;
  } catch (error) {
    store.getState().setFeedback({ msg: `Can't create ${objectName}`, severity: 'error' });
    throw new Error(`Can't create ${objectName}`);
  }
};

export const put = async (objectName, { id, ...data } = { id: null }) => {
  let result;
  try {
    if (id) {
      result = await fetchApi.put(`/${objectName}/${id}`, data);
    } else {
      result = await fetchApi.put(`/${objectName}`, data);
    }

    if (result.status !== 200) {
      store.getState().setFeedback({ msg: `Can't update ${objectName}`, severity: 'error' });
      throw new Error(`Can't update ${objectName}`);
    }

    return result.data;
  } catch (error) {
    store.getState().setFeedback({ msg: `Can't update ${objectName}`, severity: 'error' });
    throw new Error(`Can't update ${objectName}`);
  }
};
