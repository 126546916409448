import React from 'react';
import { PDFViewer, PDFDownloadLink } from '@react-pdf/renderer';
import MyDocument from './document';
import { useParams } from 'react-router-dom';
import { get } from 'src/api/api';
import { getData } from 'src/api/api';
import { useQuery } from 'react-query';
import { useQueryClient } from 'react-query';
import { B2_BASE_URL } from 'src/constants/constants';
import Loader from 'react-loader-spinner';
import { useHistory } from 'react-router-dom';
import DownloadIcon from '@mui/icons-material/Download';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';

const handleClick = (url) => {
  console.log(url);
  window.open(url, '_blank');
};

export default function InterventionLogReport() {
  let downloaded = false;
  let { id } = useParams();
  const history = useHistory();
  const { t } = useTranslation();

  const queryClient = useQueryClient();
  const globalParam = queryClient.getQueryData(['params/global']);

  const { data: logData, error, isLoading, isError } = useQuery(['incident-logs/' + id], async () => await get('incident-logs/' + id));

  const { data: staffData, isLoading: staffIsLoading } = useQuery(['incidentStaff', { id: id }], async ({ queryKey }) => await get(`incident/${id}/staff`));

  const { data: vhcData, isLoading: vhcIsLoading } = useQuery(['incidentVehicle', { id: id }], async ({ queryKey }) => await get(`incident/${id}/Vehicle`));

  const { data: matData, isLoading: matIsLoading } = useQuery(['incidentMaterial', { id: id }], async ({ queryKey }) => await get(`incident/${id}/Material`));

  const {
    data: incidentData,
    error: errorData,
    isLoading: isLoadinData,
    isError: isErrorData,
  } = useQuery(['incidentDetail', { id: id }], async ({ queryKey }) => await getData('incident', { queryKey }));

  if (isLoading || isLoadinData || staffIsLoading || vhcIsLoading || matIsLoading || !globalParam) {
    return <Loader />;
  }

  return (
    <PDFDownloadLink
      document={
        <MyDocument
          incidentData={incidentData}
          logData={logData}
          staffData={staffData}
          vhcData={vhcData}
          matData={matData}
          globalParam={globalParam}
          b2Url={B2_BASE_URL}
        />
      }
      fileName={`incident_${id}.pdf`}
    >
      {({ blob, url, loading, error }) => {
        {
          /* console.log(url);
        if (url) {
          //window.open(url, '_blank');
          if (!downloaded) {
            downloaded = true;
            saveFile(blob);
            // history.goBack();
          }
        } */
        }
        if (loading) {
          return <Loader />;
        } else {
          return (
            <Button fullWidth variant="contained" startIcon={<DownloadIcon />} size="large" color="neutral" onClick={() => handleClick(url)}>
              {t('DOWNLOAD_LOG')}
            </Button>
          );
        }
      }}
    </PDFDownloadLink>
  );

  // return (
  //   <PDFViewer width="100%" height="100%" style={{ height: '100vh' }} showToolbar>
  //     <MyDocument
  //       incidentData={incidentData}
  //       logData={logData}
  //       staffData={staffData}
  //       vhcData={vhcData}
  //       matData={matData}
  //       globalParam={globalParam}
  //       b2Url={B2_BASE_URL}
  //     />
  //   </PDFViewer>
  // );
}

// const saveFile = async (blob) => {
//   const fileURL = URL.createObjectURL(blob);
//   window.open(
//     fileURL,
//     '_blank',
//     'toolbar=yes, location=yes, directories=no, status=no, menubar=yes, scrollbars=yes, resizable=no, copyhistory=yes, width=800, height=800'
//   );
// };

// const saveFile = async (blob) => {
//   const a = document.createElement('a');
//   a.download = 'my-file.pdf';
//   a.href = URL.createObjectURL(blob);
//   a.addEventListener('click', (e) => {
//     setTimeout(() => URL.revokeObjectURL(a.href), 30 * 1000);
//   });
//   a.click();
// };
