import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import ListItemText from '@mui/material/ListItemText';
import ListItem from '@mui/material/ListItem';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import { useQuery } from 'react-query';
import { getAllData } from 'src/api/api';
import Loader from 'src/shared/defaultLoader';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function QuickSelector({ objectName, title, buttonTitle, startIcon, onItemSelected, columnName, columnId }) {
  const query = () => getAllData(objectName);
  const { data, error, isLoading, isError } = useQuery(objectName, query);
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  if (isLoading) {
    return <Loader />;
  }
  if (isError) {
    return <span>Error : {error.message}</span>;
  }
  return (
    <div>
      <Button size="small" variant="contained" onClick={handleClickOpen} startIcon={startIcon}>
        {buttonTitle}
      </Button>
      <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
        <AppBar sx={{ position: 'relative' }}>
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              {title}
            </Typography>
            {/* <Button autoFocus color="inherit" onClick={handleClose}>
              save
            </Button> */}
          </Toolbar>
        </AppBar>
        <List>
          {data.map((e) => {
            return (
              <div
                key={e[columnId]}
                onClick={() => {
                  onItemSelected(e);
                  handleClose();
                }}
              >
                <ListItem button>
                  <ListItemText primary={e[columnName]} />
                </ListItem>
                <Divider />
              </div>
            );
          })}
        </List>
      </Dialog>
    </div>
  );
}
