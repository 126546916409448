import React from 'react';
import Grid from '@mui/material/Grid';
import { getData, put } from 'src/api/api';
import { useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import Loader from 'src/shared/defaultLoader';
import IncidentsCard from '../card/incidentCard';
import IncidentStaff from '../staff/incidentStaff';
import IncidentVehicle from '../vehicle/incidentVehicle';
import IncidentMaterial from '../material/incidentMaterial';
import FeedIcon from '@mui/icons-material/Feed';
//import Button from '../../../components/button/button';
import Button from '@mui/material/Button';
import MUIButton from '@mui/material/Button';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import AlertDialog from 'src/shared/sharedModal';
import useStoreGlobalFeedback from 'src/modules/shared/globalFeedback/globalFeedback.store.js';
import { useMutation, useQueryClient } from 'react-query';
import { updateData, createData, deleteData } from 'src/api/api';
import { AuthenticationService } from 'src/services/auth';
import RoomIcon from '@mui/icons-material/Room';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import IncidentsPhotoDownloadButton from '../incidentsPhotoDownloadButton/incidentsPhotoDownloadButton';

export default function IncidentDetail() {
  const history = useHistory();
  const module = history.location.pathname.split('/')[1];
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(false);
  const [showCloseConfirmation, setShowCloseConfirmation] = React.useState(false);
  const queryClient = useQueryClient();

  const isSquadLeaderOrHigher = AuthenticationService.checkUserMaxAccessLevel() > 80;

  let { id } = useParams();
  const getQueryFn = async ({ queryKey }) => await getData('incident', { queryKey });
  const { data, error, isLoading, isError } = useQuery(['incidentDetail', { id: id }], getQueryFn);

  const setFeedback = useStoreGlobalFeedback((state) => state.setFeedback);
  const deleteQueryFn = async ({ id, ...data }) => await deleteData('incident', { id, ...data });

  const { mutateAsync: mutateAsyncDelete } = useMutation(deleteQueryFn, {
    onSuccess: (data) => {},
  });

  async function deleteCurrentRecord() {
    const res = await mutateAsyncDelete({ ...data, id });
    if (res) {
      if (res.isError) {
        console.log(res);
        setFeedback({ msg: t(res.message), severity: 'error' });
      } else {
        queryClient.setQueryData(['incident', { id: id }], {});
        history.goBack();
      }
    }
  }

  async function closeIncident() {
    await put('incident/close/' + id);
    queryClient.setQueryData(['incident', { id: id }], {});
    history.goBack();
  }

  if (isLoading) {
    return <Loader />;
  }

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <IncidentsCard incident={data} />
      </Grid>
      <Grid item xs={12}>
        {data?.incident_address ? (
          <Grid item xs={12}>
            <Button
              fullWidth
              variant="contained"
              startIcon={<RoomIcon />}
              size="large"
              color="neutral"
              onClick={() => window.open(`https://www.google.com/maps/dir/Current+Location/${data.incident_address}`, '_blank')}
            >
              {t('GPS')}
            </Button>
          </Grid>
        ) : null}
      </Grid>
      <Grid item xs={12}>
        <IncidentStaff incident={data} />
      </Grid>
      <Grid item xs={12}>
        <IncidentVehicle incident={data} />
      </Grid>
      <Grid item xs={12}>
        <IncidentMaterial incident={data} />
      </Grid>

      {isSquadLeaderOrHigher ? (
        <>
          <Grid item xs={12}>
            <Button
              fullWidth
              variant="contained"
              startIcon={<FeedIcon />}
              size="large"
              color="neutral"
              onClick={() => history.push(`/${module}/intervention/${id}/log`)}
            >
              {t('INTERVENTION_LOG')}
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Button
              fullWidth
              variant="contained"
              startIcon={<PictureAsPdfIcon />}
              size="large"
              color="neutral"
              onClick={() => history.push(`/${module}/intervention/${id}/log/report`)}
            >
              {t('DOWNLOAD_LOG')}
            </Button>
          </Grid>
          <Grid item xs={12}>
            <IncidentsPhotoDownloadButton incidentId={id} />
          </Grid>
        </>
      ) : null}

      {module === 'admin' ? (
        <Grid item xs={12}>
          <MUIButton
            fullWidth
            variant="contained"
            color="error"
            onClick={() => {
              setShowCloseConfirmation(true);
            }}
          >
            {t('CLOSE_INCIDENT')}
          </MUIButton>
        </Grid>
      ) : null}

      {module === 'admin' ? (
        <Grid item xs={12}>
          {/* <Button
            fullWidth
            variant="contained"
            startIcon={<FeedIcon />}
            size="large"
            color="error"
            onClick={() => history.push(`/${module}/intervention/${id}/log`)}
          >
            {t('INTERVENTION_LOG')}
          </Button> */}
          <MUIButton
            fullWidth
            variant="contained"
            color="error"
            onClick={() => {
              setOpen(true);
              // deleteCurrentRecord;
            }}
          >
            {t('DELETE')}
          </MUIButton>
        </Grid>
      ) : null}

      <AlertDialog
        open={open}
        setOpen={setOpen}
        Message={t('Êtes-vous sûr de vouloir supprimer cette intervention?')}
        onConfirm={() => deleteCurrentRecord()}
        AcceptBtn={t('YES')}
        CancelBtn={t('CANCEL')}
      />
      <AlertDialog
        open={showCloseConfirmation}
        setOpen={setShowCloseConfirmation}
        Message={t('Êtes-vous sûr de vouloir fermer cette intervention?')}
        onConfirm={() => closeIncident()}
        AcceptBtn={t('YES')}
        CancelBtn={t('CANCEL')}
      />
    </Grid>
  );
}
