import React from 'react';
import GenericList from 'src/shared/genericList';
import { useTranslation } from 'react-i18next';

export default function SpecializationList() {
  const { t } = useTranslation();
  const columns = [
    { field: 'specialization_id', headerName: 'ID', hide: true },
    {
      field: 'specialization_abbreviation',
      headerName: t('RANK_ABBREVIATION'),
      width: 150,
      editable: true,
    },
    {
      field: 'specialization_name',
      headerName: t('NAME'),
      width: 75,
      editable: true,
      flex: 1,
    },
    {
      field: 'specialization_order',
      headerName: t('SORT_ORDER'),
      width: 75,
      editable: true,
      flex: 1,
    },
  ];

  return <GenericList objectName={'specialization'} columns={columns} />;
}
