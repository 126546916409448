import React, { useState, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Loader from 'src/shared/defaultLoader';
import { get } from 'src/api/api';
import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';
import { Utils } from 'src/services/utils';

export default function GroupSelector({ defaultGroupId, onChange, label = '', inputRef }) {
  const [groupList, setGroupList] = useState();
  const { t } = useTranslation();

  const [value, setValue] = React.useState(null);
  const [inputValue, setInputValue] = React.useState('');

  const getQueryFn = async ({ queryKey }) => await get('group/list', queryKey[1]);
  const { data, isLoading } = useQuery(['group/search'], getQueryFn, {
    onSuccess: (data) => {
      if ((data[0].group_id ?? null) !== null) {
        data.unshift({
          group_id: null,
          group_name: '',
          group_code: '',
          group_order: 0,
        });
      }

      setGroupList(data);
      if (defaultGroupId) {
        setValue(data.find((e) => e.group_id === defaultGroupId));
      }
    },
  });

  useEffect(() => {
    if (defaultGroupId && groupList) {
      setValue(groupList.find((e) => e.group_id === defaultGroupId));
    } else {
      setValue(null);
    }
  }, [defaultGroupId, groupList]);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <div>
      <form autoComplete="off">
        <Autocomplete
          noOptionsText={t('NO_OPTION')}
          fullWidth
          value={value}
          onChange={(event, newValue) => {
            setValue(newValue);
            onChange(newValue);
          }}
          inputValue={inputValue}
          onInputChange={(event, newInputValue) => {
            setInputValue(newInputValue);
          }}
          options={data}
          getOptionLabel={(option) => (option.group_id === null ? '⠀' : `[${option.group_code}] ${option.group_name}`)}
          renderInput={(params) => <TextField variant="standard" fullWidth autoComplete="off" {...params} label={label} inputRef={inputRef} />}
        />
      </form>
    </div>
  );
}
