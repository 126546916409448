import React from 'react';
import GenericCRUD from 'src/shared/genericCRUD';
import { useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useQuery } from 'react-query';
import { getData, getAllData } from 'src/api/api';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import { useTranslation } from 'react-i18next';
import FormControl from '@mui/material/FormControl';
import Loader from 'src/shared/defaultLoader';
import MenuItem from '@mui/material/MenuItem';
import ReactHookFormSelect from 'src/shared/reactHookFormSelect';
import { VEHICLE_TYPE } from 'src/constants/constants';

export default function Vehicle() {
  const { register, handleSubmit, control, setValue } = useForm();
  const { t } = useTranslation();
  let { id } = useParams();
  const getQueryFn = async ({ queryKey }) => await getData('vehicle', { queryKey });
  const { data, error, isLoading, isError } = useQuery(['vehicle', { id }], getQueryFn);

  const inputRef = React.useRef();

  if (isLoading) {
    return <Loader />;
  }
  if (isError) {
    return <span>Error : {error.message}</span>;
  }

  return (
    <GenericCRUD objectName={'vehicle'} handleSubmit={handleSubmit} data={data} isDisabledManagement={true}>
      <Grid item xs={12} sm={6}>
        <ReactHookFormSelect
          required
          register={register}
          id="vehicle_type"
          name="vehicle_type"
          label={t('VEHICLE_TYPE')}
          control={control}
          defaultValue={data.vehicle_type}
          variant="standard"
        >
          {VEHICLE_TYPE.map((type) => {
            return (
              <MenuItem key={type.code} value={type.code}>
                {t(type.name)}
              </MenuItem>
            );
          })}
        </ReactHookFormSelect>
      </Grid>

      <Grid item xs={12} sm={6}>
        <FormControl variant="standard" fullWidth={true}>
          <TextField
            {...register('vehicle_code')}
            required
            autoComplete="off"
            id="vehicle_code"
            name="vehicle_code"
            label={t('VEHICLE_CODE')}
            fullWidth
            variant="standard"
            defaultValue={data.vehicle_code}
          />
        </FormControl>
      </Grid>

      <Grid item xs={12} sm={6}>
        <FormControl variant="standard" fullWidth={true}>
          <TextField
            {...register('vehicle_name')}
            required
            autoComplete="off"
            id="vehicle_name"
            name="vehicle_name"
            label={t('VEHICLE_NAME')}
            fullWidth
            variant="standard"
            defaultValue={data.vehicle_name}
          />
        </FormControl>
      </Grid>

      <Grid item xs={12} sm={6}>
        <FormControl variant="standard" fullWidth={true}>
          <TextField
            {...register('vehicle_order')}
            required
            id="vehicle_order"
            name="vehicle_order"
            label={t('SORT_ORDER')}
            fullWidth
            autoComplete="family-name"
            variant="standard"
            defaultValue={data.vehicle_order}
          />
        </FormControl>
      </Grid>
    </GenericCRUD>
  );
}
