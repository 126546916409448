import React from 'react';
import GenericCRUD from 'src/shared/genericCRUD';
import { useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useQuery } from 'react-query';
import { getData } from 'src/api/api';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import { useTranslation } from 'react-i18next';
import FormControl from '@mui/material/FormControl';
import Loader from 'src/shared/defaultLoader';
import MapLayerFileImport from './mapLayerFileImport';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import PopulatedSelect from 'src/shared/populatedSelect';

export default function MapLayerDetail() {
  const { register, handleSubmit, control } = useForm();
  const { t } = useTranslation();
  let { id } = useParams();
  const getQueryFn = async ({ queryKey }) => await getData('map-layers', { queryKey });

  const [fileName, setFileName] = React.useState('');

  const { data, error, isLoading, isError } = useQuery(['map-layers', { id }], getQueryFn, {
    onSuccess: (data) => {
      if (data.map_layer_url && data.map_layer_url !== '') {
        const fileData = JSON.parse(data.map_layer_url);
        setFileName(fileData.fileName);
      }
    },
  });

  if (isLoading) {
    return <Loader />;
  }
  if (isError) {
    return <span>Error : {error.message}</span>;
  }

  return (
    <GenericCRUD objectName={'map-layers'} handleSubmit={handleSubmit} data={data} stayOnPage rowId={'map_layer_id'}>
      <Grid item xs={12} sm={6}>
        <FormControl variant="standard" fullWidth={true}>
          <TextField
            {...register('map_layer_name')}
            required
            autoComplete="off"
            id="map_layer_name"
            name="map_layer_name"
            label={t('NAME')}
            fullWidth
            variant="standard"
            defaultValue={data.map_layer_name}
          />
        </FormControl>
      </Grid>

      <Grid item xs={12} md={6}>
        <PopulatedSelect
          control={control}
          value={data.role_id}
          endoint={'role'}
          name={'role_id'}
          id={'role_id'}
          label={t('ROLE')}
          withBlankOption
          displayAttribute={'role_name'}
        />
      </Grid>

      {id != 0 ? (
        <Grid item xs={12}>
          <Card>
            <CardHeader title={t('FILE')} />
            <CardContent>
              <Grid container spacing={3}>
                <Grid item xs={12} md={12}>
                  <FormControl variant="standard" fullWidth={true}>
                    <TextField
                      id="map_layer_url"
                      name="map_layer_url"
                      label={t('KML_OR_KMZ_FILE')}
                      fullWidth
                      variant="standard"
                      value={fileName}
                      InputProps={{
                        readOnly: true,
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={12}>
                  <Box sx={{ display: 'flex', flexDirection: 'row-reverse' }}>
                    <Stack direction="row" spacing={1}>
                      <MapLayerFileImport layerId={id} />
                    </Stack>
                  </Box>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      ) : null}
    </GenericCRUD>
  );
}
