import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useTranslation } from 'react-i18next';
import { DateUtiles } from 'src/services/dateUtiles';
import { get, getAllData } from 'src/api/api';
import { useQuery } from 'react-query';
import Loader from 'src/shared/defaultLoader';
import { Colors } from 'src/styles/variables';
import Box from '@mui/material/Box';
import RegroupedPlanningLine from './regroupedPlanningLine';

export default function RegroupedPlanning({ baseDate, type = 'AVAILABILITY', regroup = true }) {
  const { t } = useTranslation();

  const monday = DateUtiles.getMonday(baseDate);
  const sunday = DateUtiles.getSunday(baseDate);

  const url = 'availability/calendar/all';
  const cacheKey = 'AllWeeklyAvailability/';

  const getQueryFn = async ({ queryKey }) => await get(url, { start: monday, end: sunday });
  const { data, isLoading } = useQuery([cacheKey + monday], getQueryFn, {
    onSuccess: (data) => {
      // console.log(data);
    },
  });

  const getSpecializationQueryFn = async () => await getAllData('specialization');
  const { data: specializations, isLoading: specializationIsLoading } = useQuery('specialization', getSpecializationQueryFn);

  if (isLoading || specializationIsLoading) {
    return <Loader />;
  }

  let specData = [];
  specData.push({ name: t('TOTAL_WORKFORCE'), members: data, min_requierement: null, color: null });

  for (const spec of specializations) {
    const members = data.reduce((previousValue, currentValue) => {
      if (currentValue.specializations.includes(spec.specialization_abbreviation)) {
        previousValue.push(currentValue);
      }
      return previousValue;
    }, []);

    specData.push({ name: spec.specialization_name, members: members, min_requierement: spec.min_requierement, color: spec.color });
  }

  const getRecordByDate = (date, source) => {
    let availabilities = [];
    if (data) {
      for (const availability of source) {
        const start = new Date(availability.start);
        const end = new Date(availability.end);
        if (start <= date && end >= date) {
          availabilities.push(availability);
        }
      }
    }
    if (availabilities.length === 0) {
      availabilities.push({
        id: 0,
        title: 'NOT_AVAILABLE',
        start: null,
        end: null,
        color: null,
        textColor: 'black',
      });
    }
    return availabilities;
  };

  for (const spec of specData) {
    let weekDays = [];
    for (let index = 0; index < 7; index++) {
      const day = DateUtiles.getMonday(baseDate);
      day.setDate(day.getDate() + index);
      let dayAbbr = '';
      switch (day.getDay()) {
        case 1:
          dayAbbr = 'MONDAY_ABBR';
          break;
        case 2:
          dayAbbr = 'TUESDAY_ABBR';
          break;
        case 3:
          dayAbbr = 'WEDNESDAY_ABBR';
          break;
        case 4:
          dayAbbr = 'THUSDAY_ABBR';
          break;
        case 5:
          dayAbbr = 'FRIDAY_ABBR';
          break;
        case 6:
          dayAbbr = 'SATURDAY_ABBR';
          break;
        case 0:
          dayAbbr = 'SUNDAY_ABBR';
          break;
        default:
          break;
      }
      const dayData = {
        id: index,
        date: day,
        name: t(dayAbbr) + ' ' + day.getDate(),
        morrning: 0,
        afternoon: 0,
        night: 0,
      };

      const minutesInOneDay = 1440;
      const DayDelta = minutesInOneDay * index;

      for (const member of spec.members) {
        const morning = DateUtiles.addMinutes(monday, 60 * 10 + DayDelta);
        const morningRecords = getRecordByDate(morning, member.availabilities);
        if (morningRecords[0].title === 'AVAILABLE') {
          dayData.morrning++;
        }
      }

      for (const member of spec.members) {
        const afternoon = DateUtiles.addMinutes(monday, 60 * 14 + DayDelta);
        const afternoonRecords = getRecordByDate(afternoon, member.availabilities);
        if (afternoonRecords[0].title === 'AVAILABLE') {
          dayData.afternoon++;
        }
      }

      for (const member of spec.members) {
        const night = DateUtiles.addMinutes(monday, 60 * 23 + DayDelta);
        const nightRecords = getRecordByDate(night, member.availabilities);
        if (nightRecords[0].title === 'AVAILABLE') {
          dayData.night++;
        }
      }

      weekDays.push(dayData);
    }
    spec.standardizedAvailability = weekDays;
  }

  let today = new Date().getDay();
  if (DateUtiles.getWeekNumber(new Date()) !== DateUtiles.getWeekNumber(baseDate)) {
    today = -1;
  }

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell sx={{ minWidth: 5, width: 5, p: 0 }}></TableCell>
            <TableCell sx={{ minWidth: 120, width: 120, maxWidth: 120, p: 1 }}></TableCell>
            <TableCell sx={{ p: 0, width: 1 / 7, color: today === 1 ? 'red' : null }} align="left">
              {t('MONDAY_ABBR')}
            </TableCell>

            <TableCell sx={{ p: 0, width: 1 / 7, color: today === 2 ? 'red' : null }} align="left">
              {t('TUESDAY_ABBR')}
            </TableCell>

            <TableCell sx={{ p: 0, width: 1 / 7, color: today === 3 ? 'red' : null }} align="left">
              {t('WEDNESDAY_ABBR')}
            </TableCell>

            <TableCell sx={{ p: 0, width: 1 / 7, color: today === 4 ? 'red' : null }} align="left">
              {t('THUSDAY_ABBR')}
            </TableCell>
            <TableCell sx={{ p: 0, width: 1 / 7, color: today === 5 ? 'red' : null }} align="left">
              {t('FRIDAY_ABBR')}
            </TableCell>
            <TableCell sx={{ p: 0, width: 1 / 7, color: today === 6 ? 'red' : null }} align="left">
              {t('SATURDAY_ABBR')}
            </TableCell>
            <TableCell sx={{ p: 0, width: 1 / 7, color: today === 0 ? 'red' : null }} align="left">
              {t('SUNDAY_ABBR')}
            </TableCell>
          </TableRow>
        </TableHead>
      </Table>
      <Table>
        <TableBody>
          {specData.map((spec) => (
            <TableRow key={spec.name}>
              <TableCell sx={{ minWidth: 5, width: 5, p: 0, backgroundColor: spec.color }}></TableCell>
              <TableCell sx={{ minWidth: 120, width: 120, maxWidth: 120, overflow: 'hidden', p: 1, borderRight: '1px solid #888' }}>{spec.name}</TableCell>
              {spec.standardizedAvailability.map((day, dayIndex) => {
                return <RegroupedPlanningLine key={dayIndex} highlight={today === (dayIndex + 1) % 7} day={day} min_requierement={spec.min_requierement} />;
              })}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
