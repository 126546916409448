import React from 'react';
import Button from '../../components/button/button';
import { useTranslation } from 'react-i18next';
import { getAllData } from 'src/api/api';
import Loader from 'src/shared/defaultLoader';
import { useQuery, useQueryClient } from 'react-query';
import { useTheme } from '@mui/material/styles';
import { get } from 'src/api/api';
import { useHistory } from 'react-router-dom';
import LoopIcon from '@mui/icons-material/Loop';

export default function CurrentIC() {
  const theme = useTheme();
  const history = useHistory();

  const { t } = useTranslation();
  const [open, setOpen] = React.useState(false);
  const queryClient = useQueryClient();

  const getSpecializationQueryFn = async () => await getAllData('specialization');
  const { data: specializations, isLoading: specializationIsLoading } = useQuery('specialization', getSpecializationQueryFn);

  const getQueryFn = async ({ queryKey }) => await get('incident/currentIncident');
  const { data, isLoading } = useQuery(['currentIncident'], getQueryFn);

  if (specializationIsLoading || isLoading) {
    return <Loader />;
  }
  const ciSpec = specializations.find((spec) => spec.role_abbreviation === 'CI');
  const contrastTextColor = theme.palette.getContrastText(ciSpec.color);
  return (
    <>
      <Button
        fullWidth
        sx={{ height: '100%' }}
        variant="contained"
        icon={LoopIcon}
        bgColor={ciSpec.color}
        textColor={contrastTextColor}
        iconColor={contrastTextColor}
        size="large"
        onClick={() => history.push(`/user/intervention/${data.incident_id}/new_ic`)}
      >
        {`${t('CHANGE_IC')} (${t(data.rank_abbreviation)} ${data.lastname} ${data.firstname}) `}
      </Button>
    </>
  );
}
