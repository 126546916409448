import React from 'react';
import Typography from '@mui/material/Typography';
import { get } from 'src/api/api';
import { useQuery } from 'react-query';
import Paper from '@mui/material/Paper';
import { DateUtiles } from 'src/services/dateUtiles';

import Grid from '@mui/material/Grid';
import { useTranslation } from 'react-i18next';
import Table from '@mui/material/Table';
import Box from '@mui/material/Box';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { AuthenticationService } from 'src/services/auth';
import IconButton from '@mui/material/IconButton';
import ModeEditOutlineIcon from '@mui/icons-material/ModeEditOutline';

export default function WeeklyCalendarView({ functionDetails, data }) {
  const isIC = AuthenticationService.hasRole('INCIDENT_COMMANDER');
  let { url } = useRouteMatch();

  const { t } = useTranslation();

  const history = useHistory();

  const handleClick = (event, member_id) => {
    // console.log(member_id);
    history.push(`/user/member/${member_id}`);
  };

  return (
    <Box sx={{ mb: 2 }}>
      <Typography variant="h6" component="div">
        {functionDetails.function_name}
      </Typography>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Grid item xs={12}>
            <TableContainer component={Paper}>
              <Table aria-label="simple table">
                {/* <TableHead>
                  <TableRow>
                    <TableCell sx={{ minWidth: 5, width: 5, p: 0 }}></TableCell>
                    <TableCell>{t('FROM')}</TableCell>
                    <TableCell>{t('TO')}</TableCell>
                    <TableCell>&nbsp;</TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead> */}
                <TableBody>
                  {data.map((availability) => {
                    const today = new Date();
                    const startDate = new Date(availability.start);
                    const endDate = new Date(availability.end);
                    const isCurrentRecord = today > startDate && today < endDate;
                    return (
                      <TableRow
                        key={availability.id}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 }, p: '4px', backgroundColor: isCurrentRecord ? availability.color : null }}
                      >
                        <TableCell sx={{ minWidth: 5, width: 5, p: 0, backgroundColor: availability.color }}></TableCell>
                        <TableCell sx={{ p: '4px' }}>{`${t(DateUtiles.getDayName(startDate))} ${DateUtiles.toShortDateTime(availability.start)}`}</TableCell>
                        <TableCell sx={{ p: '4px' }}>{`${t(DateUtiles.getDayName(endDate))} ${DateUtiles.toShortDateTime(availability.end)}`}</TableCell>
                        <TableCell sx={{ p: '4px' }} component="th" scope="row">
                          {availability.member_id === null ? (
                            <b>{`${t('MISSING_REPLACEMENT')}`}</b>
                          ) : (
                            `${t('RANK_ABBR.' + availability.rank_abbreviation)} ${availability.firstname ?? ''} ${availability.lastname ?? ''}`
                          )}
                        </TableCell>
                        {isIC ? (
                          <TableCell sx={{ p: '4px' }} component="th" scope="row">
                            <IconButton color="primary" onClick={(event) => history.push(`${url}/assignation/${availability.id}`)}>
                              <ModeEditOutlineIcon />
                            </IconButton>
                          </TableCell>
                        ) : null}
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}
