import * as React from 'react';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import LocalFireDepartmentIcon from '@mui/icons-material/LocalFireDepartment';
import AppsIcon from '@mui/icons-material/Apps';
import EventIcon from '@mui/icons-material/Event';
import Paper from '@mui/material/Paper';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export default function BottomNav() {
  const { t } = useTranslation();

  const [value, setValue] = React.useState('ALARM');
  const ref = React.useRef(null);
  let { url } = useRouteMatch();
  const history = useHistory();
  return (
    <Box sx={{ pb: 7 }} ref={ref}>
      <CssBaseline />

      <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }} elevation={3}>
        <BottomNavigation
          showLabels
          value={value}
          onChange={(event, newValue) => {
            setValue(newValue);
            switch (newValue) {
              case 'CALENDAR':
                history.push(`${url}/calendar`);
                break;
              case 'ALARM':
                history.push(`${url}`);
                break;
              case 'OTHERS':
                history.push(`${url}/apps`);
                break;
              default:
                break;
            }
          }}
        >
          <BottomNavigationAction label={t('CALENDAR')} icon={<EventIcon />} value={'CALENDAR'} />

          <BottomNavigationAction label={t('ALARM')} icon={<LocalFireDepartmentIcon />} value={'ALARM'} />
          <BottomNavigationAction label={t('OTHERS')} icon={<AppsIcon />} value={'OTHERS'} />
        </BottomNavigation>
      </Paper>
    </Box>
  );
}
