import React from 'react';
import './board-footer.scss';
import logo from 'src/images/Alarmo_simple_full.png';
export default function BoardFooter() {
  return (
    <div id="board-footer">
      <img src={logo} width="125" height="42" alt="Logo" /> <span id="board-footer-url">www.alarmo.ch</span>
    </div>
  );
}
