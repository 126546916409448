import React from 'react';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import HandymanIcon from '@mui/icons-material/Handyman';
import { useUIContext } from '../../contexts/ui/ui.context';
import { NavLink, useRouteMatch } from 'react-router-dom';
import { Home } from '@mui/icons-material';
import MapIcon from '@mui/icons-material/Map';
import LayersIcon from '@mui/icons-material/Layers';
import { useTranslation } from 'react-i18next';
import DashboardIcon from '@mui/icons-material/Dashboard';
import GroupsIcon from '@mui/icons-material/Groups';
import SettingsIcon from '@mui/icons-material/Settings';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import ContactPhoneIcon from '@mui/icons-material/ContactPhone';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import ListItemButton from '@mui/material/ListItemButton';
import Collapse from '@mui/material/Collapse';
import LinkIcon from '@mui/icons-material/Link';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import WarningIcon from '@mui/icons-material/Warning';

const drawerWidth = 240;

export default function Sidebar(props) {
  const { t } = useTranslation();

  const [openPhoneBook, setOpenPhoneBook] = React.useState(false);
  const [openStaff, setOpenStaff] = React.useState(false);
  const [openFunction, setOpenFunction] = React.useState(false);
  const [openBoard, setOpenBoard] = React.useState(false);
  const [openMap, setOpenMap] = React.useState(false);
  const [openVehicle, setOpenVehicle] = React.useState(false);
  const [openIncident, setOpenIncident] = React.useState(false);

  const { isMenuOpen, toggleMenu } = useUIContext();
  const { window } = props;
  let { url } = useRouteMatch();

  const drawer = (
    <>
      <Toolbar />
      <Box sx={{ overflow: 'auto' }}>
        <List>
          <NavLink exact to={`${url}`} className="link">
            <ListItem button>
              <ListItemIcon>
                <Home />
              </ListItemIcon>
              <ListItemText primary={t('HOME')} />
            </ListItem>
          </NavLink>

          {/* HR */}
          <ListItemButton onClick={() => setOpenStaff(!openStaff)}>
            <ListItemIcon>
              <GroupsIcon />
            </ListItemIcon>
            <ListItemText primary={t('STAFF')} />
            {openStaff ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          <Collapse in={openStaff} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <NavLink to={`${url}/users`} className="link">
                <ListItemButton sx={{ pl: 4 }}>
                  <ListItemText primary={t('MEMBERS')} />
                </ListItemButton>
              </NavLink>

              <NavLink to={`${url}/sections`} className="link">
                <ListItemButton sx={{ pl: 4 }}>
                  <ListItemText primary={t('SECTIONS')} />
                </ListItemButton>
              </NavLink>

              <NavLink to={`${url}/group-types`} className="link">
                <ListItemButton sx={{ pl: 4 }}>
                  <ListItemText primary={t('GROUP_TYPES')} />
                </ListItemButton>
              </NavLink>

              <NavLink to={`${url}/groups`} className="link">
                <ListItemButton sx={{ pl: 4 }}>
                  <ListItemText primary={t('GROUPS')} />
                </ListItemButton>
              </NavLink>
            </List>
          </Collapse>

          <ListItemButton onClick={() => setOpenVehicle(!openVehicle)}>
            <ListItemIcon>
              <LocalShippingIcon />
            </ListItemIcon>
            <ListItemText primary={t('VEHICLES_AND_MATERIALS')} />
            {openVehicle ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          <Collapse in={openVehicle} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <NavLink to={`${url}/vehicles`} className="link">
                <ListItemButton sx={{ pl: 4 }}>
                  <ListItemText primary={t('VEHICLES')} />
                </ListItemButton>
              </NavLink>

              <NavLink to={`${url}/materials`} className="link">
                <ListItemButton sx={{ pl: 4 }}>
                  <ListItemText primary={t('MATERIALS')} />
                </ListItemButton>
              </NavLink>
            </List>
          </Collapse>

          {/* INCIDENT */}
          <ListItemButton onClick={() => setOpenIncident(!openIncident)}>
            <ListItemIcon>
              <WarningIcon />
            </ListItemIcon>
            <ListItemText primary={t('INCIDENTS')} />
            {openIncident ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          <Collapse in={openIncident} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <NavLink to={`${url}/interventions-list`} className="link">
                <ListItemButton sx={{ pl: 4 }}>
                  <ListItemText primary={t('INCIDENTS_LIST')} />
                </ListItemButton>
              </NavLink>
              <NavLink to={`${url}/intervention-log-presets`} className="link">
                <ListItemButton sx={{ pl: 4 }}>
                  <ListItemText primary={t('INTERVENTION_LOG')} />
                </ListItemButton>
              </NavLink>
              <NavLink to={`${url}/intervention-log-categories`} className="link">
                <ListItemButton sx={{ pl: 4 }}>
                  <ListItemText primary={t('INTERVENTION_LOG_CATEGORIES')} />
                </ListItemButton>
              </NavLink>
            </List>
          </Collapse>

          <NavLink to={`${url}/links`} className="link">
            <ListItem button>
              <ListItemIcon>
                <LinkIcon />
              </ListItemIcon>
              <ListItemText primary={t('LINKS')} />
            </ListItem>
          </NavLink>
          {/* </List>
        <Divider />
        <List> */}

          {/* Function */}
          <ListItemButton onClick={() => setOpenFunction(!openFunction)}>
            <ListItemIcon>
              <HandymanIcon />
            </ListItemIcon>
            <ListItemText primary={t('SPECIALIZATIONS')} />
            {openFunction ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          <Collapse in={openFunction} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <NavLink to={`${url}/specializations`} className="link">
                <ListItemButton sx={{ pl: 4 }}>
                  <ListItemText primary={t('SPECIALIZATIONS')} />
                </ListItemButton>
              </NavLink>

              <NavLink to={`${url}/functions`} className="link">
                <ListItemButton sx={{ pl: 4 }}>
                  <ListItemText primary={t('FUNCTIONS')} />
                </ListItemButton>
              </NavLink>

              <NavLink to={`${url}/planning`} className="link">
                <ListItemButton sx={{ pl: 4 }}>
                  <ListItemText primary={t('ON_DUTY')} />
                </ListItemButton>
              </NavLink>
            </List>
          </Collapse>

          <NavLink to={`${url}/alarm_types`} className="link">
            <ListItem button>
              <ListItemIcon>
                <FormatListBulletedIcon />
              </ListItemIcon>
              <ListItemText primary={t('ALARM_TYPE')} />
            </ListItem>
          </NavLink>

          {/* Map */}
          <ListItemButton onClick={() => setOpenMap(!openMap)}>
            <ListItemIcon>
              <MapIcon />
            </ListItemIcon>
            <ListItemText primary={t('MAPS')} />
            {openMap ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          <Collapse in={openMap} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <NavLink to={`${url}/map_layers`} className="link">
                <ListItemButton sx={{ pl: 4 }}>
                  <ListItemText primary={t('MAP_LAYERS')} />
                </ListItemButton>
              </NavLink>

              <NavLink to={`${url}/maps`} className="link">
                <ListItemButton sx={{ pl: 4 }}>
                  <ListItemText primary={t('MAPS')} />
                </ListItemButton>
              </NavLink>

              <NavLink to={`${url}/map_files`} className="link">
                <ListItemButton sx={{ pl: 4 }}>
                  <ListItemText primary={t('FILES')} />
                </ListItemButton>
              </NavLink>
            </List>
          </Collapse>

          {/* Phone Book */}
          <ListItemButton onClick={() => setOpenPhoneBook(!openPhoneBook)}>
            <ListItemIcon>
              <ContactPhoneIcon />
            </ListItemIcon>
            <ListItemText primary={t('PHONE_BOOK')} />
            {openPhoneBook ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          <Collapse in={openPhoneBook} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <NavLink to={`${url}/directory_groups`} className="link">
                <ListItemButton sx={{ pl: 4 }}>
                  <ListItemText primary={t('PHONE_BOOK_GROUPS')} />
                </ListItemButton>
              </NavLink>

              <NavLink to={`${url}/directory`} className="link">
                <ListItemButton sx={{ pl: 4 }}>
                  <ListItemText primary={t('PHONE_BOOKS')} />
                </ListItemButton>
              </NavLink>

              <NavLink to={`${url}/contacts`} className="link">
                <ListItemButton sx={{ pl: 4 }}>
                  <ListItemText primary={t('CONTACTS')} />
                </ListItemButton>
              </NavLink>
            </List>
          </Collapse>

          {/* Board */}
          <ListItemButton onClick={() => setOpenBoard(!openBoard)}>
            <ListItemIcon>
              <DashboardIcon />
            </ListItemIcon>
            <ListItemText primary={t('DISPLAY')} />
            {openBoard ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          <Collapse in={openBoard} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <NavLink to={`${url}/display_boards`} className="link">
                <ListItemButton sx={{ pl: 4 }}>
                  <ListItemText primary={t('DISPLAY')} />
                </ListItemButton>
              </NavLink>

              <NavLink to={`${url}/display_shifts`} className="link">
                <ListItemButton sx={{ pl: 4 }}>
                  <ListItemText primary={t('SHIFT')} />
                </ListItemButton>
              </NavLink>
            </List>
          </Collapse>

          <NavLink to={`${url}/parameters`} className="link">
            <ListItem button>
              <ListItemIcon>
                <SettingsIcon />
              </ListItemIcon>
              <ListItemText primary={t('PARAMETERS')} />
            </ListItem>
          </NavLink>
        </List>
      </Box>
    </>
  );

  const container = window !== undefined ? () => window().document.body : undefined;
  return (
    <>
      <Drawer
        container={container}
        variant="temporary"
        open={isMenuOpen}
        onClose={() => toggleMenu()}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        sx={{
          display: { xs: 'block', sm: 'none' },
          '& .MuiDrawer-paper': {
            boxSizing: 'border-box',
            width: drawerWidth,
          },
        }}
      >
        {drawer}
      </Drawer>
      <Drawer
        variant="permanent"
        sx={{
          display: { xs: 'none', sm: 'block' },
          width: drawerWidth,
          flexShrink: 0,
          [`& .MuiDrawer-paper`]: {
            width: drawerWidth,
            boxSizing: 'border-box',
          },
        }}
        open
      >
        {drawer}
      </Drawer>
    </>
  );
}
