import React from 'react';
import { useQuery } from 'react-query';
import { useHistory } from 'react-router-dom';
import { DataGrid } from '@mui/x-data-grid';
import { getAllData } from 'src/api/api';
import { useTranslation } from 'react-i18next';
import Loader from 'src/shared/defaultLoader';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import AddIcon from '@mui/icons-material/Add';

export default function GenericList({ objectName, columns, rowId, detailLink }) {
  const { t } = useTranslation();
  const history = useHistory();
  const query = () => getAllData(objectName);
  const { data, error, isLoading, isError } = useQuery(objectName, query);

  if (isLoading) {
    return <Loader />;
  }
  if (isError) {
    return <span>Error : {error.message}</span>;
  }

  let columnVisibilityModel = {
    id: false,
  };
  columnVisibilityModel[rowId] = false;
  columnVisibilityModel[`${objectName}_id`] = false;
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <Box sx={{ mb: 1 }}>
        <Button
          size="small"
          type="submit"
          variant="contained"
          startIcon={<AddIcon />}
          onClick={() => {
            let url = `${objectName}/0`;
            if (detailLink) {
              url = `${detailLink}/0`;
            }
            history.push(url);
          }}
        >
          {t('NEW')}
        </Button>
      </Box>

      <DataGrid
        autoHeight
        initialState={{
          columns: {
            columnVisibilityModel: columnVisibilityModel,
          },
        }}
        getRowId={(row) => {
          let idName = `${objectName}_id`;
          if (rowId) {
            idName = rowId;
          }
          return row[idName];
        }}
        rows={data}
        columns={columns}
        pageSize={100}
        rowsPerPageOptions={[100]}
        onCellClick={(cell) => {
          let url = `${objectName}/${cell.id}`;
          if (detailLink) {
            url = `${detailLink}/${cell.id}`;
          }
          history.push(url);
        }}
        getRowClassName={(params) => {
          if (params.row.is_disabled) {
            return 'disabled-line';
          }
        }}
      />
    </Box>
  );
}
