import create from 'zustand/vanilla';

const store = create((set) => {
  const logEntry = null;

  const setLogEntry = (data) => {
    set({ logEntry: data });
  };

  return {
    logEntry: logEntry,
    setLogEntry: setLogEntry,
  };
});

export default store;
