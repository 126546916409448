import React from 'react';
import Button from '../../components/button/button';
import StarRateIcon from '@mui/icons-material/StarRate';
import { useTranslation } from 'react-i18next';
import { getAllData } from 'src/api/api';
import Loader from 'src/shared/defaultLoader';
import { useQuery, useQueryClient } from 'react-query';
import AlertDialog from 'src/shared/sharedModal';
import { put, get } from 'src/api/api';
import { AuthenticationService } from 'src/services/auth';
import { useTheme } from '@mui/material/styles';
import { createData } from 'src/api/api';
export default function IAmICButton() {
  const theme = useTheme();

  const { t } = useTranslation();
  const [open, setOpen] = React.useState(false);
  const queryClient = useQueryClient();

  const getSpecializationQueryFn = async () => await getAllData('specialization');
  const { data: specializations, isLoading: specializationIsLoading } = useQuery('specialization', getSpecializationQueryFn);

  const getQueryFn = async ({ queryKey }) => await get('incident/currentIncident');
  const { data, isLoading } = useQuery(['currentIncident'], getQueryFn);

  if (specializationIsLoading || isLoading) {
    return <Loader />;
  }
  const currentUser = AuthenticationService.getCurrentUser();

  const ciSpec = specializations.find((spec) => spec.role_abbreviation === 'CI');
  const contrastTextColor = theme.palette.getContrastText(ciSpec.color);

  const buttonClickHandler = async () => {
    setOpen(false);
    await put(`incident/${data.incident_id}/incidentCommander`, currentUser);
    queryClient.invalidateQueries('currentUserStatus');
    await createData('incident-logs', {
      entry_type: 'AUTO',
      subject: '',
      comment: 'NEW_IC',
      incident_id: data.incident_id,
      event_date: Date(),
    });
    // refetch();
  };

  return (
    <>
      <Button icon={StarRateIcon} bgColor={ciSpec.color} textColor={contrastTextColor} iconColor={contrastTextColor} onClick={() => setOpen(true)}>
        {t('I_AM_INCIDENT_COMMANDER')}
      </Button>

      <AlertDialog
        open={open}
        setOpen={setOpen}
        Message={t('CONFIRM_I_AM_CI')}
        onConfirm={() => buttonClickHandler()}
        AcceptBtn={t('YES')}
        CancelBtn={t('CANCEL')}
      />
    </>
  );
}
