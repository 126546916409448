import React, { useState } from 'react';
import Button from '../../button/button';
import TaxiAlertIcon from '@mui/icons-material/TaxiAlert';
import { post } from 'src/api/api';
// import useStore from '../../../user.store';
import { useQuery, useQueryClient } from 'react-query';
import { get, deleteData } from 'src/api/api';
import Loader from 'src/shared/defaultLoader';
import { Colors } from 'src/styles/variables';
import { useTranslation } from 'react-i18next';
import AlertDialog from 'src/shared/sharedModal';
import { useHistory } from 'react-router-dom';

export default function AlarmButton() {
  const history = useHistory();
  const { t } = useTranslation();
  const [currentStatus, setCurrentStatus] = useState();
  const [open, setOpen] = useState(false);

  const queryClient = useQueryClient();

  const getQueryFn = async ({ queryKey }) => await get('user/currentUserStatus');
  const { isLoading } = useQuery(['currentUserStatus'], getQueryFn, {
    onSuccess: (data) => {
      setCurrentStatus(data);
    },
  });

  const alarmClickHandler = async () => {
    if (!currentStatus?.isInAlarm) {
      const res = await post('alarm/receipt');
      if (Array.isArray(res)) {
        if (res.length > 1) {
          history.push(`/user/open-interventions`);
        }
      }
    } else {
      await deleteData('alarm/receipt');
    }
    currentStatus.isInAlarm = !currentStatus?.isInAlarm;
    queryClient.setQueryData(['currentUserStatus'], currentStatus);
    queryClient.invalidateQueries('workForce');
    setCurrentStatus(currentStatus);
  };

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      <Button
        icon={TaxiAlertIcon}
        iconColor={currentStatus?.isInAlarm ? null : Colors.ALARM.darker}
        // textColor={currentStatus?.isInAlarm ? Colors.ALARM.darker : null}
        // bgColor={currentStatus?.isInAlarm ? Colors.ALARM.light : null}
        // textSize={24}
        // onClick={() => alarmClickHandler()}
        onClick={() => {
          if (currentStatus?.isInAlarm) {
            setOpen(true);
          } else {
            alarmClickHandler();
          }
        }}
      >
        {!currentStatus?.isInAlarm ? t('ONGOIN_ALARM') : t('LEAVE_ALARM')}
      </Button>
      <AlertDialog
        open={open}
        setOpen={setOpen}
        Message={t('CONFIRM_QUITE_ONGOIN_ALARM')}
        onConfirm={() => alarmClickHandler()}
        AcceptBtn={t('YES')}
        CancelBtn={t('CANCEL')}
      />
    </>
  );
}
