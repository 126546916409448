import React from 'react';
import GenericCRUD from 'src/shared/genericCRUD';
import { useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useQuery } from 'react-query';
import { getData } from 'src/api/api';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import { useTranslation } from 'react-i18next';
import FormControl from '@mui/material/FormControl';
import Loader from 'src/shared/defaultLoader';

export default function Rank() {
  const { register, handleSubmit } = useForm();
  const { t } = useTranslation();
  let { id } = useParams();
  const getQueryFn = async ({ queryKey }) => await getData('rank', { queryKey });

  const { data, error, isLoading, isError } = useQuery(['rank', { id }], getQueryFn);

  if (isLoading) {
    return <Loader />;
  }
  if (isError) {
    return <span>Error : {error.message}</span>;
  }

  return (
    <GenericCRUD objectName={'rank'} handleSubmit={handleSubmit} data={data}>
      <Grid item xs={12} sm={4}>
        <FormControl variant="standard" fullWidth={true}>
          <TextField
            {...register('rank_name')}
            required
            autoComplete="off"
            id="rank_name"
            name="rank_name"
            label={t('RANK')}
            fullWidth
            variant="standard"
            defaultValue={data.rank_name}
          />
        </FormControl>
      </Grid>

      <Grid item xs={12} sm={4}>
        <FormControl variant="standard" fullWidth={true}>
          <TextField
            {...register('rank_abbreviation')}
            required
            id="rank_abbreviation"
            name="rank_abbreviation"
            label={t('RANK_ABBREVIATION')}
            fullWidth
            autoComplete="family-name"
            variant="standard"
            defaultValue={data.rank_abbreviation}
          />
        </FormControl>
      </Grid>

      <Grid item xs={12} sm={4}>
        <FormControl variant="standard" fullWidth={true}>
          <TextField
            {...register('rank_order')}
            required
            id="rank_order"
            name="rank_order"
            label={t('ORDER_RANK')}
            fullWidth
            autoComplete="family-name"
            variant="standard"
            defaultValue={data.rank_order}
          />
        </FormControl>
      </Grid>
    </GenericCRUD>
  );
}
