import React from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { post, get } from 'src/api/api';
import { Colors } from 'src/styles/variables';
import { useTranslation } from 'react-i18next';
import Grid from '@mui/material/Grid';
import Button from '../../components/button/button';
import MarkEmailUnreadIcon from '@mui/icons-material/MarkEmailUnread';
import Loader from 'src/shared/defaultLoader';

export default function IncidentNotification() {
  const { t } = useTranslation();

  const queryClient = useQueryClient();
  const userParam = queryClient.getQueryData(['params/currentUser']);

  const getQueryFn = async () => await get('alarm/notifications');
  const { data: notifications, isLoading } = useQuery(['alarm/notifications'], getQueryFn);

  const clickHandler = async (type) => {
    await post('alarm/notification/' + type);
    queryClient.invalidateQueries('alarm/notifications');
  };

  if (isLoading) {
    return <Loader />;
  }
  // console.log(notifications);

  const personalNotification = notifications.find((e) => e.mail_type === 'INCIDENT_NOTIFICATION_PERSONAL');
  const professionalNotification = notifications.find((e) => e.mail_type === 'INCIDENT_NOTIFICATION_PROFESSIONAL');

  const personalButtonVisible = userParam.personal_notification_param && userParam.personal_notification_param !== '' && !personalNotification;
  const professionalButtonVisible = userParam.professional_notification_param && userParam.professional_notification_param !== '' && !professionalNotification;

  return (
    <>
      {personalButtonVisible ? (
        <Grid item xs={professionalButtonVisible ? 6 : 12}>
          <Button
            icon={MarkEmailUnreadIcon}
            iconColor={Colors.button_default_icon}
            bgColor={Colors.button_default}
            onClick={() => {
              clickHandler('personal');
            }}
          >
            {t('INCIDENT_NOTIFICATION_PERSONAL_BUTTON')}
          </Button>
        </Grid>
      ) : null}
      {professionalButtonVisible ? (
        <Grid item xs={personalButtonVisible ? 6 : 12}>
          <Button
            icon={MarkEmailUnreadIcon}
            iconColor={Colors.button_default_icon}
            bgColor={Colors.button_default}
            onClick={() => {
              clickHandler('professional');
            }}
          >
            {t('INCIDENT_NOTIFICATION_PROFESSIONAL_BUTTON')}
          </Button>
        </Grid>
      ) : null}
    </>
  );
}
