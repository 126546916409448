import React, { useState } from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import { useUserContext } from '../../contexts/user/user.context';
import { useTranslation } from 'react-i18next';
import { get } from 'src/api/api';
import { useQuery } from 'react-query';
import Loader from 'src/shared/defaultLoader';

export default function CurrentUser() {
  const { t } = useTranslation();
  const [currentStatus, setCurrentStatus] = useState();

  const getQueryFn = async ({ queryKey }) => await get('user/currentUserStatus');
  const { isLoading } = useQuery(['currentUserStatus'], getQueryFn, {
    onSuccess: (data) => {
      setCurrentStatus(data);
    },
  });

  const { currentUser, setUser } = useUserContext();

  if (isLoading) {
    return <Loader />;
  }

  return (
    <Box
      sx={
        {
          // bgcolor: '#c8fac5',
          // boxShadow: 0,
          // borderRadius: 1,
          // pb: 1,
        }
      }
    >
      <Box
        sx={{
          color: 'text.secondary',
          alignContent: 'center',
          justifyContent: 'center',
          display: 'flex',
          textAlign: 'center',
          flexDirection: 'column',
          paddingBottom: '15',
        }}
      >
        <Box>
          <Typography variant="subtitle2" gutterBottom>
            {`${t('RANK_ABBR.' + currentUser.rank_abbreviation)} ${currentUser.firstname} ${currentUser.lastname}`}
          </Typography>
        </Box>
      </Box>
      <Box sx={{ m: 'auto', width: '70%' }}>
        <Divider />
      </Box>
      {/* <Box sx={{ color: 'text.secondary', alignContent: 'center', justifyContent: 'center', display: 'flex', textAlign: 'center', flexDirection: 'column' }}> */}
      {/* <Box>
          <Typography variant="caption" gutterBottom>
            {currentUser.section_name}
          </Typography>
        </Box>
        <Box>
          <Typography variant="caption" gutterBottom>
            {t('CURRENT_STATUS')}&nbsp;
          </Typography>
          <Typography variant="caption" gutterBottom>
            {currentStatus?.isInAlarm ? t('ONGOIN_ALARM') : t(currentStatus?.availability)}
          </Typography>
        </Box> */}
      {/* <Box>
          <Typography variant="caption" gutterBottom>
            Officier de service
          </Typography>
        </Box> */}
      {/* </Box> */}
    </Box>
  );
}
