import React from 'react';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton';
import { DateUtiles } from 'src/services/dateUtiles';
import Running from 'src/icons/running';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import MapIcon from '@mui/icons-material/Map';
import StarsIcon from '@mui/icons-material/Stars';
import Stack from '@mui/material/Stack';
import IncidentAvatar from 'src/shared/incidentAvatar';
import { useHistory } from 'react-router-dom';
import { DurationUtils } from 'src/services/durations';

export default function IncidentsCard({ incident, withClick, onClick }) {
  const history = useHistory();
  const module = history.location.pathname.split('/')[1];
  let dateAndDurationSubHeader = DateUtiles.toDateTime(incident.creation_date);
  if (incident.end_date) {
    dateAndDurationSubHeader += ` - ${DateUtiles.toDateTime(incident.end_date)}`;
    dateAndDurationSubHeader += ` (${DurationUtils.toReadableDurationBetweenTwoDate(incident.creation_date, incident?.end_date ?? '')})`; //
  } else {
    dateAndDurationSubHeader += ` (${DurationUtils.toReadableDurationBetweenTwoDate(incident.creation_date, Date.now())})`; //
  }

  return (
    <Card
      key={incident.incident_id}
      sx={{ mb: 2 }}
      onClick={() => {
        if (withClick) {
          if (onClick) {
            onClick(incident);
          } else {
            history.push(`/${module}/incident/${incident.incident_id}`);
          }
        }
      }}
    >
      <CardHeader
        sx={{ pb: 1 }}
        avatar={<IncidentAvatar incident={incident} />}
        action={
          !withClick ? (
            <IconButton aria-label="settings" onClick={() => history.push(`/${module}/incident/${incident.incident_id}/edit`)}>
              <EditIcon />
            </IconButton>
          ) : null
        }
        title={incident.incident_description}
        subheader={`${dateAndDurationSubHeader}`}
      />
      <CardContent sx={{ p: 1 }}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Stack direction="row" spacing={2}>
              <Paper elevation={0}>
                <MapIcon />
              </Paper>
              <Paper elevation={0}>{incident.incident_address}</Paper>
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <Stack direction="row" spacing={2}>
              <Paper elevation={0}>
                <StarsIcon />
              </Paper>
              <Paper elevation={0}>
                {incident.rank_abbreviation} {incident.lastname} {incident.firstname}
              </Paper>
            </Stack>
          </Grid>

          <Grid item xs={12}>
            <Stack direction="row" spacing={2}>
              <Paper elevation={0}>
                <Running />
              </Paper>
              <Paper elevation={0}>{incident.nb_person}</Paper>
              <Paper elevation={0}>
                <LocalShippingIcon />
              </Paper>
              <Paper elevation={0}>{incident.nb_vhc}</Paper>
            </Stack>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
