import React from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { getAllData } from 'src/api/api';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Loader from 'src/shared/defaultLoader';
import { useHistory } from 'react-router-dom';
import Topbar from '../../components/topbar/topbar';

const BigButton = styled(Button)(() => ({
  // transform: 'scale(2)',
  width: '90%',
  height: '100px',
}));

export default function Tableau() {
  const { t } = useTranslation();
  const history = useHistory();

  const query = () => getAllData('display_board');
  const { data, error, isLoading, isError } = useQuery('display_board', query);

  if (isLoading) {
    return <Loader />;
  }
  return (
    <>
      <Topbar />
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Box
            sx={{
              boxShadow: 0,
              borderRadius: 1,
              p: 1,
            }}
          >
            <Box sx={{ color: 'text.secondary', alignContent: 'center', justifyContent: 'center', display: 'flex', flexDirection: 'row' }}>
              <Typography variant="h6" gutterBottom>
                {t('AVAILABLE_BOARD')}
              </Typography>
            </Box>
          </Box>
        </Grid>

        <Grid item xs={12}>
          {data.map((board) => (
            <Box key={board.display_board_id} sx={{ p: 1, alignContent: 'center', justifyContent: 'center', display: 'flex' }}>
              <BigButton variant="contained" size="large" onClick={() => history.push(`display/board/${board.display_board_id}`)}>
                {board.board_name}
              </BigButton>
            </Box>
          ))}
        </Grid>
      </Grid>
    </>
  );
}
