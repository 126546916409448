import React, { useState } from 'react';
import Button from '@mui/material/Button';
import { post } from 'src/api/api';
import { useQuery, useQueryClient } from 'react-query';
import Grid from '@mui/material/Grid';

import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import AddAlertIcon from '@mui/icons-material/AddAlert';
import NotificationImportantIcon from '@mui/icons-material/NotificationImportant';
import ButtonGroup from '@mui/material/ButtonGroup';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Typography from '@mui/material/Typography';

import Toolbar from '@mui/material/Toolbar';
import Stack from '@mui/material/Stack';
import { useTranslation } from 'react-i18next';

import VehicleOnSiteIcon from 'src/icons/v_on_site';
import VehicleOnWayIcon from 'src/icons/v_on_way';
import VehicleEngagedIcon from 'src/icons/v_engaged';
import VehicleAvailableIcon from 'src/icons/v_available';
import VehicleNotAvailableIcon from 'src/icons/v_not_available';
import VehicleOutOfOrderIcon from 'src/icons/v_out_of_order';
import { get } from 'src/api/api';

export default function MaterialButton({ material, size = 'large' }) {
  const { t } = useTranslation();

  const [open, setOpen] = React.useState(false);

  const [currentStatus, setCurrentStatus] = useState();
  const getQueryFn = async ({ queryKey }) => await get('user/currentUserStatus');
  const { isLoading } = useQuery(['currentUserStatus'], getQueryFn, {
    onSuccess: (data) => {
      setCurrentStatus(data);
    },
  });

  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setOpen(open);
  };

  const queryClient = useQueryClient();

  const vehicleClickHandler = async (newStatus) => {
    await post(`material/${material.material_id}/currentIntervention`, { newStatus });
    queryClient.invalidateQueries('material/withState');
  };

  let Icon = EventAvailableIcon;
  let ExtraIcon = null;
  let color = 'vehicle_available';
  switch (material.material_status) {
    case 'ENGAGED':
      color = 'vehicle_engaged';
      ExtraIcon = VehicleEngagedIcon;
      break;
    case 'ON_SITE':
      color = 'vehicle_on_site';
      ExtraIcon = VehicleOnSiteIcon;
      break;
    case 'ON_WAY':
      color = 'vehicle_on_way';
      ExtraIcon = VehicleOnWayIcon;
      break;
    case 'OUT_OF_ORDER':
      color = 'vehicle_out_of_order';
      ExtraIcon = VehicleOutOfOrderIcon;
      break;

    case 'NOT_AVAILABLE':
      color = 'vehicle_not_available';
      ExtraIcon = VehicleNotAvailableIcon;
      break;

    default:
      color = 'neutral';
      //ExtraIcon = VehicleAvailableIcon;
      break;
  }

  const list = () => (
    <Box sx={{ width: 300, p: 1 }} role="presentation" onClick={toggleDrawer(false)} onKeyDown={toggleDrawer(false)}>
      <Toolbar />
      <Stack spacing={2}>
        <Box
          sx={{
            color: 'text.secondary',
            alignContent: 'center',
            justifyContent: 'center',
            display: 'flex',
            textAlign: 'center',
            flexDirection: 'column',
            paddingBottom: '0px',
            paddingTop: '10px',
          }}
        >
          <Box>
            <Typography variant="subtitle2" gutterBottom>
              {material.material_code} / {material.material_name}
            </Typography>
          </Box>
        </Box>
        {currentStatus?.isInAlarm ? (
          <>
            <Button
              fullWidth
              variant="contained"
              size="large"
              color={'vehicle_on_way'}
              onClick={() => {
                vehicleClickHandler('ON_WAY');
              }}
            >
              {t('ON_WAY')}
            </Button>
            <Button
              fullWidth
              variant="contained"
              size="large"
              color={'vehicle_on_site'}
              onClick={() => {
                vehicleClickHandler('ON_SITE');
              }}
            >
              {t('ON_SITE')}
            </Button>
            <Button
              fullWidth
              variant="contained"
              size="large"
              color={'vehicle_out_of_order'}
              onClick={() => {
                vehicleClickHandler('OUT_OF_ORDER');
              }}
            >
              {t('VEHICLE_OUT_OF_ORDER')}
            </Button>
          </>
        ) : null}
        <Button
          fullWidth
          variant="contained"
          size="large"
          color={'neutral'}
          onClick={() => {
            vehicleClickHandler('AVAILABLE');
          }}
        >
          {t('VEHICLE_AVAILABLE')}
        </Button>
        <Button
          fullWidth
          variant="contained"
          size="large"
          color={'vehicle_not_available'}
          onClick={() => {
            vehicleClickHandler('NOT_AVAILABLE');
          }}
        >
          {t('VEHICLE_NOT_AVAILABLE')}
        </Button>
      </Stack>
    </Box>
  );

  return (
    <Grid container spacing={1}>
      {ExtraIcon && size === 'large' ? (
        <Grid item xs={2}>
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
            <ExtraIcon />
          </Box>
        </Grid>
      ) : null}

      <Grid item xs={ExtraIcon && size === 'large' ? 10 : 12}>
        <ButtonGroup size={size} sx={{ height: '100%' }} color={color} fullWidth variant="contained" aria-label="split button">
          <Button
            sx={{ height: '100%', p: 0.5 }}
            variant="contained"
            // startIcon={<VtractLogo style={{ height: '40px', width: '46px', color: 'blue' }} />}
            size={size}
            color={color}
            onClick={toggleDrawer(true)}
          >
            {material.material_code} / {material.material_name}
          </Button>

          <Button color={color} size={size} sx={{ width: 20 }} onClick={toggleDrawer(true)}>
            <ArrowDropDownIcon />
          </Button>
        </ButtonGroup>

        <Drawer anchor={'right'} open={open} onClose={toggleDrawer(false)}>
          {list()}
        </Drawer>
      </Grid>
    </Grid>
  );
}
