import React from 'react';
import GenericList from 'src/shared/genericList';
import { useTranslation } from 'react-i18next';

export default function SectionsList() {
  const { t } = useTranslation();
  const columns = [
    { field: 'section_id', headerName: 'ID', hide: true },
    {
      field: 'section_name',
      headerName: t('SECTION'),
      width: 75,
      editable: true,
      flex: 1,
    },
    {
      field: 'section_order',
      headerName: t('SORT_ORDER'),
      width: 75,
      editable: true,
      flex: 1,
    },
  ];

  return <GenericList objectName={'section'} columns={columns} />;
}
