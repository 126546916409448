import React from 'react';
import GenericList from 'src/shared/genericList';
import { useTranslation } from 'react-i18next';

export default function RankSList() {
  const { t } = useTranslation();
  const columns = [
    { field: 'rank_id', headerName: 'ID', hide: true },
    {
      field: 'rank_abbreviation',
      headerName: t('RANK_ABBREVIATION'),
      width: 150,
      editable: true,
    },
    {
      field: 'rank_name',
      headerName: t('RANK'),
      width: 75,
      editable: true,
      flex: 1,
    },
  ];

  return <GenericList objectName={'rank'} columns={columns} />;
}
