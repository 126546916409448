import React from 'react';
import GenericCRUD from 'src/shared/genericCRUD';
import { useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useQuery } from 'react-query';
import { getData } from 'src/api/api';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import { useTranslation } from 'react-i18next';
import FormControl from '@mui/material/FormControl';
import Loader from 'src/shared/defaultLoader';
import ReactHookFormSelect from 'src/shared/reactHookFormSelect';
import MenuItem from '@mui/material/MenuItem';
import { INCIDENT_LOG_ITEM_TYPES } from 'src/constants/constants';
import { INCIDENT_LOG_ENTRY_TYPES } from 'src/constants/constants';
import InterventionLogPresetParentSelector from './interventionLogPresetParentSelector';
import InterventionLogCategorySelector from 'src/shared/interventionLogCategorySelector';

export default function InterventionLogPresetEdit() {
  const { register, watch, handleSubmit, control, setValue } = useForm();
  const { t } = useTranslation();
  let { id } = useParams();
  const getQueryFn = async ({ queryKey }) => await getData('intervention-log-preset', { queryKey });

  const [currentType, setCurrentType] = React.useState('ACTION');

  const inputRef = React.useRef();

  React.useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      if (name === 'item_type') {
        setCurrentType(value.item_type);
      }
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  const { data, error, isLoading, isError } = useQuery(['intervention-log-preset', { id }], getQueryFn, {
    onSuccess: (data) => {
      setValue('incident_log_preset_category_id', data?.incident_log_preset_category_id ?? null);
      if (data.is_editable) {
        data.is_editable = 'YES';
      } else {
        data.is_editable = 'NO';
      }
      console.log(data);
      setCurrentType(data.item_type);
    },
  });

  if (isLoading) {
    return <Loader />;
  }
  if (isError) {
    return <span>Error : {error.message}</span>;
  }

  return (
    <GenericCRUD objectName={'intervention-log-preset'} handleSubmit={handleSubmit} data={data}>
      <Grid item xs={12} sm={6}>
        <FormControl variant="standard" fullWidth={true}>
          <TextField
            {...register('name')}
            required
            autoComplete="off"
            id="name"
            name="name"
            label={t('NAME')}
            fullWidth
            variant="standard"
            defaultValue={data.name}
          />
        </FormControl>
      </Grid>

      <Grid item xs={12} sm={6}>
        <ReactHookFormSelect
          register={register}
          id="item_type"
          name="item_type"
          label={t('ITEM_TYPE')}
          control={control}
          defaultValue={data.item_type}
          variant="standard"
          disabled={id !== '0'}
        >
          {INCIDENT_LOG_ITEM_TYPES.map((type) => {
            return (
              <MenuItem key={type.code} value={type.code}>
                {t(type.name)}
              </MenuItem>
            );
          })}
        </ReactHookFormSelect>
      </Grid>

      <Grid item xs={12} sm={4}>
        <InterventionLogCategorySelector
          defaultId={data.incident_log_preset_category_id}
          label={t('CATEGORY_NAME')}
          onChange={(v) => {
            setValue('incident_log_preset_category_id', v?.incident_log_preset_category_id ?? null);
          }}
          inputRef={inputRef}
        />
      </Grid>

      <Grid item xs={12} sm={4}>
        <FormControl variant="standard" fullWidth={true}>
          <TextField
            {...register('color_code')}
            required
            id="color_code"
            name="color_code"
            label={t('COLOR')}
            type="color"
            fullWidth
            autoComplete="family-name"
            variant="standard"
            defaultValue={data?.color_code ?? '#f6f6f6'}
          />
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={4}>
        <FormControl variant="standard" fullWidth={true}>
          <TextField
            {...register('item_order')}
            required
            id="item_order"
            name="item_order"
            label={t('SORT_ORDER')}
            fullWidth
            autoComplete="family-name"
            variant="standard"
            defaultValue={data?.item_order ?? 0}
          />
        </FormControl>
      </Grid>

      <Grid item xs={12}>
        <InterventionLogPresetParentSelector
          register={register}
          id="parent_id"
          name="parent_id"
          control={control}
          defaultValue={data.parent_id}
          variant="standard"
        />
      </Grid>

      {currentType === 'ACTION' ? (
        <>
          <Grid item xs={6}>
            <ReactHookFormSelect
              register={register}
              id="entry_type"
              name="entry_type"
              label={t('INCIDENT_LOG_ENTRY_TYPE')}
              control={control}
              defaultValue={data.entry_type}
              variant="standard"
            >
              {INCIDENT_LOG_ENTRY_TYPES.map((type) => {
                return (
                  <MenuItem key={type.code} value={type.code}>
                    {t(type.name)}
                  </MenuItem>
                );
              })}
            </ReactHookFormSelect>
          </Grid>

          <Grid item xs={6}>
            <ReactHookFormSelect
              register={register}
              id="is_editable"
              name="is_editable"
              label={t('IS_EDITABLE')}
              control={control}
              defaultValue={data.is_editable}
              variant="standard"
            >
              {[
                { code: 'YES', name: 'YES' },
                { code: 'NO', name: 'NO' },
              ].map((type) => {
                return (
                  <MenuItem key={type.code} value={type.code}>
                    {t(type.name)}
                  </MenuItem>
                );
              })}
            </ReactHookFormSelect>
          </Grid>

          <Grid item xs={12}>
            <FormControl variant="standard" fullWidth={true}>
              <TextField
                {...register('subject')}
                //required={linkIsMandatory}
                autoComplete="off"
                id="subject"
                name="subject"
                label={t('INCIDENT_LOG_SUBJECT')}
                fullWidth
                variant="standard"
                defaultValue={data.subject}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <FormControl variant="standard" fullWidth={true}>
              <TextField
                {...register('comment')}
                autoComplete="off"
                id="comment"
                name="comment"
                label={t('COMMENT')}
                multiline
                rows={5}
                fullWidth
                variant="standard"
                defaultValue={data.comment}
              />
            </FormControl>
          </Grid>
        </>
      ) : null}
    </GenericCRUD>
  );
}
