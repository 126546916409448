import { createTheme } from '@mui/material/styles';
import { Themes } from 'src/styles/variables';

export default createTheme({
  palette: Themes.USER.palette,
  breakpoints: {
    values: {
      xs: 0,
      se: 400,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
});
