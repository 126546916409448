import { createContext, useContext, useState, useMemo } from 'react';

const DisplayContext = createContext(null);

export const useDisplayContext = () => useContext(DisplayContext);

export const DisplayContextProvider = ({ children }) => {
  const DisplayState = {
    assignations: [],
  };

  const [state, setstate] = useState(DisplayState);

  const value = useMemo(() => {
    return {
      ...state,
      setAssignation: (data) => {
        setstate({ ...state, assignations: data });
        // console.log( state);
      },
    };
  }, [state]);

  return <DisplayContext.Provider value={value}>{children}</DisplayContext.Provider>;
};
// export default UIContext;
