import React, { useState } from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { useTranslation } from 'react-i18next';
import { AuthenticationService } from 'src/services/auth';
import { Link as RouterLink, useHistory, Redirect } from 'react-router-dom';
import logo from 'src/images/Alarmo_simple_full.png';
import Alert from '@mui/material/Alert';
import Copyright from 'src/shared/copyright';
import MuiLink from '@mui/material/Link';
import i18n from 'src/i18n/i18n';

export default function Login() {
  const [loginError, setLoginError] = useState(false);
  const { t } = useTranslation();
  const history = useHistory();

  const currentToken = AuthenticationService.getDecodedToken();
  if (currentToken) {
    const redirect = processToken();
    if (redirect) {
      return redirect;
    }
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    const result = await AuthenticationService.login(data.get('login'), data.get('password'));
    if (result) {
      processLogin();
    } else {
      setLoginError(true);

      // setStatus('Invalid login or password');
    }
  };

  function processLogin() {
    const myDecodedToken = AuthenticationService.getDecodedToken();
    switch (myDecodedToken.type) {
      case 'ADMIN':
        history.push('/admin');
        break;
      case 'USER':
        history.push('/user');
        break;
      case 'DISPLAY':
        history.push('/display');
        break;
      default:
        break;
    }
  }

  function processToken() {
    const myDecodedToken = AuthenticationService.getDecodedToken();
    switch (myDecodedToken.type) {
      case 'ADMIN':
        return <Redirect push to="/admin" />;
      case 'USER':
        return <Redirect push to="/user" />;
      case 'DISPLAY':
        return <Redirect push to="/display" />;
      default:
        break;
    }
    return null;
  }

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Paper sx={{ marginTop: 2, p: 3 }}>
        <Box
          sx={{
            marginTop: 0,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Box sx={{ m: 1 }}>
            <img src={logo} width="250" height="84" alt="Logo" />
          </Box>
          <Typography component="h1" variant="h5">
            {t('SIGN_IN')}
          </Typography>
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
            <TextField margin="normal" required fullWidth id="login" label={t('LOGIN')} name="login" autoComplete="login" autoFocus />
            <TextField margin="normal" required fullWidth name="password" label={t('PASSWORD')} type="password" id="password" autoComplete="current-password" />
            {/* <FormControlLabel control={<Checkbox value="remember" color="primary" />} label={t('REMEMBER_ME')} /> */}
            {loginError ? <Alert severity="error">{t('LOGIN_ERROR')}</Alert> : null}
            <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
              {t('SIGN_IN')}
            </Button>
            <Grid container>
              <Grid item xs></Grid>
              <Grid item>
                <Link component={RouterLink} to="/forgot_password" variant="body2">
                  {t('FORGOT_PASSWORD')}
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>

        <Typography variant="body2" color="text.secondary" align="center" sx={{ mt: 4 }}>
          <MuiLink
            color="inherit"
            component="button"
            onClick={() => {
              i18n.changeLanguage('fr');
            }}
          >
            Fr
          </MuiLink>
          &nbsp;
          <MuiLink
            component="button"
            color="inherit"
            onClick={() => {
              i18n.changeLanguage('de');
            }}
          >
            De
          </MuiLink>
          &nbsp;
          <MuiLink
            color="inherit"
            component="button"
            onClick={() => {
              i18n.changeLanguage('en');
            }}
          >
            En
          </MuiLink>
        </Typography>

        <Copyright sx={{ mt: 1, mb: 4 }} />
      </Paper>
    </Container>
  );
}
