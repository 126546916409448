import React from 'react';
import { get } from 'src/api/api';
import { useQuery } from 'react-query';
import Loader from 'src/shared/defaultLoader';

import MemberList from '../../components/memberList/memberList';

export default function SpecializationWorkForce({ specId, noToggleButton }) {
  const getQueryFn = async () => await get(`specialization/${specId}/members`);
  const { data, isLoading } = useQuery(['specialization-members', specId], getQueryFn);
  if (isLoading) {
    return <Loader />;
  }

  return <MemberList data={data} noToggleButton forceAllView />;
}
