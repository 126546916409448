import React from 'react';
import { Wrapper, google } from '@googlemaps/react-wrapper';
import './map.scss';
import { useParams } from 'react-router-dom';
import { useUIContext } from '../../contexts/ui/ui.context';
import { get } from 'src/api/api';
import Loader from 'src/shared/defaultLoader';
import { useQuery, useQueryClient } from 'react-query';
import { useTranslation } from 'react-i18next';
import { B2_BASE_URL } from 'src/constants/constants';
import MapLayerSelection from './mapLayerSelection';

function MapV() {
  let { id } = useParams();
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const globalParam = queryClient.getQueryData(['params/global']);

  const { setAppBarTitle } = useUIContext();

  const getQueryFn = async () => await get(`maps/${id}/with-layer`);
  const { data, isLoading } = useQuery(['maps/with-layer', id], getQueryFn, {
    refetchOnWindowFocus: false,
    enabled: true,
    onSuccess: (data) => {
      setAppBarTitle(`${t('MAP')}: ${data.map_name}`);
    },
  });

  if (isLoading) {
    return <Loader />;
  }
  const center = { lat: parseFloat(data.map_param.center_lat), lng: parseFloat(data.map_param.center_long) };
  const zoom = 16;

  return (
    <React.Fragment>
      <Wrapper apiKey={globalParam.google_map_api}>
        <MyMapComponent center={center} zoom={zoom} google={google} data={data} />
      </Wrapper>
    </React.Fragment>
  );
}

function MyMapComponent({ center, zoom, google, data }) {
  const ref = React.useRef();
  const [myMap, setMyMap] = React.useState(null);
  const queryClient = useQueryClient();
  const globalParam = queryClient.getQueryData(['params/global']);

  React.useEffect(() => {
    const map = new window.google.maps.Map(ref.current, {
      center,
      zoom,
      mapTypeId: window.google.maps.MapTypeId.HYBRID,
      scaleControl: true,
      styles: [
        {
          featureType: 'poi',
          elementType: 'labels',
          stylers: [{ visibility: 'off' }],
        },
      ],
    });

    const opt = { minZoom: 12, maxZoom: 22 };
    map.setOptions(opt);

    data.layers.map((layer) => {
      if (layer.map_layer_url) {
        if (layer.map_layer_url.fileName !== '') {
          layer.kmlLayer = new window.google.maps.KmlLayer(`${globalParam.files_server || B2_BASE_URL}${layer.map_layer_url.fileName}`, {
            // Pour préserver la vue, sinon zoom sur KLM...
            preserveViewport: true,
            map: map,
          });

          //window.google.maps.event.addListener(layer.kmlLayer, 'click', function (kmlEvent) {});
        }
      }
    });

    let geolocationAvailable = false;
    if ('geolocation' in navigator) {
      geolocationAvailable = true;
    }
    if (geolocationAvailable) {
      navigator.geolocation.getCurrentPosition(function (position) {
        const pos = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        };

        const marker = new window.google.maps.Marker({
          position: pos,
          map: map,
        });

        map.setCenter(pos);
      });
    }
    setMyMap(map);
  }, []);

  return (
    <>
      <div ref={ref} id="map" />
      {myMap != null ? <MapLayerSelection layers={data.layers} map={myMap} /> : null}
    </>
  );
}

const Map = React.memo(MapV);
export default Map;
