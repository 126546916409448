import React from 'react';

import { useQuery } from 'react-query';
import { getData } from 'src/api/api';
import Loader from 'src/shared/defaultLoader';
import TableauBloc from '../tableau-bloc/tableau-bloc';
import { Utils } from 'src/services/utils';

export default function TableauGroup({ title, groupId, nameSuffix, namePrefix, datas, isPrimary, displayAvailability }) {
  const getQueryFn = async ({ queryKey }) => await getData('group', { queryKey });
  const { data: groupData, isLoading } = useQuery(['group', { id: groupId }], getQueryFn);

  if (isLoading) {
    return <Loader />;
  }

  // title = title || groupData.group_name;
  if (title === '') {
    title = groupData.group_name;
  }

  if (namePrefix) {
    title = namePrefix + ' ' + title;
  }

  if (nameSuffix) {
    title = title + ' ' + nameSuffix;
  }

  let groups = [];
  for (const key in datas) {
    if (Object.hasOwnProperty.call(datas, key)) {
      const element = datas[key];
      for (const member of element.members) {
        if (member.groups.find((e) => e === groupData.group_name)) {
          groups.push(member);
        }
      }
    }
  }

  if (groups.length === 0) return null;
  groups = Utils.sortByRankAndName(groups);

  const foundLeaderIdx = groups.findIndex((m) => m.member_id === groupData.group_leader_id);
  if (foundLeaderIdx > 0) {
    const leader = groups[foundLeaderIdx];
    groups.splice(foundLeaderIdx, 1);
    groups.unshift(leader);
  }

  return <TableauBloc title={title} displayAvailability={displayAvailability} blocData={groups} />;
}
