import React from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { updateData, createData, deleteData, getData, getAllData } from 'src/api/api';
import Loader from 'src/shared/defaultLoader';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import { useTranslation } from 'react-i18next';
import FormControl from '@mui/material/FormControl';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import AlertDialog from 'src/shared/sharedModal';
import ReactHookFormSelect from 'src/shared/reactHookFormSelect';
import MenuItem from '@mui/material/MenuItem';
import FormLabel from '@mui/material/FormLabel';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { Controller } from 'react-hook-form';
import useStoreGlobalFeedback from 'src/modules/shared/globalFeedback/globalFeedback.store.js';
import AddIcon from '@mui/icons-material/Add';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import { LANGUAGES, MEMBER_TYPE } from 'src/constants/constants';
import i18n from 'src/i18n/i18n';

export default function User() {
  const [open, setOpen] = React.useState(false);
  const { t } = useTranslation();
  let { id } = useParams();

  const setFeedback = useStoreGlobalFeedback((state) => state.setFeedback);

  const getQueryFn = async ({ queryKey }) => await getData('user', { queryKey });
  const { data, error, isLoading, isError } = useQuery(['user', { id }], getQueryFn);

  const createQueryFn = async (data) => await createData('user', data);
  const updateQueryFn = async ({ id, ...data }) => await updateData('user', { id, ...data });
  const deleteQueryFn = async ({ id, ...data }) => await deleteData('user', { id, ...data });

  const getRanksQueryFn = async () => await getAllData('rank');
  const { data: ranks, isLoading: ranksIsLoading } = useQuery('ranks', getRanksQueryFn);

  const getSectionsQueryFn = async () => await getAllData('section');
  const { data: sections, isLoading: sectionIsLoading } = useQuery('sections', getSectionsQueryFn);

  const getSpecializationQueryFn = async () => await getAllData('specialization');
  const { data: specializations, isLoading: specializationIsLoading } = useQuery('specialization', getSpecializationQueryFn);

  const getRolesQueryFn = async () => await getAllData('role');
  const { data: roles, isLoading: roleIsLoading } = useQuery('roles', getRolesQueryFn);

  const getGroupsQueryFn = async () => await getAllData('group');
  const { data: groups, isLoading: groupsIsLoading } = useQuery('groups', getGroupsQueryFn);

  const { register, handleSubmit, control, getValues, setValue } = useForm();
  const history = useHistory();

  const queryClient = useQueryClient();
  const { mutateAsync: mutateAsyncUpdate } = useMutation(updateQueryFn, {
    onSuccess: (data) => {
      queryClient.setQueryData(['user', { id: id }], data);
    },
  });
  const { mutateAsync: mutateAsyncCreate } = useMutation(createQueryFn, {
    onSuccess: (data) => {
      queryClient.setQueryData(['user', { id: id }], data);
      queryClient.setQueryData(['user', { id: '0' }], {});
    },
  });
  const { mutateAsync: mutateAsyncDelete } = useMutation(deleteQueryFn, {
    onSuccess: (data) => {
      queryClient.setQueryData(['user', { id: id }], {});
    },
  });

  async function deleteCurrentRecord() {
    try {
      await mutateAsyncDelete({ ...data, id });
      history.goBack();
    } catch (error) {
      console.log(error);
      setFeedback({ msg: t('MEMBER_DELETE_ERROR'), severity: 'error' });
    }
  }

  async function disableCurrentRecord() {
    data.is_disabled = true;
    await mutateAsyncUpdate({ ...data, id });
    history.goBack();
  }

  async function enableCurrentRecord() {
    data.is_disabled = false;
    await mutateAsyncUpdate({ ...data, id });
    history.goBack();
  }

  if (isLoading || ranksIsLoading || sectionIsLoading || specializationIsLoading || roleIsLoading || groupsIsLoading) {
    return <Loader />;
  }

  if (isError) {
    return <span>Error : {error.message}</span>;
  }

  const onSubmit = handleSubmit(async (dataToSave) => {
    // console.log(data);
    if (id !== '0') {
      dataToSave.is_disabled = data.is_disabled;
      await mutateAsyncUpdate({ ...dataToSave, id });
    } else {
      await mutateAsyncCreate(dataToSave);
    }
    history.goBack();
  });

  const handleCheck = (checkedId) => {
    const { specializations: ids } = getValues();
    const newIds = ids?.includes(checkedId) ? ids?.filter((id) => id !== checkedId) : [...(ids ?? []), checkedId];
    setValue('specializations', newIds);
    return newIds;
  };

  const handleGroupCheck = (checkedId) => {
    const { groups: ids } = getValues();
    const newIds = ids?.includes(checkedId) ? ids?.filter((id) => id !== checkedId) : [...(ids ?? []), checkedId];
    setValue('groups', newIds);
    return newIds;
  };

  const handleRoleCheck = (checkedId) => {
    const { roles: ids } = getValues();
    const newIds = ids?.includes(checkedId) ? ids?.filter((id) => id !== checkedId) : [...(ids ?? []), checkedId];
    setValue('roles', newIds);
    return newIds;
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <Paper sx={{ pl: 3, pr: 3, pb: 1, pt: 1, mb: 1 }}>
        <Grid container spacing={1}>
          <Grid item xs={'auto'}>
            <Button size="small" type="submit" variant="contained" startIcon={<AddIcon />} onClick={() => history.push(`${id}/availability`)}>
              {t('AVAILABILITIES')}
            </Button>
          </Grid>
          {/* <Grid item xs={'auto'}>
            <Button size="small" type="submit" variant="contained" startIcon={<TodayIcon />} onClick={() => history.push(`${id}/availabilities`)}>
              {t('AVAILABILITIES')}
            </Button>
          </Grid> */}

          {/* <Grid item xs={'auto'}>
            <Button size="small" type="submit" variant="contained" startIcon={<AddIcon />} onClick={() => history.push(`${id}/function`)}>
              {t('FUNCTIONS')}
            </Button>
          </Grid>

          <Grid item xs={'auto'}>
            <Button size="small" type="submit" variant="contained" startIcon={<EventIcon />} onClick={() => history.push(`${id}/functions`)}>
              {t('FUNCTIONS')}
            </Button>
          </Grid> */}

          <Grid item xs={'auto'}>
            <Button size="small" type="submit" variant="contained" startIcon={<LockOpenIcon />} onClick={() => history.push(`${id}/access`)}>
              {t('ACCESS')}
            </Button>
          </Grid>
        </Grid>
      </Paper>

      <Paper sx={{ p: 3 }}>
        <Typography variant="h6" gutterBottom>
          {t('DETAILS')}
        </Typography>
        <form className="userUpdateForm" onSubmit={onSubmit}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <FormControl variant="standard" fullWidth={true}>
                <TextField
                  {...register('firstname')}
                  required
                  autoComplete="off"
                  id="firstname"
                  name="firstname"
                  label={t('FIRST_NAME')}
                  fullWidth
                  variant="standard"
                  defaultValue={data.firstname}
                />
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={6}>
              <FormControl variant="standard" fullWidth={true}>
                <TextField
                  {...register('lastname')}
                  required
                  id="lastname"
                  name="lastname"
                  label={t('LAST_NAME')}
                  fullWidth
                  autoComplete="family-name"
                  variant="standard"
                  defaultValue={data.lastname}
                />
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={6}>
              <ReactHookFormSelect
                register={register}
                required
                id="rank_id"
                name="rank_id"
                label={t('RANK')}
                control={control}
                defaultValue={data.rank_id}
                variant="standard"
              >
                {ranks.map((rank) => {
                  return (
                    <MenuItem key={rank.rank_id} value={rank.rank_id}>
                      {t('RANK_FULL.' + rank.rank_name)}
                    </MenuItem>
                  );
                })}
              </ReactHookFormSelect>
            </Grid>

            <Grid item xs={12} sm={3}>
              <ReactHookFormSelect
                register={register}
                id="section_id"
                name="section_id"
                label={t('SECTION')}
                control={control}
                defaultValue={data.section_id}
                variant="standard"
              >
                <MenuItem value={null}>&nbsp;</MenuItem>
                {sections.map((section) => {
                  return (
                    <MenuItem key={section.section_id} value={section.section_id}>
                      {section.section_name}
                    </MenuItem>
                  );
                })}
              </ReactHookFormSelect>
            </Grid>
            <Grid item xs={12} sm={3}>
              <FormControl variant="standard" fullWidth={true}>
                <TextField {...register('nip')} id="nip" name="nip" label={t('NIP')} fullWidth variant="standard" defaultValue={data.nip} />
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={6}>
              <FormControl variant="standard" fullWidth={true}>
                <TextField
                  {...register('phone')}
                  required
                  autoComplete="off"
                  id="phone"
                  name="phone"
                  label={t('PHONE')}
                  fullWidth
                  variant="standard"
                  defaultValue={data.phone}
                />
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={6}>
              <FormControl variant="standard" fullWidth={true}>
                <TextField
                  {...register('email')}
                  autoComplete="off"
                  id="email"
                  name="email"
                  label={t('EMAIL')}
                  fullWidth
                  variant="standard"
                  defaultValue={data.email}
                />
              </FormControl>
            </Grid>

            <Grid item xs={6}>
              <ReactHookFormSelect
                register={register}
                id="member_type"
                name="member_type"
                label={t('MEMBER_TYPE')}
                control={control}
                defaultValue={data.member_type}
                variant="standard"
              >
                {MEMBER_TYPE.map((type) => {
                  return (
                    <MenuItem key={type.code} value={type.code}>
                      {t(type.name)}
                    </MenuItem>
                  );
                })}
              </ReactHookFormSelect>
            </Grid>

            <Grid item xs={6}>
              <ReactHookFormSelect
                register={register}
                id="lang"
                name="lang"
                label={t('LANGUAGE')}
                control={control}
                defaultValue={Number(id) === 0 ? i18n.language : data.lang}
                variant="standard"
              >
                {LANGUAGES.map((type) => {
                  return (
                    <MenuItem key={type.code} value={type.code}>
                      {type.name}
                    </MenuItem>
                  );
                })}
              </ReactHookFormSelect>
            </Grid>

            <Grid item xs={12} sm={6}>
              <FormControl component="fieldset" variant="standard">
                <FormLabel component="legend">{t('SPECIALIZATIONS')}</FormLabel>
                <FormGroup>
                  <Controller
                    name="specializations"
                    defaultValue={data.specializations}
                    render={(props) =>
                      specializations?.map((item, index) => (
                        <FormControlLabel
                          control={
                            <Checkbox
                              onChange={() => {
                                // props.onChange(handleCheck(item.specialization_id));
                                handleCheck(item.specialization_id);
                              }}
                              defaultChecked={data.specializations?.includes(item.specialization_id)}
                            />
                          }
                          key={item.specialization_id}
                          label={item.specialization_name}
                        />
                      ))
                    }
                    control={control}
                  />
                </FormGroup>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl component="fieldset" variant="standard">
                <FormLabel component="legend">{t('GROUPS')}</FormLabel>
                <FormGroup>
                  <Controller
                    name="groups"
                    defaultValue={data.groups}
                    render={(props) =>
                      groups?.map((item, index) => (
                        <FormControlLabel
                          control={
                            <Checkbox
                              onChange={() => {
                                handleGroupCheck(item.group_id);
                              }}
                              defaultChecked={data.groups?.includes(item.group_id)}
                            />
                          }
                          key={item.group_id}
                          label={item.group_name + (item.is_alarm_group ? ' (' + t('GROUP_ALARM') + ')' : '')}
                        />
                      ))
                    }
                    control={control}
                  />
                </FormGroup>
              </FormControl>
            </Grid>

            {/* <Grid item xs={12} sm={6}>
              <FormControl component="fieldset" variant="standard">
                <FormLabel component="legend">{t('ROLES')}</FormLabel>
                <FormGroup>
                  <Controller
                    name="roles"
                    defaultValue={data.roles}
                    render={(props) =>
                      roles?.map((item, index) => (
                        <FormControlLabel
                          control={
                            <Checkbox
                              onChange={() => {
                                handleRoleCheck(item.role_id);
                              }}
                              defaultChecked={data.roles?.includes(item.role_id)}
                            />
                          }
                          key={item.role_id}
                          label={t(item.role_name)}
                        />
                      ))
                    }
                    control={control}
                  />
                </FormGroup>
              </FormControl>
            </Grid> */}

            <Grid item xs={12}>
              <Box sx={{ display: 'flex', flexDirection: 'row-reverse' }}>
                <Stack direction="row" spacing={1}>
                  <Button type="submit" variant="contained">
                    {t('SAVE')}
                  </Button>
                  {id !== '0' && data.is_disabled === true ? (
                    <Button
                      variant="outlined"
                      onClick={() => {
                        enableCurrentRecord();
                      }}
                    >
                      {t('RE_ENABLE')}
                    </Button>
                  ) : null}
                  {id !== '0' && data.is_disabled !== true ? (
                    <Button
                      variant="outlined"
                      color="error"
                      onClick={() => {
                        disableCurrentRecord();
                      }}
                    >
                      {t('DISABLE')}
                    </Button>
                  ) : null}
                  {id !== '0' ? (
                    <Button
                      variant="contained"
                      color="error"
                      onClick={() => {
                        setOpen(true);
                      }}
                    >
                      {t('DELETE')}
                    </Button>
                  ) : null}
                  <AlertDialog
                    open={open}
                    setOpen={setOpen}
                    Message={t('Êtes-vous sûr de vouloir supprimer cet enregistrement?')}
                    onConfirm={() => deleteCurrentRecord()}
                    AcceptBtn={t('YES')}
                    CancelBtn={t('CANCEL')}
                  />
                </Stack>
              </Box>
            </Grid>
          </Grid>
        </form>
      </Paper>
    </Box>
  );
}
