import React, { useState } from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { useTranslation } from 'react-i18next';
import { AuthenticationService } from 'src/services/auth';
import { useHistory } from 'react-router-dom';
import logo from 'src/images/Alarmo_full.png';
import Alert from '@mui/material/Alert';
import Copyright from 'src/shared/copyright';

export default function ForgottenPassword() {
  const [error, setError] = useState('');
  const { t } = useTranslation();
  const history = useHistory();

  const handleSubmit = async (event) => {
    setError('');
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    const result = await AuthenticationService.resetPassword(data.get('email'));
    if (result) {
      history.push('/');
    } else {
      setError('PASSWORD_RESET_ERROR');
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Box sx={{ m: 1 }}>
          <img src={logo} width="200" height="200" alt="Logo" />
        </Box>
        <Typography component="h1" variant="h5">
          {t('LOST_PASSWORD')}
        </Typography>
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          <TextField margin="normal" required fullWidth id="email" label={t('EMAIL_ADDRESS')} name="email" autoComplete="email" autoFocus />

          {error !== '' ? <Alert severity="error">{t(error)}</Alert> : null}
          <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
            {t('RESET_PASSWORD')}
          </Button>
        </Box>
      </Box>
      <Copyright sx={{ mt: 8, mb: 4 }} />
    </Container>
  );
}
