import React from 'react';
import { getAllUsers } from 'src/api/api';
import { useQuery } from 'react-query';
import Loader from 'src/shared/defaultLoader';

import { useUIContext } from '../../contexts/ui/ui.context';
import { useTranslation } from 'react-i18next';
import MemberList from '../../components/memberList/memberList';

export default function AllMember() {
  const { t } = useTranslation();

  const { setAppBarTitle } = useUIContext();

  React.useEffect(() => {
    setAppBarTitle(t('MEMBERS_LIST'));
  }, []);

  const { data, error, isLoading, isError, refetch } = useQuery('users', getAllUsers, {
    onSuccess: (data) => {
      setFilteredData(data);
    },
  });
  const [filteredData, setFilteredData] = React.useState(data);
  React.useEffect(() => {
    let newData = data;
    if (newData) {
      newData = newData.filter((e) => !(e?.is_disabled ?? false) && e.member_type === 'FIRST_RESPONDER');

      setFilteredData(newData);
    }
  }, [data]);
  if (isLoading) {
    return <Loader />;
  }

  return <MemberList data={filteredData} />;
}
