import React from 'react';
import Grid from '@mui/material/Grid';
import ToggleButton from '@mui/material/ToggleButton';
import Divider from '@mui/material/Divider';
import useStore from './reinforcement.store';

function LineSeparator({ name }) {
  return (
    <Grid item xs={12}>
      <Divider>{name}</Divider>
    </Grid>
  );
}

export default function ReinforcementGroups() {
  const groupData = useStore((state) => state.groupsData);
  const memberData = useStore((state) => state.membersData);
  const selectedGroups = useStore((state) => state.selectedGroups);
  const setSelectedGroups = useStore((state) => state.setSelectedGroups);

  let separatorName;
  let prevGroupType;
  return (
    <Grid container spacing={2}>
      {groupData.map((group) => {
        separatorName = '';
        if (prevGroupType !== group.group_type_name) {
          separatorName = group.group_type_name;
        }
        prevGroupType = group.group_type_name;

        return (
          <Line
            key={group.group_id}
            separatorName={separatorName}
            group={group}
            memberData={memberData}
            defaultValue={selectedGroups.has(group.group_id) ? selectedGroups.get(group.group_id) : false}
            onLineStatusChange={(isSelected) => {
              selectedGroups.set(group.group_id, isSelected);
              setSelectedGroups(selectedGroups);
            }}
          />
        );
      })}
    </Grid>
  );
}

function Line({ separatorName, group, onLineStatusChange, defaultValue, memberData }) {
  const [selected, setSelected] = React.useState(defaultValue);

  const members = memberData.reduce(
    (previousValue, currentValue) => {
      if (currentValue.groups_ids.includes(group.group_id) && !currentValue.alarm_id) {
        if (currentValue.status_code === 'AVAILABLE') {
          previousValue.availableMembers++;
        }
        previousValue.allMembers++;
      }
      return previousValue;
    },
    { allMembers: 0, availableMembers: 0 }
  );

  return (
    <>
      {separatorName !== '' ? <LineSeparator name={separatorName} /> : null}
      <Grid item xs={12}>
        <ToggleButton
          color="primary"
          fullWidth
          selected={selected}
          onChange={() => {
            const newStatus = !selected;
            setSelected(newStatus);
            onLineStatusChange(newStatus);
          }}
          size="large"
          value={group.group_id}
        >
          {`[${group.group_code}] ${group.group_name} ${members.availableMembers}/${members.allMembers}`}
        </ToggleButton>
      </Grid>
    </>
  );
}
