import React from 'react';
import GenericCRUD from 'src/shared/genericCRUD';
import { useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useQuery } from 'react-query';
import { getData, getAllData } from 'src/api/api';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import { useTranslation } from 'react-i18next';
import FormControl from '@mui/material/FormControl';
import Loader from 'src/shared/defaultLoader';
import FormLabel from '@mui/material/FormLabel';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Controller } from 'react-hook-form';
import Checkbox from '@mui/material/Checkbox';

export default function DirectoryDetail() {
  const { register, handleSubmit, control, getValues, setValue } = useForm();
  const { t } = useTranslation();
  let { id } = useParams();

  const getQueryFn = async ({ queryKey }) => await getData('directory', { queryKey });
  const { data, error, isLoading, isError } = useQuery(['directory', { id }], getQueryFn);

  const getGroupsQueryFn = async () => await getAllData('directory/group');
  const { data: groups, isLoading: groupsIsLoading } = useQuery('directory/group', getGroupsQueryFn);

  const handleGroupCheck = (checkedId) => {
    const { groups: ids } = getValues();
    const newIds = ids?.includes(checkedId) ? ids?.filter((id) => id !== checkedId) : [...(ids ?? []), checkedId];
    setValue('groups', newIds);
    return newIds;
  };

  if (isLoading || groupsIsLoading) {
    return <Loader />;
  }
  if (isError) {
    return <span>Error : {error.message}</span>;
  }

  return (
    <GenericCRUD objectName={'directory'} handleSubmit={handleSubmit} data={data}>
      <Grid item xs={12} sm={6}>
        <FormControl variant="standard" fullWidth={true}>
          <TextField
            {...register('phone_directory_name')}
            required
            autoComplete="off"
            id="phone_directory_name"
            name="phone_directory_name"
            label={t('NAME')}
            fullWidth
            variant="standard"
            defaultValue={data.phone_directory_name}
          />
        </FormControl>
      </Grid>

      <Grid item xs={12} sm={6}>
        <FormControl variant="standard" fullWidth={true}>
          <TextField
            {...register('phone_directory_order')}
            required
            id="phone_directory_order"
            name="phone_directory_order"
            label={t('SORT_ORDER')}
            fullWidth
            autoComplete="family-name"
            variant="standard"
            defaultValue={data.phone_directory_order}
          />
        </FormControl>
      </Grid>

      <Grid item xs={12} sm={6}>
        <FormControl component="fieldset" variant="standard">
          <FormLabel component="legend">{t('GROUPS')}</FormLabel>
          <FormGroup>
            <Controller
              name="groups"
              defaultValue={data.groups}
              render={(props) =>
                groups?.map((item, index) => (
                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={() => {
                          handleGroupCheck(item.phone_directory_group_id);
                        }}
                        defaultChecked={data.groups?.includes(item.phone_directory_group_id)}
                      />
                    }
                    key={item.phone_directory_group_id}
                    label={item.phone_directory_group_name}
                  />
                ))
              }
              control={control}
            />
          </FormGroup>
        </FormControl>
      </Grid>
    </GenericCRUD>
  );
}
