import create from 'zustand/vanilla';

const store = create((set) => {
  const feedback = { msg: '', severity: '' };

  const setFeedback = (data) => {
    set({ feedback: data });
  };

  return {
    feedback: feedback,
    setFeedback: setFeedback,
  };
});

export default store;
