import React from 'react';
import { useParams, useHistory, useRouteMatch } from 'react-router-dom';
import { useMutation, useQueryClient } from 'react-query';
import { updateData, createData, deleteData } from 'src/api/api';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import AlertDialog from 'src/shared/sharedModal';
import useStoreGlobalFeedback from 'src/modules/shared/globalFeedback/globalFeedback.store.js';

export default function GenericCRUD({
  objectName,
  children,
  handleSubmit,
  data,
  stayOnPage = false,
  rowId,
  isDisabledManagement = false,
  hideDelete = false,
  returnPath = '',
}) {
  const [open, setOpen] = React.useState(false);
  const { t } = useTranslation();
  let { id } = useParams();
  const history = useHistory();
  let { path } = useRouteMatch();

  const setFeedback = useStoreGlobalFeedback((state) => state.setFeedback);

  // const getQueryFn = async ({ queryKey }) => await getData(objectName, { queryKey });
  const createQueryFn = async (data) => await createData(objectName, data);
  const updateQueryFn = async ({ id, ...data }) => await updateData(objectName, { id, ...data });
  const deleteQueryFn = async ({ id, ...data }) => await deleteData(objectName, { id, ...data });

  // const { data, error, isLoading, isError } = useQuery([objectName, { id }], getQueryFn);

  const queryClient = useQueryClient();
  const { mutateAsync: mutateAsyncUpdate } = useMutation(updateQueryFn, {
    onSuccess: (data) => {
      queryClient.setQueryData([objectName, { id: id }], data);
    },
  });
  const { mutateAsync: mutateAsyncCreate } = useMutation(createQueryFn, {
    onSuccess: (data) => {
      queryClient.setQueryData([objectName, { id: id }], data);
      queryClient.setQueryData([objectName, { id: '0' }], {});
    },
  });
  const { mutateAsync: mutateAsyncDelete } = useMutation(deleteQueryFn, {
    onSuccess: (data) => {},
  });

  async function deleteCurrentRecord() {
    const res = await mutateAsyncDelete({ ...data, id });
    if (res) {
      if (res.isError) {
        console.log(res);
        setFeedback({ msg: t(res.message), severity: 'error' });
      } else {
        queryClient.setQueryData([objectName, { id: id }], {});
        history.goBack();
      }
    }
  }

  // if (isLoading) {
  //   return <Loader />;
  // }
  // if (isError) {
  //   return <span>Error : {error.message}</span>;
  // }

  const onSubmit = handleSubmit(async (dataToSave) => {
    if (id !== '0') {
      if (isDisabledManagement) {
        dataToSave.is_disabled = data.is_disabled;
      }
      await mutateAsyncUpdate({ ...dataToSave, id });
      if (!stayOnPage) {
        history.goBack();
      }
    } else {
      const res = await mutateAsyncCreate(dataToSave);
      // console.log(res);
      if (returnPath !== '') {
        history.push(returnPath);
      } else {
        if (!stayOnPage) {
          history.goBack();
        } else {
          let idName = `${objectName}_id`;
          if (rowId) {
            idName = rowId;
          }
          const newPath = path.replace(':id', res[idName]);
          history.push(newPath);
        }
      }
    }
    setFeedback({ msg: ' ', severity: 'success' });
  });

  async function disableCurrentRecord() {
    data.is_disabled = true;
    await mutateAsyncUpdate({ ...data, id });
    history.goBack();
  }

  async function enableCurrentRecord() {
    data.is_disabled = false;
    await mutateAsyncUpdate({ ...data, id });
    history.goBack();
  }

  return (
    <Paper sx={{ p: 3 }}>
      <Typography variant="h6" gutterBottom>
        {t('DETAILS')}
      </Typography>
      <form className="detailsForm" onSubmit={onSubmit}>
        <Grid container spacing={3}>
          {children}

          <Grid item xs={12}>
            <Box sx={{ display: 'flex', flexDirection: 'row-reverse' }}>
              <Stack direction="row" spacing={1}>
                <Button type="submit" variant="contained">
                  {t('SAVE')}
                </Button>

                {isDisabledManagement && id !== '0' && data.is_disabled === true ? (
                  <Button
                    variant="outlined"
                    onClick={() => {
                      enableCurrentRecord();
                    }}
                  >
                    {t('RE_ENABLE')}
                  </Button>
                ) : null}

                {isDisabledManagement && id !== '0' && data.is_disabled !== true ? (
                  <Button
                    variant="outlined"
                    color="error"
                    onClick={() => {
                      disableCurrentRecord();
                    }}
                  >
                    {t('DISABLE')}
                  </Button>
                ) : null}

                {id !== '0' && hideDelete === false ? (
                  <Button
                    variant="contained"
                    color="error"
                    onClick={() => {
                      setOpen(true);
                      // deleteCurrentRecord;
                    }}
                  >
                    {t('DELETE')}
                  </Button>
                ) : null}
                <AlertDialog
                  open={open}
                  setOpen={setOpen}
                  Message={t('Êtes-vous sûr de vouloir supprimer cet enregistrement?')}
                  onConfirm={() => deleteCurrentRecord()}
                  AcceptBtn={t('YES')}
                  CancelBtn={t('CANCEL')}
                />
              </Stack>
            </Box>
          </Grid>
        </Grid>
      </form>
    </Paper>
  );
}
