import React, { useRef } from 'react';
import Button from '@mui/material/Button';
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import { useTranslation } from 'react-i18next';
import api from 'src/api/axios';
import { useQueryClient } from 'react-query';
import useStoreGlobalFeedback from 'src/modules/shared/globalFeedback/globalFeedback.store.js';
import Loader from 'src/shared/defaultLoader';

export default function AddPhotoButton({ onImportDone, incidentId }) {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const [isLoading, setIsLoading] = React.useState(false);
  const setFeedback = useStoreGlobalFeedback((state) => state.setFeedback);

  const myRefname = useRef(null);

  const changeHandler = async (event) => {
    const formData = new FormData();
    if (event.target.files.length > 0) {
      formData.append('file', event.target.files[0]);
      try {
        setIsLoading(true);
        const uploadRes = await api.post(`/incident-logs/${incidentId}/importPhoto`, formData);
        event.target.value = null;
        queryClient.invalidateQueries(['incident-logs/' + incidentId]);
        setIsLoading(false);
        if (uploadRes.data.res) {
          setFeedback({ msg: t('IMPORT_DONE'), severity: 'success' });
        } else {
          setFeedback({ msg: t(uploadRes.data.msg), severity: 'error' });
        }

        //onImportDone();
        return true;
      } catch (error) {
        event.target.value = null;
        setFeedback({ msg: t('IMPORT_ERROR'), severity: 'error' });
        setIsLoading(false);
        console.error(error);
        return false;
      }
    }
  };

  const handleSubmission = () => {
    myRefname.current.click();
  };

  return (
    <>
      <input type="file" accept="image/jpeg,image/png,image/tiff,image/webp" name="file" onChange={changeHandler} ref={myRefname} style={{ display: 'none' }} />

      {isLoading ? (
        <Loader />
      ) : (
        <Button variant="contained" fullWidth size="large" color="neutral" startIcon={<AddAPhotoIcon />} onClick={handleSubmission}>
          {t('ADD_PHOTO')}
        </Button>
      )}
    </>
  );
}
