import React from 'react';
import Topbar from './components/topbar/topbar';
import Sidebar from './components/sidebar/sidebar';
import BottomNav from './components/bottomNav/bottomNav';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import { UIContextProvider } from './contexts/ui/ui.context';
import { ThemeProvider } from '@mui/material/styles';
import { UserContextProvider } from './contexts/user/user.context';
import theme from './user.theme';
import UserContent from './userContent';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import Map from './pages/map/map';
export default function Admin() {
  let { path } = useRouteMatch();

  return (
    <ThemeProvider theme={theme}>
      <UIContextProvider>
        <UserContextProvider>
          <Box sx={{ bgcolor: '#efefef', minHeight: '100vh', flexFlow: 'column', display: 'flex' }}>
            <Box sx={{ display: 'flex', flexGrow: 1 }}>
              <Topbar />
              <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
                <Sidebar />
              </Box>

              <Switch>
                <Route path={`${path}/map/:id`}>
                  <Box sx={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
                    <Toolbar />
                    <Map />
                  </Box>
                </Route>
                <Route path={`${path}`}>
                  <Box component="main" sx={{ flexGrow: 1, p: 1 }}>
                    <Toolbar />
                    <UserContent />
                  </Box>
                </Route>
              </Switch>
            </Box>
            <Box sx={{ display: { md: 'none' } }}>
              <BottomNav />
            </Box>
          </Box>
        </UserContextProvider>
      </UIContextProvider>
    </ThemeProvider>
  );
}
