import React from 'react';
import { useQuery } from 'react-query';
import { getAllData } from 'src/api/api';
import Grid from '@mui/material/Grid';
import Button from '../../components/button/button';
import Colors from 'src/styles/variables';
import { useTranslation } from 'react-i18next';
import Loader from 'src/shared/defaultLoader';
import RoomIcon from '@mui/icons-material/Room';
import { useUIContext } from '../../contexts/ui/ui.context';
import { useHistory } from 'react-router-dom';

export default function Maps() {
  const { t } = useTranslation();

  const history = useHistory();

  const { setAppBarTitle } = useUIContext();
  React.useEffect(() => {
    setAppBarTitle(t('MAPS'));
  }, []);

  const query = () => getAllData('maps/by-role');

  const { data, error, isLoading, isError } = useQuery('maps/by-role', query);

  if (isLoading) {
    return <Loader />;
  }
  if (data.length === 1) {
    history.replace(`/user/map/${data[0].map_id}`);
  }
  return (
    <Grid container spacing={2}>
      {data.map((map) => {
        return (
          <Grid key={map.map_id} item xs={12}>
            <Button
              icon={RoomIcon}
              iconColor={Colors.button_default_icon}
              bgColor={Colors.button_default}
              onClick={() => {
                history.push(`/user/map/${map.map_id}`);
              }}
            >
              {map.map_name}
            </Button>
          </Grid>
        );
      })}
    </Grid>
  );
}
